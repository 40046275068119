import React, { useState, useEffect }from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import './styles/header.css'
import { Button2 } from "./Button2"
import logo from './images/logo.svg' 
import { selectUser, login, logout } from '../redux/userSlice';
import { auth, projectFirestore } from './firebase/config';
import { Avatar } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import Navbar from './Navbar';
import Navbar2 from './Navbar2';
import Button from '@mui/material/Button';
import ChatIcon from '@mui/icons-material/Chat';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Watchlist from './Watchlist'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddIcon from '@mui/icons-material/Add';


 


function Header() {

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const defaultProps = {
    color: 'secondary',
    children: <i class="far fa-bell" id="notifications__bell"></i>,
  };

  const useStyles = makeStyles((theme) => ({
    
  }));

  const classes = useStyles();

  
  const user = useSelector(selectUser);

  const dispatch = useDispatch();


  const logoutOfApp = () => {
    dispatch(logout())
    auth.signOut();
  }

   const [userId, setUserId] = useState('') 

  auth.onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.
      setUserId(user.uid);
    } else {
      // User not logged in or has just logged out.
    }
  });

  const [newAlert, setNewAlert] = useState()
useEffect(() => {
  if(userId){
  projectFirestore.collection("users").doc(userId).
  onSnapshot(snapshot =>(
      setNewAlert(snapshot.data().newMessages)
  ))
  }
}, [userId])

console.log(newAlert)  



  if (user) {
    return (
     <>
       <nav className='navbar'>

        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
        <img className='navbar-logo' src={logo} alt='logo'></img>
        </Link>
        

        <div className='menu-icon'>
          <Navbar />
        </div>
        
        <div className='headerIcons__wrapper'>
       <div className='header__icons'>
       <Link to="/srch">
        <SearchIcon style={{color: "#002d65", padding: "3px", borderRadius: "100px", border: "1px solid #ff5e00"}}/>
        </Link>
       </div>
       <div className='header__icons'>
        <Link id="watchLink">
          <Watchlist />
        </Link>
        </div>
        <div className='header__icons'>
        <Link to="/listing">
        <AddIcon  style={{color: "#002d65", padding: "3px", borderRadius: "100px", border: "1px solid #ff5e00"}}/>
        </Link>
        </div>
       </div>

        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <Link to="/srch" >
      <IconButton style={{height: '40px', marginTop: '18px', padding: "5.5px", border: '1px solid #ff5e00'}} >
        <SearchIcon style={{color: '#002d65', width: '28px', height: '28px'}}/>
      </IconButton>
      </Link>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          
          <li className='nav-item'>
            <Link  className='nav-links' onClick={closeMobileMenu}>
              <Watchlist />
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/help'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Help
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/chts'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              <Badge color="secondary" badgeContent={newAlert}>
              <ChatIcon id="chatIcon" />           </Badge>
            </Link>
          </li>
          <li
            className='nav-item'
          >
            <Link
               to={"/user/" + userId}
              className='nav-links'
              onClick={closeMobileMenu}
              id="userName"
            >
              
             <Avatar src={user?.photoUrl} />
             
             
            </Link>
          </li>
          <li className='nav-item' >
            <Link
              
              className='nav-links'
              onClick={() => {closeMobileMenu(); logoutOfApp();}}
              id="Logout"
            >
              Logout
            </Link>
          </li>
         
          <li>
            <Link
              to='/listing'
              className='nav-links-mobile'
              onClick={closeMobileMenu}
            >
              Sell Item <i class="fas fa-plus"></i>
            </Link>
          </li>
        </ul>
        <Button2 />
      </nav>
      
     </> 
  )







  } else { 
   return  (
    <>
      <nav className='navbar'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
        <img className='navbar-logo' src={logo} alt='logo'></img>
        </Link>
        <div className='menu-icon'>
        <Navbar2 />
        </div>
    

         <div className='headerIcons__wrapper'>
       <div className='header__icons'>
       <Link to="/srch">
        <SearchIcon style={{color: "#002d65", padding: "3px", borderRadius: "100px", border: "1px solid #ff5e00"}}/>
        </Link>
       </div>
       
        <div className='header__icons'>
        <Link to="/listing">
        <AddIcon  style={{color: "#002d65", padding: "3px", borderRadius: "100px", border: "1px solid #ff5e00"}}/>
        </Link>
        </div>
       </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>

        <Link to="/srch" >
        <IconButton style={{height: '40px', marginTop: '18px', padding: "5.5px", border: '1px solid #ff5e00'}} >
        <SearchIcon style={{color: '#002d65', width: '28px', height: '28px'}}/>
        </IconButton> 
       </Link>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>

          <li className='nav-item'>
            <Link
              to='/help'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Help
            </Link>
          </li>
         
          <li
            className='nav-item'
          >
            <Link
              to='/login'
              className='nav-links'
              onClick={closeMobileMenu}
              id="login"
            >
             Login
            </Link>
          </li>
          <li className='nav-item' >
            <Link
              to='/signup'
              className='nav-links'
              onClick={closeMobileMenu}
              id="signUp"
            >
              Sign Up
            </Link>
          </li>
          
          <li>
            <Link
              to='/signup'
              className='nav-links-mobile'
              onClick={closeMobileMenu}
            >
              Sell Item <i class="fas fa-plus"></i>
            </Link>
          </li>
        </ul>
       
        <Button2 />
      </nav>
    </>   
)
  }


    
}

export default Header;