import React, {useState, useEffect} from 'react'
import './styles/home.css'
import HomeCards from './Categories/HomeCards'
import PropertyCards from './Categories/PropertyCards'
import NewlyAddedCards from './Categories/NewlyAddedCards'
import HomeAndSecurityCards from './Categories/HomeAndSecurityCards'
import { projectFirestore } from './firebase/config'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Banner from './Banner';
import Navbar2 from './Navbar2'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import VehiclesCards from './Categories/VehiclesCards'
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom'


function Home() {
 

  return (
    <div className='home__page'>
   

      <div className="btn-group">
      <Link id="home__cat__Link" to="/categories"><button>All Catagories</button> </Link>
      <Link id="home__cat__Link" to="/cars-and-bakkies"><button>Cars & Bakkies</button></Link>
      <Link id="home__cat__Link" to="/cellphones"><button>Cellphones</button></Link>
      <Link id="home__cat__Link" to="/apartments"><button>Apartments</button></Link>
      <Link id="home__cat__Link" to="/houses"><button>Houses</button></Link>
      <Link id="home__cat__Link" to="/home-appliances"><button>Home Appliances</button></Link>
      <Link id="home__cat__Link" to="/home-and-garden"><button>Home & Garden</button></Link> 
      <Link id="home__cat__Link" to="/computers-and-laptops"><button>Computers & Laptops</button></Link> 
    </div>
     <Banner />
     <NewlyAddedCards cardTitle="Newly Added"/>
    <VehiclesCards cardTitle="Vehicles"/>
    <HomeCards cardTitle="Electronics"/>  
    <PropertyCards cardTitle="Property" />
    <HomeAndSecurityCards cardTitle="Home and Garden"/> 
     
      <div className="viewAll-container">
       <a href="/categories">
      <button className="view-cat-button">View All Categories <ArrowForwardIcon style={{marginBottom: "-7px",marginLeft: "5px"}}/></button>
     </a> 
     </div>
    </div>
  )
}

export default Home

