import React from 'react';
import Chats from './Chats';
import Sidebar from './Sidebar';
import { BrowserRouter as Router, Switch, Route, useParams, Link, Redirect } from 'react-router-dom';
import Page404 from './Page404';


function ChatWrapper() {
    return (
        <div>
        
        

        <div className='chat__app'>

        <div className='chat__appBody'><Switch>
        <Route path='/chts'>
        <Sidebar />    
        </Route>
        
        
    

        <Route path='/cht/:chatId'>
        <Chats />
        </Route>
        <Route path='/*' component={Page404} />
        </Switch>
         </div>

        </div>

        
        </div>
    )
}

export default ChatWrapper
