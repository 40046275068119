import React, {useState, useEffect} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles/home.css"
import './styles/listingExpand.css'
import { useParams, Link } from 'react-router-dom'
import { auth, provider, projectFirestore } from './firebase/config';
import { Carousel } from 'react-responsive-carousel'
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

function Banner() {

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
    
    //This is the product Id used as the link
    let { proId } = useParams();

    //below I fetch the product data
    const [productData, setProductData] = useState([])
    useEffect(() => {
        projectFirestore.collection("Listings").onSnapshot(snapshot =>(
          setProductData(snapshot.docs.map(doc => (
              {
                  id: doc.id,
                  data: doc.data(),
                  
              }
          )))
      ))
      }, [])
      
      const [sliderImages, setSliderImages]=useState([])
      useEffect(() => {
        projectFirestore.collection("Listings").doc(proId).
        onSnapshot(snapshot =>(
            setSliderImages(snapshot.data().images)
        ))
  }, [])

    console.log(sliderImages)    

      const [listedProduct, setListedProduct]=useState([])

      useEffect(() => {
        projectFirestore.collection("Listings").onSnapshot(snapshot => (
          setListedProduct(snapshot.docs.map(doc => doc.id))
        ))
      }, [])

        const result = listedProduct.find(findUser)

            function findUser(item){
                return item === proId
            }

      

      const findProduct = productData.find(function(data, index) {
        if(data.id === proId)
          return true;
      });
      

      const [slideImages, setSlideImages] = useState([])

      useEffect(() => {
        
      
        setSlideImages(oldArray => [findProduct] );

      }, [findProduct])

      console.log(slideImages)




      if (proId === result ) {

    return (

      
      <div className="relative" id="expCarousel">
      
      <Carousel 
          infiniteLoop
          showStatus={true}
          showIndicators={true}
          showThumbs={false}
          
      >   
          {sliderImages.map((image) => {
            return <div onClick={handleClickOpen}>
                <img src={image} alt="title"  className="ExpImageSlider" />
            </div>
          })}     
    </Carousel>

    <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        
      >
        <DialogActions className="picture__dialog">
          
          <IconButton  onClick={handleClose} autoFocus>
            <CloseIcon />
          </IconButton>
        </DialogActions>
       <Carousel 
          infiniteLoop
          showStatus={true}
          showIndicators={true}
          showThumbs={false}
          
      >   
          {sliderImages.map((image) => {
            return <div className="centerImg" onClick={handleClickOpen}>
                <img src={image} alt="title" className='fullImage' />

            </div>
          })}     
    </Carousel>
     
      </Dialog>
      
  </div>

      
    )

}else{
    return <h1>This Product does not exists Does Not Exist</h1>
}
}

export default Banner

