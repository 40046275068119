import React from 'react'
import './styles/404.css'

function Page404() {
    return (
        <div>


        <div className="containerSSS">
            <h2 id='oops'>Oops! Page not found.</h2>
            <h1>404</h1>
            <p>We can't find the page you're looking for.</p>
            <a href="/">Go back home</a>
       </div>
            
        </div>
    )
}

export default Page404
