import React, {useState, useEffect}from 'react'
import SidebarChat from './SidebarChat'
import './styles/chats.css'
import { useParams } from 'react-router-dom'
import {projectFirestore, auth} from './firebase/config'
import { selectUser, login, logout } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux'; 
import { useHistory, useLocation } from 'react-router-dom';



function Sidebar() {

   const {chatId} = useParams()
   const [userId, setUserId] = useState() 
   const user = useSelector(selectUser);
   const history = useHistory();
   const handleClick = () => history.push('/cht');

  auth.onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.
      setUserId(user.uid);
    } else {
      // User not logged in or has just logged out.
    }
  });

    
  const [chatName, setChatName] = useState([])

  useEffect(() => {
    if(userId) {projectFirestore.collection("users")
        .doc(userId)
        .collection("Chats")
        .orderBy("timestamp", "desc")
        .onSnapshot(snapshot =>(
            setChatName(snapshot.docs.map(doc => (
                {
                    id: doc.id,
                    data: doc.data(),  
                }
            )))
        ))
    }
    }, [userId])

    console.log(userId)
    console.log(chatName)

    


    return (
        <div className="sidebar">

            <div className="sidebar_header">
                <div className="sidebar_headerRight">
                    <medium  style={{fontWeight: "bolder", fontSize: "x-large"}}>Chats</medium>
                </div>    
            </div>
            <div className="sidebar_chats">
                {chatName.map(room => (
                    <SidebarChat key={room.id} 
                    id={room.id} 
                    name={room.data.name}
                    image={room.data.profilePicture}
                    alert={room.data.alerts}
                    lastMsg={chatId}
                    />
                ))}
                
            </div>

            
        </div>
    )
}

export default Sidebar
