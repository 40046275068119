import React, {useEffect, useState} from 'react'
import '../styles/homeCards.css'
import { projectFirestore } from '../firebase/config'
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, login, logout } from '../../redux/userSlice';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import moment from 'moment'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';



function HomeCards({  cardTitle }) {

    const user = useSelector(selectUser);
    



        const [electronicsCards, setElectronicsCards] = useState([]);
        useEffect(() => {
            projectFirestore.collection("Listings").limit(12).onSnapshot(snapshot =>(
                setElectronicsCards(snapshot.docs.map(doc => (
                    {
                        id: doc.id,
                        data: doc.data(),
                        
                    }
                )))
            ))
    }, [])

    console.log(electronicsCards)

    const [displayedProducts, setDisplayedProducts] = useState([])


    useEffect(() => {
        
         
            if (!electronicsCards) return
      
            const filteredProducts = electronicsCards.filter(
              (prod) => prod.data.mainCatagory === "Electronics"
            )
      
            setDisplayedProducts(filteredProducts)
          
    }, [electronicsCards])

    console.log(Date.now() + 5000000)

    const renderer = ({days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <span>Over</span>;
        } else {
          // Render a countdown
          return <span>{ days ? <span>{days}</span> :<span> {hours}:{minutes}:{seconds} Left</span> } {days ? <span>Days Left</span>: null}</span>;
        }
      }; 


      const scrollRight = () => {
        var slider = document.getElementById("card__row")
        slider.scrollLeft = slider.scrollLeft + 300
      }  
      const scrollLeft = () => {
        var slider = document.getElementById("card__row")
        slider.scrollLeft = slider.scrollLeft - 300
      }


    return (

     <div>

            <div className="row__title__div">

            <h1 className="row__title">{cardTitle}</h1>
            <Link to="/electronics">
            <Button className="viewAll__button" style={{backgroundColor: "white", 
            color: "#002d65", 
            fontWeight: 'lighter', 
            border: "1px solid #002d65", 
            padding: "2px",
             transition: "transform 150ms"}}>
              View All
            </Button>
            </Link>
            </div>
            
      <div id="card__row">   
      
      <ArrowBackIosIcon id="back__arr" onClick={scrollLeft}/>
        {displayedProducts.map(({id, data: { images, adTitle, startingPrice, bids, location, duration, buyItNowPrice, timestamp }}) => (
           <a href={`/list/${id}`} >
           <div className="card">

           <img src={images[0]} className="card__image"/>

           <h4 className="card__title">{adTitle}</h4>

           <h2 className="card__price">R {startingPrice ? startingPrice.toLocaleString() : buyItNowPrice.toLocaleString()}</h2>
      
           {startingPrice ?
           <div className="bids__time">
           <h5 className="card__bids">{bids} {bids < 2 ? <span>Bid</span> : <span>Bids</span>} • </h5>
           <h5 className="card__time"> Auction • <Countdown  date={duration} renderer={renderer}/></h5>
           </div> : <h5 className="negotiable">Buy It Now / Make Offer</h5>}


           <h3 className="card__location"><i class="fas fa-map-marker-alt" ></i> {location}</h3>
           </div>
        </a>   
            ))}
          <ArrowForwardIosIcon onClick={scrollRight} id="forward__arr"/>  
         </div>
           

           
        </div>

    )
}

export default HomeCards
