import React from 'react'
import { projectFirestore, projectStorage, auth, increment, timestamp } from './firebase/config'

function Payment() {

    var yoco = new window.YocoSDK({
        publicKey: 'pk_live_2b177be4R4lPy2V68a64',
      });
      var checkoutButton = document.querySelector('#checkout-button');
     const checkout = () => {
       yoco.showPopup({
          amountInCents: 1000,
          currency: 'ZAR',
          name: 'Your Store or Product',
          description: 'Awesome description',
          callback: function (result) {
            // This function returns a token that your server can use to capture a payment
            if (result.error) {
              const errorMessage = result.error.message;
              alert("error occured: " + errorMessage);
            } else {
              alert("card successfully tokenised: " + result.id);
              projectFirestore.collection("payment").add({
                successfully: "yes"
              })
            }
            // In a real integration - you would now pass this chargeToken back to your
            // server along with the order/basket that the customer has purchased.
          }
        }) 
     }
        
      
  return (
    <div>
       <button onClick={checkout}>buy</button>
    </div>
  )
}

export default Payment