import React, {useEffect, useState} from 'react'
import '../styles/homeCards.css'
import { projectFirestore } from '../firebase/config'
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, login, logout } from '../../redux/userSlice';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import Pagination from '@mui/material/Pagination';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import '../styles/Categories.css'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';



function Electronics({  cardTitle }) {

    const user = useSelector(selectUser);
    
    const [alignment, setAlignment] = useState("All");

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };


      const [electronicsCards, setElectronicsCards] = useState([]);
      const [lastDoc, setLastDoc] = useState([]);
      const [loading, setLoading] = useState(false);
      const [isEmpty, setIsEmpty] = useState(false)
      console.log(isEmpty)

        useEffect(() => {
            projectFirestore.collection("Listings")
            .where("catagory", "==", "House")
            .limit(10)
            .get()
            .then((collections) =>{
              const listings = collections.docs.map((lists) => lists.data())
              const lastList = collections.docs[collections.docs.length - 1]
              setElectronicsCards(listings)
              setLastDoc(lastList)
             })
      }, [])

    const viewMore = () => {

      setLoading(true)
      projectFirestore.collection("Listings")
      .where("catagory", "==", "House")
      .startAfter(lastDoc)
      .limit(5)
      .get()
      
      .then((collections) =>{

        const isCollectionEmpty = collections.size === 0;
        console.log(isCollectionEmpty)

        if(!isCollectionEmpty){
        const listings = collections.docs.map((lists) => lists.data())
        const lastList = collections.docs[collections.docs.length - 1]
        setElectronicsCards(listOf => [...listOf, ...listings])
        setLastDoc(lastList)
        }else{
          setIsEmpty(true)
        }
        setLoading(false)
      })
}
     
    console.log(electronicsCards)
    
     console.log(alignment)



    const [type, setType] = useState([])
    useEffect(() => { 

      if(alignment === "BuyItNow"){
      if (!electronicsCards) return
      const filteredProducts2 = electronicsCards.filter(
        (prod) => prod.sellingStyle === "Fixed Price"
      )
      setType(filteredProducts2)

      }else if(alignment === "Auction"){
        if (!electronicsCards) return
        const filteredProducts2 = electronicsCards.filter(
          (prod) => prod.sellingStyle === "Auction Style"
        )
        setType(filteredProducts2)

      }else{
        setType(electronicsCards)
      }

    }, [electronicsCards, alignment])

    console.log(type)



    console.log(Date.now() + 5000000)

    const renderer = ({days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <span>Auction Over</span>;
        } else {
          // Render a countdown
          return <span>{ days ? <span>{days}</span> :<span> {hours}:{minutes}:{seconds} Left</span> } {days ? <span>Days Left</span>: null}</span>;
        }
      }; 
      

     


    return (

     <div className="catagory__container">

       <div className="otherCat">
          
         <h1 style={{color: "#002d65"}}>Similiar Categories</h1>
         <div>
         <a href="/townhouses"><h4 className="other-categories">Townhouses</h4></a>
         </div>
         <div>
         <a href="/apartments"><h4 className="other-categories">Apartments</h4></a>
         </div>

       
       </div>
            
      <div className="category__wrapper">   
     <div className="category__toggle">
     <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      id='btn__toggle'
     >
      <ToggleButton value="All" onClick={handleChange}>All</ToggleButton>
      <ToggleButton value="BuyItNow" onClick={handleChange}>Buy It Now</ToggleButton>
      <ToggleButton value="Auction" onClick={handleChange}>Auction</ToggleButton>
    </ToggleButtonGroup>
   </div>
   
    <div >
        {type.map(({id, itemDescription, images, adTitle, startingPrice, bids, location, duration, buyItNowPrice, timestamp }) => (
         
         <div > 
          <Link to={`/list/${id}`} >
           
           <div className="card2">

           <div className="cat__img">
           <img src={images[0]} className="card__image2"/>
          </div>
          
          <div className="cat__info">

          <div>
           <h4 className="card__title2">{adTitle}</h4>
         </div>

           <h2 className="card__price2">R {startingPrice ? startingPrice.toLocaleString() : buyItNowPrice.toLocaleString()}</h2>
       
           <h4 id="card__itemDesc">{itemDescription}</h4>

           {startingPrice ?
           <div className="bids__time2">
           <h5 className="card__bids2"><span id="separator2">{bids} {bids < 2 ? <span>Bid</span> : <span>Bids</span>} • </span></h5>
           <h5 className="card__time2"> Auction • <Countdown  date={duration} renderer={renderer}/></h5>
           </div> : <h5 className="negotiable2">Buy It Now / Make Offer</h5>}


           <h3 className="card__location2"><i class="fas fa-map-marker-alt" ></i> {location}</h3>
           </div>
           </div>   
          </Link> 
         </div> 
            ))}  
         </div>

         <div className="bottom__action">  
         {loading && <CircularProgress style={{color:"#002d65"}} />} 
         {!loading && !isEmpty && <button id="viewMore__btn" onClick={viewMore}>Load More</button>}
         {isEmpty && <h4 id="no__more"> - no more -</h4>}
         </div> 
         <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders" id="responsive-cat">
        <List style={{ width: "100%", bgcolor: 'background.paper'}}>
        <ListItem disablePadding>
            <ListItemButton>
              
             <h3>Similiar Categories</h3> 
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIcon style={{color: "#ff5e00"}}/>
              </ListItemIcon>
              <a href="/townhouses"><p>Townhouses</p></a> 
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIcon style={{color: "#ff5e00"}}/>
              </ListItemIcon>
              <a href="/apartments"><p>Apartments</p></a> 
            </ListItemButton>
          </ListItem>

         
        </List>
        
      </nav>
      
    </Box>
          </div>
        </div>

    )
}

export default Electronics
