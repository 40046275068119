import firebase from 'firebase'
require('firebase/auth')
 

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDo0zYdlBGyMOb6Bt_gEOtfMLuEJtxKy4g",
  authDomain: "zagmax.com",
  projectId: "zagmaxcom",
  storageBucket: "zagmaxcom.appspot.com",
  messagingSenderId: "908712089905",
  appId: "1:908712089905:web:ef09418afa19524b22f810",
  measurementId: "G-09CK5C7Q9K"
};

// Initialize Firebase
  firebase.initializeApp(firebaseConfig);


  const projectStorage = firebase.storage();
  const projectFirestore = firebase.firestore();
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  const auth = firebase.auth();
  const provider = new firebase.auth.GoogleAuthProvider();
  const increment = firebase.firestore.FieldValue.increment(1)
  
  export { projectStorage, projectFirestore, timestamp, auth, provider, increment};