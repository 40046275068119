import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FlagIcon from '@mui/icons-material/Flag';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { timestamp, projectFirestore, auth } from '../firebase/config';
import { useParams } from 'react-router-dom'
import { selectUser } from '../../redux/userSlice';
import {  useSelector } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import {  useSnackbar } from 'notistack';

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  let { proId } = useParams();
  const user = useSelector(selectUser);
  const [userId, setUserId] = useState("") 
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          setUserId(user.uid);
        } else {
          // User not logged in or has just logged out.
        }
      });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [radio, setRadio] = useState("")
  const [reportMsg, setReportMsg] = useState("")
  console.log(reportMsg)
  const message = 'Select The Reason';
  const message2 = 'Report was successfully received and It being reviewed';

  const SendReport = () => {

    if(radio === ""){
        enqueueSnackbar(message, { 
            variant: 'error',
      
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
          },
          TransitionComponent: Fade,
      
          autoHideDuration: 5000,
        }); 
    }else{  
    projectFirestore.collection('reports').add({
        Reason: radio,
        Message: reportMsg,
        timestamp: timestamp,
        List: proId,
        ReporterEmail: user?.email,
        ReporterName: user?.displayName,
        ReporterID: userId
    })
    setOpen(false)
    setReportMsg("")
    enqueueSnackbar(message2, { 
        variant: 'success',
  
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
      },
      TransitionComponent: Fade,
  
      autoHideDuration: 90000,
    }); 
 }
  }

  return (
    <div>
    
      <button id="report__btn"onClick={handleClickOpen}><FlagIcon style={{marginBottom: "-8px", marginRight: "5px"}}/>Report</button>
     
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Report Ad</DialogTitle>
        <DialogContent>
        <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Reason for Reporting</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        
        name="radio-buttons-group"
      >
        <FormControlLabel value="Violent or repulsive content" onChange={e => setRadio(e.target.value)}  control={<Radio />} label="Violent or repulsive content" />
        <FormControlLabel value="Inappropriate or Sexual content" onChange={e => setRadio(e.target.value)} control={<Radio />} label="Inappropriate or Sexual content" />
        <FormControlLabel value="Spam" onChange={e => setRadio(e.target.value)} control={<Radio />} label="Spam" />
        <FormControlLabel value="Wrong category" onChange={e => setRadio(e.target.value)} control={<Radio />} label="Wrong category" />
        <FormControlLabel value="Fraud" onChange={e => setRadio(e.target.value)} control={<Radio />} label="Fraud" />
        <FormControlLabel value="Other" onChange={e => setRadio(e.target.value)} control={<Radio />} label="Other" />
      </RadioGroup>
    </FormControl>
    <TextField
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={4}
          fullWidth
          defaultValue={reportMsg}
          onChange={e => setReportMsg(e.target.value)}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {reportMsg === "" ? <Button disabled>Submit</Button> : <Button onClick={SendReport}>Submit</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
