import * as React from 'react';
import { useState, useEffect} from 'react';
import { auth } from './firebase/config';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import { selectUser, login, logout } from '../redux/userSlice';
import Badge from '@material-ui/core/Badge';
import { projectFirestore } from './firebase/config';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Watchlist from './Watchlist'


export default function TemporaryDrawer() {

  const user = useSelector(selectUser);
  const [userId, setUserId] = useState()  

  auth.onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.
      setUserId(user.uid);
    } else {
      // User not logged in or has just logged out. ??New Commit
    }
  });

  const [newAlert, setNewAlert] = useState()
useEffect(() => {
  if(userId){
  projectFirestore.collection("users").doc(userId).
  onSnapshot(snapshot =>(
      setNewAlert(snapshot.data().newMessages)
  ))
  }
}, [userId])
console.log(newAlert)

  const dispatch = useDispatch();
  const logoutOfApp = () => {   
    dispatch(logout())
    auth.signOut();
  }

  const styles = {
    largeIcon: {
      width: 100,
      height: 100,
    },
  
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     <List>
        {['Close'].map((text, index) => (
          
          <ListItem button key={text}>

            <ListItemIcon>
            {index % 2 === 0 ? <CloseIcon />:<CloseIcon />}
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    
      <List>
        {['Home'].map((text, index) => (
          <Link to='/'>
          <ListItem button key={text}>
            <ListItemIcon>
            {index % 2 === 0 ? <HomeIcon />:<HomeIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
          </Link>
        ))}
      </List>
     
      <List>
        {['Help'].map((text, index) => (
          <Link to='/help'>
          <ListItem button key={text}>
            <ListItemIcon>
            {index % 2 === 0 ? <HelpIcon />:<HelpIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
          </Link>
        ))}
      </List>
      <List>
        {[user?.displayName].map((text, index) => (
          <Link to={"/user/" + userId}>
          <ListItem button key={text}>
            <ListItemIcon>
            {index % 2 === 0 ? <AccountCircleIcon />:<AccountCircleIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
          </Link>
        ))}
      </List>
      <List>
        {['Chats'].map((text, index) => (
          <Link to='/chts'>
          <ListItem button key={text}>
            <ListItemIcon>
{index % 2 === 0 ?<Badge color="secondary" badgeContent={newAlert}><ChatIcon /></Badge> :<Badge color="secondary" badgeContent={1}><ChatIcon /></Badge>}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
          </Link>
        ))}
      </List>
      <List onClick={logoutOfApp}>
        {['Logout'].map((text, index) => (  
          <ListItem button key={text}>
            <ListItemIcon>
            {index % 2 === 0 ? <LogoutIcon />:<LogoutIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>

      <Link to="/listing">
     <button id="navBtn">Sell +</button>
     </Link>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Badge id="menu__badge" color="secondary" badgeContent={newAlert} onClick={toggleDrawer(anchor, true)} ><MenuIcon onClick={toggleDrawer(anchor, true)} id="Menu__Icon">{anchor}</MenuIcon></Badge>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)} 
            
          </Drawer>
        </React.Fragment>
      ))}
      

    </div>
  );
}
