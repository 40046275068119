import React from 'react'
import {projectFirestore, increment, auth, timestamp} from'../firebase/config'
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom'


function CancelAction() {
    const {proId} = useParams()

    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const deleteList = (e) => {
        e.preventDefault();
    
        projectFirestore.collection('Listings').doc(proId).delete()
         
        setOpen(false)
    
        history.push('/')
      }

  return (
    <div>
      <button onClick={handleClickOpen} id="chat__btn" style={{backgroundColor: "#FF5E00"}}>Cancel Auction</button>
        <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure you want to delete this listing?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This Can't be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} id="modal__btn">Cancel</Button>
          <Button onClick={deleteList}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CancelAction