import React from "react";
import "./styles/footer.css";
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link} from 'react-router-dom';


function Footer() {
  
  return (
    <div className="main-footer">

      <div className="container">

        <div className="row">
          {/* Column1 */}
          
          <div className="col1">
            <h4>Shop</h4>
            <ui className="list-unstyled">
              <li><Link to="/vehicles">Vehicles</Link></li>
              <li><Link  to="/furniture">Furniture</Link></li>
              <li> <Link to="/electronics">Electronics</Link></li>
              <li ><Link to="/home-and-garden">Home & Garden</Link></li>
            </ui>
          </div>

          {/* Column2 */}
          <div className="col2">
            <h4>Company</h4>
            <ui className="list-unstyled">
             <li><Link  to="/help">About us</Link></li> 
             <Link target="_blank" to="/privacy"><li id="term-of-use">Privacy Policy</li></Link>
            <Link target="_blank" to="/terms-of-use"><li id="term-of-use">Terms & Conditions</li></Link>  
            </ui>
          </div>

          {/* Column2 */}
          <div className="col2">
            <h4>Support</h4>
            <ui className="list-unstyled">
             <li><Link  to="/help">Help </Link></li>
             <li><Link  to="/help">Report a bug</Link></li>
             <li> <Link  to="/help">Submit an feature Idea</Link>  </li>
            </ui>
          </div>

          {/* Column3 */}
          <div className="col3">
            <h4>Follow Us</h4>
            <ui className="list-unstyled" style={{display: 'block'}}>
            <div style={{display: "flex"}}>

            <div><a target="_blank" href="https://web.facebook.com/profile.php?id=61562594682886&notif_id=1723621914258566&notif_t=follower_invite_accept&ref=notif"><FacebookIcon className="footer__Icons"  style={{color: "#0754a5", marginBottom: "3px" }} /></a></div>

            <div><a target="_blank" href="https://youtube.com/@zagmax101"><YouTubeIcon className="footer__Icons"  style={{color: "#df0712", marginBottom: "3px" }} /></a></div>
            <div><a target="_blank" href="https://www.instagram.com/zagmaxcom/"><InstagramIcon className="footer__Icons"  style={{color: "#df0712", marginBottom: "3px" }} /></a></div>
            </div>
            <div style={{display: "flex"}}>
           
            </div>
            </ui>
          </div>


        </div>


        <hr style={{color: 'orange'}} />
        <div className="lastRow">
          <p className="col-sm">
            &copy;{new Date().getFullYear()} Zagmax | All rights reserved |
            <Link target="_blank" to="/terms-of-use">Terms Of Service</Link> | <Link target="_blank" to="./privacy">Privacy</Link>
          </p>
        </div>

      </div>

    </div>
  );
}

export default Footer;
