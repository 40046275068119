import React, {useState} from 'react'
import googleIcon from './images/googleIcon.png'
import facebookIcon from './images/facebookIcon.png'
import logo from './images/logo.svg'
import twitterIcon from './images/twitterIcon.png'
import appleIcon from './images/appleIcon.png'
import { auth, provider, projectFirestore} from './firebase/config';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { selectUser, login, logout } from '../redux/userSlice';
import firebase from 'firebase';
import { useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';


function Login() {

    const history = useHistory();
    const handleClick = () => history.push('/signup');
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const loginToApp = () => {
    auth.signInWithPopup(provider)
    .then(userAuth => {
      dispatch(login({
            displayName: userAuth.user.displayName,
            uid: userAuth.user.uid,
            email: userAuth.email,
            photoUrl: userAuth.user.photoURL,
      }))
    }).then(() => {
      const userUid = auth.currentUser.uid;
      const docRef =  projectFirestore.collection('globalUids').doc(userUid)
      docRef.get().then((doc) => {
        if(doc.exists){
          console.log("welcome")
        }else{
          projectFirestore.collection("globalUids").doc(userUid).set({
            uniqueId: userUid,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(), 
          }) 
          
          projectFirestore.collection("users").doc(userUid).set({
            name: auth.currentUser.displayName,
            photoUrl: auth.currentUser.photoURL,
            email: auth.currentUser.email,
            newMessages: 0, 
            listings: 0,
            five: 0,
            four: 0,
            three: 0,
            two: 0,
            one: 0,
            ReviewsNos: 0,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            about: '***No About***',
            raters: [],
            id: userUid
        });
        }
      }).catch((error) => alert(error.message));

    })
  }

  const message = "The Email or Password is incorrect, or the account does not exist you have to Sign up."
 
  const loginToAppEmail = (e) => {

  e.preventDefault();

  auth.signInWithEmailAndPassword(email, password)
   .then((userAuth) => {
     dispatch(login({
      email: userAuth.user.email,
      displayName: userAuth.user.displayName,
      photoUrl: userAuth.user.photoURL,
     }))
   }).catch((error) => {
    enqueueSnackbar(message, { 
      variant: 'error',

      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    TransitionComponent: Fade,

    autoHideDuration: 90000,
  });
   })
  }
    return (
        <div className="signup__form__wrapper">
            <img className='logo' src={logo} alt='logo'></img>
            <h1>Login To Your Account</h1>
            
          <div className="social__signUp">
          <div>
            <img src={googleIcon} alt="google" className="google__icon"/>
          </div>
          <div> 
            <button className="google__btn" onClick={loginToApp}>Continue With Google</button>
          </div>
          </div>

          <div style={{marginTop: '-40px'}}>
          <h3 id="OrSignUp">Or Email</h3>
          </div>
          <div className="form__wrapper">
          <form>
       
             
             <div className="signup__inputs">
             <label className="signup__labels">
                 Email<span id="required">*</span>
             </label>
             <input 
             type="email"
             placeholder="Enter Email"
             className="signUp__inputs"
             value={email} 
             onChange={e => setEmail(e.target.value)}
             />
             </div>
             <div className="signup__inputs">
             <label className="signup__labels">
                 Password<span id="required">*</span>
             </label>
             <input 
             type="password"
             placeholder="Enter Password"
             className="signUp__inputs"
             value={password} 
             onChange={e => setPassword(e.target.value)}
             />
             </div>
             <div style={{marginTop: '10px'}}>
               <small>By continuing I understand and agree with Zagmax’s 
                  <span><Link  style={{color: '#ff5e00'}} target="_blank"  to="/terms-of-use">Terms & Conditions</Link></span> and
                   <span ><Link style={{color: '#ff5e00'}} target="_blank"  to="/privacy">Privacy Policy</Link></span>.</small>
             </div>
             <button type="submit" className="signup__submit" onClick={loginToAppEmail}>Login</button>

          </form>
          </div>

          <p>Not a member? <span id="reg__link" onClick={handleClick}>Register</span></p>
        </div>
    )
}

export default Login