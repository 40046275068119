import * as React from 'react';
import { useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import './styles/watchlist.css'
import { projectFirestore, auth } from './firebase/config';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [userId, setUserId] = useState()  

  auth.onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.
      setUserId(user.uid);
    } else {
      // User not logged in or has just logged out. ??New Commit
    }
  });

  const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Over</span>;
    } else {
      // Render a countdown
      return <span>{ days ? <span>{days}</span> :<span> {hours}:{minutes}:{seconds} Left</span> } {days ? <span>Days Left</span>: null}</span>;
    }

  }; 

  const [check, setCheck] = useState()
 

  

  const [deleteWatch, setDeleteWatch] = useState(false)

     const reload = () => {

      window.location.reload()

    }
  

  

  const deleteWatchItems = (e) => {
    setDeleteWatch(true)
  }

useEffect(() => {
  const ref = projectFirestore.collection("users").doc(userId).collection('Watchlist')
  ref.onSnapshot((snapshot) => {
    snapshot.docs.forEach((doc) => {
      ref.doc(doc.id).delete()
    })
  })
  setOpen(false)
  
  setDeleteWatch(false)
}, [deleteWatch === true])





  const [watching, setWatching] = useState([])

  useEffect(() => {
    if(open === true){
    projectFirestore.collection("users").doc(userId).collection("Watchlist") 
    .orderBy("timestamp", "desc")

    .get()
    .then((collections) =>{
      const posts = collections.docs.map((lists) => lists.data())
      const lastList = collections.docs[collections.docs.length - 1]
      setWatching(posts)
     })
    }else{
      console.log("not open")
    }
  }, [open === true])

  console.log(watching)
  return (
    <div>
        
        
        <li id='watchLink' onClick={handleClickOpen}>
       <span id='atMobile'><FavoriteBorderIcon  
         style={{color: "#002d65",
         padding: "3px", 
         borderRadius: "100px",
         border: "1px solid #ff5e00"}}/></span><span id='atFull'>Watchlist</span></li>
       
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
     
      >
        <AppBar style={{ position: 'relative', background: "white", color: "#002d65" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, }} id="watchTitle" variant="h6" component="div">
             Watchlist
            </Typography>
            <Link >
            <Button onClick={() => {deleteWatchItems(); setTimeout(reload, 3000)}} autoFocus style={{background: "rgb(184, 0, 0)", color: "white", fontWeight: "bolder"}} >
              Delete All
            </Button>
            </Link>
          </Toolbar>
        </AppBar>
  
 {watching.length === 0 ? <h2 id="no__items">No Items to Show</h2> :  <div>
 
  {watching.map(({id, adTitle, itemDescription ,images, buyItNowPrice, startingPrice, duration, timestamp, location, ownerID }) => (
<Link onClick={handleClose} to={`/list/${id}`}>

  <div className="user__watching"  >
  <div className="watch__img">        
       <img src={images} />
  </div>

    <div className="watch__info">
    <h2 id="watch__title">{adTitle}</h2>
      {startingPrice ? <h2 style={{color: "#ff5e00", marginTop: "-10px"}}>R{startingPrice.toLocaleString()}</h2> : <h2 style={{color: "#ff5e00", marginTop: "-10px"}}>R{buyItNowPrice.toLocaleString()}</h2>}
      {startingPrice ?
           <div className="watch__time">
           <h5 className="watchAuch"> Auction • <Countdown  date={duration} renderer={renderer}/></h5>
           </div> : <h5 className="WatchbuyNow">Buy It Now / Make Offer</h5>}
           <h4 id="watch__itemDesc">{itemDescription}</h4>
           <h3 className="watch__location"><i class="fas fa-map-marker-alt" ></i> {location}</h3>
    </div> 

     
 </div>
 </Link>
 ))} 

</div>}

      </Dialog>
    </div>
  );
}
