import { Redirect, Route } from "react-router-dom"
import { selectUser, login, logout } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from './firebase/config';




export default function PrivateProfile({ component: Component,  }){

    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    return(
        <Route 
       
        render={({ location }) => {
            if(user) {
                return(
                    <Redirect to={{pathname:"/", state: {from: location}}}/>
                )
            }else {
                return <Component />
            }
        }}
        />
    )
}