import React, {useEffect, useState}from 'react';
import './styles/help.css'
import {projectFirestore, increment, auth, timestamp} from'./firebase/config'
import { selectUser, login, logout } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'; 
import { SnackbarProvider, useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import firebase from 'firebase'
import AutoComplete from './alerts/AutoComplete'
import BuyerQs from './Accordion/BuyerQs';
import SellerQs from './Accordion/SellerQs';
import Ad2 from "./images/Ad2.mp4"
import ReactPlayer from "react-player"

function Help() {
  const history = useHistory();

  const user = useSelector(selectUser);

  const message2 = 'We received your query and we will return to you soon.';
  const { enqueueSnackbar } = useSnackbar();

  const [message, setMessage] = useState()
  const [enteredName, setEnteredName] = useState()
  const [enteredEmail, setEnteredEmail] = useState()

  const initialValues = {  
    message: "", 
    enteredName: "", 
    enteredEmail: "", 
}

const [formValues, setFormValues] = useState(initialValues)
const [formErrors, setFormErrors] = useState({})
const [isSubmit, setIsSubmit] = useState(false)

const formChange = (e) => {
  const {name, value} = e.target;
  setFormValues({...formValues, [name]: value});
}
const handleSubmit = (e) => {
  e.preventDefault()
  setFormErrors(validate(formValues))

  setIsSubmit(true)
 

}

const validate = (values) => {
  const errors = {}
  if(!values.enteredName){
    errors.enteredName = "Name Is Required*"
  }
  if(!values.enteredEmail){
    errors.enteredEmail = "Email Is Required*"
  }
  if(!values.message){
    errors.message = "Message Is Required*"
  }
  return errors;
}

useEffect(() => {
  if(Object.keys(formErrors).length === 0 && isSubmit){
    projectFirestore.collection('Help').add({
      Name: user?.displayName,
      Email: user?.email,
      Message: formValues.message,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      enteredName:formValues.enteredName,
      enteredEmail: formValues.enteredEmail,
    })

    enqueueSnackbar(message2, { 
      variant: 'success',

      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    TransitionComponent: Fade,

    autoHideDuration: 90000,
  }); 
  history.push("/");
  }
},[formErrors])

 
  const [userId, setUserId] = useState("") 
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          setUserId(user.uid);
        } else {
          // User not logged in or has just logged out.
        }
   });
  const NeedHelp = (e) => {
    e.preventDefault();

    
   
     

  }
  return <div>
      <ReactPlayer controls width="100%" url="https://youtu.be/G-amKIlvjbU"/>
      <div className="top__help">

        <div className="about__header">
         <h1 style={{color: "White", fontWeight: "900px", marginLeft: "15px"}}>About Us</h1>
        </div>

          

        <div className='about__us'>
        <h4>
        Zagmax is the best online auction marketplace to buy and sell 
        anything ranging from Houses and Cars to home equipments and Electronics and Services,
        </h4>
        <h4>
        Although there are many places to buy and sell online, 
        Zagmax offers significant advantages over other websites. Here are just a few of our benefits:
        </h4>

        <ul>
          <li>
          <span style={{fontWeight: 'bold'}}> We’re focused on helping people sell without dealing with "Not Serious" buyers</span>: Having a auction will 
          greatly reduce the amount of "Not Serious" buyers, only serious buyers will bid and wait for the auction to end.
          When the auction is over you only chat with the winning bidder.
          </li>
          <br></br>

          <li>
          Zagmax makes it easy to submit your product or land for sale. We value your time by asking for only a few
           crucial details before listing. That means you don’t have to waste your time providing initial information.
          </li>
          <br></br>
          <li>
            Its Free. Sellers list for free, Which means with every sale you keep 100% of the profit

          </li>
          <br></br>
          <li>
           <span style={{fontWeight: 'bold'}}>Zagmax is the most user-friendly online marketplace</span>, with easy sorting and searching – and
           zagmax does not have third-party ads so what see on the platform is ads listed by the sellers and we provide
           simplified auctions and listings that tell you exactly what you need to know about each List.
          </li>
        </ul>
        </div>

      </div>

       <div className='howItWork'>
         <h1 style={{fontWeight: 'bold'}}>How It Works</h1>
         <h2 id="help__subTitle"> 
           Buying on Zagmax
         </h2>
         <h3>
            There 2 types of ads listed on zagmax, There are tradional types of ads(Buy It Now) where you can message the
            seller at any time and There is a Auction style(Auction) where you can only message the seller when the auction
            is over and you were the winning bidder.
        </h3>
        <br></br>
         <h3><span style={{fontWeight: 'bolder', fontStyle: "italic"}}>If you buying through "Auction" ad, Here's how it works:</span></h3>
         <h3 style={{color: '#ff5e00'}}>1. Register on Zagmax to Bid</h3>
         <p>
         In order to bid , you must first Register or Login if you already have an account
         </p>
         <h3 style={{color: '#ff5e00'}}>2. Bid</h3>
         <p>
           We’ve made bidding easy, and we’ve explained it below:
         </p>
         <ul>
           <li>
           When you bid, it means you're committing to buy the item being sold if you're the winning bidder.
           </li>
           <br></br>
           <li>
           Bids are binding, so only bid if you fully intend to purchase the item and you have performed the requisite due diligence, because you might end up as the high bidder
           </li>
           <br></br>
           <li>
             While the auction is live you will no be able to message the seller, you will be able to message the seller
             if you were the winner of the auction. All the information regarding the item being sold will be provided
             in the description box
           </li>
           <br></br>
           <li>
            When you bid, You cannot bid the exact Current Price you have to bid from the Current Price +R10 or More
           </li>
           <br></br>
           <li>
             If the auction is live and you are the high bidder, We advise adding the item to your Watchlist 
             in case someone outbids you.
           </li>
         </ul>
         <h3 style={{color: '#ff5e00'}}>3. Win the Auction</h3>
         <p>
          To buy on Zagmax, you must win the auction by ultimately being the highest bidder, then the button
          to contact the seller will be unlocked, when you message the seller you will be only messaging for the 
          purpose of arranging for a Safe exchange with the amount you bid for and won, there will be no making of new offers

         </p>

        <h3><span style={{fontWeight: 'bolder', fontStyle: "italic"}}>If you buying through "Buy It Now" ad, Here's how it works:</span></h3>
         <h3 style={{color: '#ff5e00'}}>1. Register on zagmax</h3>
         <p>
         To contact the seller directly , you must first Register or Login if you already have an account
         </p>
         <h3 style={{color: '#ff5e00'}}> 2. Perform Due Diligence</h3>
         <p>
         While we’ve tried to make buying online as safe and easy as possible, it’s ultimately your
        responsibility to perform your own due diligence and make sure that the ad you’re considering is
         right for you – prior responding to the ad
         </p>
         <h3 style={{color: '#ff5e00'}}>3. Replying to an Ad</h3>
         <p>
         To reply on the Ad, you first click on the Ad then click on the "Message The Seller" button to make offers
         to the seller
         </p>
         <h3 style={{color: '#ff5e00'}}>4. Got the deal?</h3>
         <p>
           If you got a deal from the seller, then the seller will remove the Ad from the platform and you arrange for a Safe exchange
         </p>
         <h2 id="help__subTitle"> 
           Selling on Zagmax
         </h2>
         <p style={{fontWeight: 'bold',color: '#002d65'}}>
         Zagmax is the best place to sell and we’ve made the process easy.
         </p>
         <h3>
            There 2 types of ways to sell on zagmax, There is tradional type of selling The "Buy It Now" where potential buyers message you
             at anytime and There is a Auction style "Auction" where you can only message the Winner of the item you listed for auction, you can only message when the auction is over.
        </h3>
        <h3><span style={{fontWeight: 'bolder', fontStyle: "italic"}}>If you Selling through the "Auction" style, Here's how it works:</span></h3>
        <h3 style={{color: '#ff5e00'}}>1. Register on Zagmax to Sell</h3>
         <p>
         In order to Sell , you must first Register or Login if you already have an account
         </p>
         <h3 style={{color: '#ff5e00'}}>2. Submit Your Listing</h3>
         <ul>
           <li>You first click the Sell button then you will land on the listing page</li>
           <br></br>
           <li>
             There will be different categories to choose from, you will select the appropriate category to what
             you are selling (For example, If you want to sell a Car you will select the "Vehicle category" then select the
             "Cars and Bakkies Sub-Category" )
           </li>
           <br></br>
           <li>It’s free to submit your List. We simply ask you for a few details – including the VIN number If you are Selling a Vehicle, some 
             photos, and a brief description of the List, When you filling the listing form, there will be a "Selling Style" prompt you will select
             "Auction Style" then below you will input The "Starting Price" of what you are selling(The Starting Price is the price where buyers will start 
             bidding from), then you will select how many days you want the auction to be live for.
             </li>
             <br></br>
             <li>After you have submitted the listing, Get ready for a thrilling bidding war </li>
         </ul>

         <h3 style={{color: '#ff5e00'}}>3. During a live auction</h3>
         <p>during a live auction, you can keep track of who is winning buy clicking the "Bids" link </p>
         <h3 style={{color: '#ff5e00'}}>5. Auction End</h3>

         <p>
         Once your auction ends, you’ll be able  to message the buyer and vice-versa, in order to finalize the details and logistics
         of the Safe Exchange upon the winning price.
         </p>
         <h3><span style={{fontWeight: 'bolder', fontStyle: "italic"}}>If you Selling through the "Buy It Now" style, Here's how it works:</span></h3>
         <h3 style={{color: '#ff5e00'}}>1. Register on Zagmax to Sell</h3>
         <p>
         In order to Sell , you must first Register or Login if you already have an account
         </p>
         <h3 style={{color: '#ff5e00'}}>2. Submit Your Listing</h3>
         <ul>
           <li>You first click the Sell button then you will land on the listing page</li>
           <br></br>
           <li>
             There will be different categories to choose from, you will select the appropriate category to what
             you are selling (For example, If you want to sell a Car you will select the "Vehicle category" then select the
             "Cars and Bakkies Sub-Category" )
           </li>
           <br></br>
           <li>It’s free to submit your List. We simply ask you for a few details – including the VIN number If you are Selling a Vehicle, some 
             photos, and a brief description of the List, When you filling the listing form, there will be a "Selling Style" prompt you will select
             "Fixed Price" then below you will input The whether its Negotiable or Not.
             </li>
             <br></br>
             <li>After you have submitted the listing, Get ready to get multiple offers </li>
             
         </ul>
         <h3 style={{color: '#ff5e00'}}>3. Found the good offer?</h3>
             <p>
               If you have found the good offer from the buyer then you can arrange for a Safe Exchange, and delete the listing.
             </p>

       </div>

      <h1 style={{textAlign: "center"}}>If You Have Any Questions Don't hesitate to Contact Us</h1>
     
      <div className='helpForm__wrapper'>
        <h1 style={{marginLeft: "15px"}}>Contact Us</h1>
         <form className='contactForm'>

           <div className='usr__details'>
           <div>  
           <input value={formValues.enteredName}  onChange={formChange} name='enteredName'  type='text' placeholder='Name*' />
           <p >{formErrors.enteredName}</p> 
           </div>
           <div>
           <input value={formValues.enteredEmail}  onChange={formChange} name="enteredEmail" type='email' placeholder='Email*' />
           <p >{formErrors.enteredEmail}</p> 
           </div>
          
          </div>

          <div className='message__text'>
           <textarea value={formValues.message}  onChange={formChange} name="message" type='text' placeholder='Message...*' />
           <p >{formErrors.message}</p> 
           <div>
         <button onClick={handleSubmit}>Send</button>
           </div>
           </div>

         </form>
      </div>

  </div>;
}

export default Help;
