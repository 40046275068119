import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import '../styles/messageModal.css';
import { selectUser, login, logout } from '../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase'
import { useHistory, useLocation } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom'
import { auth, provider, projectFirestore, increment } from '../firebase/config';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AlreadyMsg from '../alerts/AlreadyMsg';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Fade from '@material-ui/core/Fade';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ChatIcon from '@mui/icons-material/Chat';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function MessageModal(props) {


  const [open2, setOpen2] = React.useState(false);

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };


  const user = useSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();

  const [messageInput, setMessageInput] = useState('');
  const [userId, setUserId] = useState("") 
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          setUserId(user.uid);
        } else {
          // User not logged in or has just logged out.
        }
      });
      console.log(userId)

  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const [userArray, setUserArray] = useState()
  useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setUserArray(snapshot.data().usersArray)
  ))
}, [])
const [ownerId, setOwnerId] = useState('')
const [ownerName, setOwnerName] = useState('')
const [ownerEmail, setOwnerEmail] = useState('')
const [ownerPic, setOwnerPic] = useState('')
const [photo, setPhoto] = useState('')
const [productName, setProductName] = useState('')

useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setOwnerId(snapshot.data().OwnerId)
  ))
}, [])

useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setProductName(snapshot.data().adTitle)
  ))
}, [])

useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setOwnerPic(snapshot.data().OwnerPicture)
  ))
}, [])

useEffect(() => {
projectFirestore.collection("Listings").doc(proId).
onSnapshot(snapshot =>(
  setOwnerName(snapshot.data().OwnerName)
))
}, [])
useEffect(() => {
projectFirestore.collection("Listings").doc(proId).
onSnapshot(snapshot =>(
setOwnerEmail(snapshot.data().Owner)
))
}, [])
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setPhoto(snapshot.data().images[0])
  ))
}, [])
  
  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
   //This is the product Id used as the link
   let { proId } = useParams();
   
    //below I fetch the product data
    const [productData, setProductData] = useState([])
    useEffect(() => {
        projectFirestore.collection("Listings").onSnapshot(snapshot =>(
          setProductData(snapshot.docs.map(doc => (
              {
                  id: doc.id,
                  data: doc.data(),
                  
              }
          )))
      ))
      }, [])


      const [listedProduct, setListedProduct]=useState([])
    

     useEffect(() => {
       projectFirestore.collection("Listings").onSnapshot(snapshot => (
         setListedProduct(snapshot.docs.map(doc => doc.id))
       ))
     }, [])
     const result = listedProduct.find(findUser)
            

     function findUser(item){
         return item === proId
     }



const findProduct = productData.find(function(data, index) {
 if(data.id === proId)
   return true;
});

console.log(findProduct)

const [alert, setAlert] = useState()

const message = `Go to Chats to Continue The Conversation with ${ownerName}`;

const sendPosts = (e, variant) => {
  e.preventDefault()
  setMessageInput("")
  if (userArray.includes(userId)) {
   
      enqueueSnackbar(message, { 
          variant: 'warning',

          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        TransitionComponent: Fade,

        autoHideDuration: 90000,
      });
  
   
  } else {
  
  const userDocRef = projectFirestore.collection('users').doc(ownerId).collection("Chats").doc();
  userDocRef
  .set({
      profilePicture: user?.photoUrl,
      name: user?.displayName,
      userEmail: user?.email,
      userIdentity: userId,
      ProductPic: photo,
      productTitle: productName,
      ToDeduct: 1,
      alerts: 1,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      TheList: proId
  })
  .then(() => {
      return userDocRef.collection('Messages').add({
        productTitle: productName,
        profilePicture: user?.photoUrl,
        message: messageInput,
        name: user?.displayName,
        userEmail: user?.email,
        photoUrl: user?.photoUrl,
        userIdentity: userId,
        ProductPic: photo,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        ToDeduct: 1,
        alerts: 1,
        TheList: proId
      });
  })
  .then(() => {
      return projectFirestore.collection('users').doc(userId).collection("Chats").doc(userDocRef.id).set({
      productTitle: productName,
      profilePicture: ownerPic,  
      name: ownerName,
      userEmail: ownerEmail,
      userIdentity: ownerId,
      ProductPic: photo,
      ToDeduct: 0,
      alerts: 0,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      TheList: proId
      });
  })
  .then(() => {
      projectFirestore.collection('users')
      .doc(userId)
      .collection("Chats")
      .doc(userDocRef.id)
      .collection('Messages')
      .add({
        productTitle: productName,
        profilePicture: ownerPic, 
        message: messageInput,
        name: user?.displayName,
        userEmail: ownerEmail,
        userIdentity: ownerId,
        userIdentifier: userId,
        ProductPic: photo,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        ToDeduct: 0,
        alerts: 0,
        TheList: proId
      });

      projectFirestore.collection('users').doc(ownerId).update({
        newMessages: increment
      })
  })
  .catch((error) => {
      console.log(error);
  });
  enqueueSnackbar('Message Sent!', { variant });
  }

  projectFirestore.collection('Listings').doc(proId).update( {
    usersArray: firebase.firestore.FieldValue.arrayUnion( userId )
  });

 
 setOpen(false)
 setMessageInput("")
}
  

  return (
    <div>
    {alert}
    <button 
    onClick={handleClickOpen} 
    id="chat__btn" 
    style={{backgroundColor: "green", marginTop: "20px"}}>
      Message The Seller
      <ChatIcon style={{marginBottom: "-5px",marginLeft: "5px"}} />
      <LockOpenIcon style={{marginBottom: "-5px", marginLeft: "5px"}}/>

    </button>
    <Dialog open={open} 
    onClose={handleClose} 
    aria-labelledby="responsive-dialog-title"
    fullScreen={fullScreen}>
      <DialogTitle>Send Message</DialogTitle>
      <DialogContent>
        <DialogContentText>
          After sending the message you can view the message by going to chats.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Type Message..."
          id="name"
          multiline
          fullWidth
          variant="standard"
          value={messageInput} onChange={e => setMessageInput(e.target.value)}
        
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} id="modal__btn">Cancel</Button>
        {messageInput === "" ? <Button style={{color: "grey"}}>Send</Button> : <Button onClick={(e) => {sendPosts(e, 'success')}}>Send</Button>}
      </DialogActions>
    </Dialog>
  </div>
  )
}


export default MessageModal



