import React from 'react'
import {Link} from 'react-router-dom'
function TermsOfUse() {
  return (
    <div>
        <div className="Terms__header">
            <h2>Terms of use</h2>
        </div>
        <div className="Terms__body">
        <p>
        Welcome to Zagmax Please read on to learn the rules and restrictions that govern your use of our website, 
        products, services and applications (the “Services”). If you have any questions, comments, or
         concerns regarding these terms or the Services, please contact us at <Link to="/help"><span id="contact__help">help</span></Link>
        </p>

        <h3>Please read these Terms carefully. They cover important information about Services provided to you.
           These Terms include information about future changes to these Terms, limitations of liability, 
           a class action waiver and resolution of disputes by arbitration instead of in court. 
           PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; 
           IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
        </h3>

        <h3>
        ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE
         ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED
          BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT
           OR CLASS-WIDE ARBITRATION.
        </h3>
        <h5 style={{color: "#ff5e00"}}>Connecting Sellers and Buyers</h5>

        <p>
        Zagmax connects those offering products / land for sale (“Sellers”) and those looking to acquire products / land
         (“Buyers”). The Services act as a meeting place only; the actual contract for sale for each product / land 
         listed on the Services is directly between the Seller and the winning Bidder or Buyer.
        </p>

        <p>
        When we use the word “you” in these Terms, it refers to any user, regardless of whether he or she is a
         Seller, Bidder, Buyer other registered user or unregistered user, while if we use one of those specific 
         terms, it only applies to that category of user.
        </p>

        <p>
        Before acquiring a product / land from any Seller (each, a “Seller”), Bidders and Buyers are responsible for making their
         own determinations that the product /land is suitable. Zagmax is only responsible for connecting 
         Sellers and Buyers and Bidders, and can’t and won’t be responsible for making sure that any Seller product / land is up to 
         a certain standard of quality. Zagmax similarly can’t and won’t be responsible for ensuring 
         that information (including credentials) any Bidder or Buyer or Seller provides about himself or herself or
         about any Seller product / land he or she is offering is accurate or up-to-date. Each Seller acknowledges and 
         agrees that they shall make reasonable efforts to complete the sale of their Seller product / land  if there is 
         a winning bid. Each Bidder acknowledges and agrees that if they are the winning bidder in an auction 
         through the Services, their bid is binding on the Bidder and Bidder is responsible for all applicable 
         government fees and taxes for the product / land if applicable, won through such bid. Notwithstanding the foregoing, we don’t 
         control the actions of any Bidder or Seller, and Sellers aren’t our employees.
        </p>

        <h3>
        ZAGMAX DOES NOT DIRECTLY OFFER THE SELLER PRODUCTS / LAND. YOU HEREBY ACKNOWLEDGE THAT ZAGMAX DOES 
        NOT SUPERVISE, DIRECT, CONTROL OR MONITOR THE SELLER PRODUCTS / LAND EXPRESSLY DISCLAIMS ANY RESPONSIBILITY 
        AND LIABILITY FOR THE SELLER CARS, INCLUDING BUT NOT LIMITED TO ANY WARRANTY OR CONDITION OF QUALITY
         OR FITNESS FOR A PARTICULAR PURPOSE, OR COMPLIANCE WITH ANY LAW, REGULATION, OR CODE.
        </h3>

        <h5 style={{color: "#ff5e00"}}>Will these Terms ever change?</h5>

        <p>
        We are constantly trying to improve our Services, so these Terms may need to change along with our Services.
        We reserve the right to change the Terms at any time, but if we do, we will place a notice on our site, send you an email, and/or notify you by some other means.
        If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services
        in any way after a change to the Terms is effective, that means you agree to all of the changes.
        Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.
        </p>

        <h5 style={{color: "#ff5e00"}}>What about my privacy?</h5>
        <p>
        Zagmax takes the privacy of its users very seriously. For the current Zagmax Privacy Policy, please click <Link to="/privacy"><span id="contact__help">here</span></Link>.
        </p>

        
        <h5 style={{color: "#ff5e00"}}>What are the basics of using Zagmax?</h5>

        <p>
        You may be required to sign up for an account, select a password and Name or Via Google,
        and provide us with certain information or data, such as your email address information. You promise to provide
        us with accurate, complete, and updated registration information about yourself. You may not select as 
        your name that you do not have the right to use, or another person’s name with
        the intent to impersonate that person. You may not transfer your account to anyone else without our 
        prior written permission.
        </p>
        <p>
        You represent and warrant that you are an individual of legal age to form a binding contract
        (or if not, you’ve received your parent’s or guardian’s permission to use the Services and have 
        gotten your parent or guardian to agree to these Terms on your behalf). If you’re agreeing to these
        Terms on behalf of an organization or entity, you represent and warrant that you are authorized to
        agree to these Terms on that organization’s or entity’s behalf and bind them to these Terms 
        (in which case, the references to “you” and “your” in these Terms, except for in this sentence, 
        refer to that organization or entity).
        </p>
        <p>
        You will only use the Services for your own internal, personal use, and not on behalf of or for the benefit of any third party.
        You will comply with all laws that apply to you, your use of the Services, and your actions and omissions
        that relate to the Services (for example, Sellers must comply with all laws that relate to the Products / Land). 
        If your use of the Services is prohibited by applicable laws, then you aren’t authorized to use the
        Services. We can’t and won’t be responsible for your using the Services in a way that breaks the law.
        </p>

       
        <h5 style={{color: "#ff5e00"}}>Are there restrictions in how I can use the Services?</h5>

        <p>You represent, warrant, and agree that you will not contribute any Content or 
        User Submission (each of those terms is defined below) or
        otherwise use the Services or interact with the Services in a manner that:</p>

        <ul className='terms__list'>
          
            <li> infringes or violates the intellectual property rights or any other rights of anyone else (Zagmax);</li>
            
            <br></br>

            <li>violates any law or regulation, including, without limitation, any applicable export control
                 laws, privacy laws or any other purpose not reasonably intended by Zagmax;
            </li>
           
            <br></br>
            <li>is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory,
                obscene, or otherwise objectionable (including, without limitation, by creating multiple 
                accounts for purposes of cheating or gaming the bidding system);
            </li>
            <br></br>

            <li>
            jeopardizes the security of your Zagmax ID, account or anyone else’s
             (such as allowing someone else to log in to the Services as you);
            </li>
            <br></br>
            <li>
            attempts, in any manner, to obtain the password, account, or other security information from any other user;
            </li>
            <br></br>
            <li>
            runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or
             are activated while you are not logged into the Services, or that otherwise interfere with the proper 
             working of the Services (including by placing an unreasonable load on the Services’ infrastructure);
            </li>
            <br></br>
            <li>
            “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);
            </li>
            <br></br>
            <li>
            copies or stores any significant portion of the Content; or
            </li>
            <br></br>
            <li>
            decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.
            </li>

        </ul>

        <h4>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</h4>

        <h5 style={{color: "#ff5e00"}}>What are my rights in the Services?</h5>

        <p>
        The materials displayed or performed or available on or through the Services, including, but not limited
        to, text, graphics, data, articles, photos, images, illustrations, User Submissions (as defined below) 
        and so forth (all of the foregoing, the “Content”) are protected by copyright and/or other intellectual
        property laws. You promise to abide by all copyright notices, trademark rules, information, and 
        restrictions contained in any Content you access through the Services, and you won’t use, copy, 
        reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display,
        license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, 
        (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone 
        else’s (including Zagmax’) rights.
        </p>

        <p>
        Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable
        license to use (i.e., to download and display locally) Content solely for purposes of using the Services.
        Use, reproduction, modification, distribution or storage of any Content for any purpose other than using
        the Services is expressly prohibited without prior written permission from us. You understand that Zagmax 
        owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce 
        (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any 
        of the Services. The Services may allow you to copy or download certain Content, but please remember that
         even where these functionalities exist, all the restrictions in this section still apply.
        </p>
        <h5 style={{color: "#ff5e00"}}>What about anything I contribute to the Services – do I have to grant any licenses to Cars and Bids or to other users?</h5>
        <h5 style={{color: "#002d65", fontStyle: "italic"}}>User Submissions</h5>
        <p>
        Anything you post, upload, share, store, or otherwise provide through the Services is your
        “User Submission”. Some User Submissions may be viewable by other users. You are solely 
        responsible for all User Submissions you contribute to the Services. You represent that all
        User Submissions submitted by you are accurate, complete, up-to-date, and in compliance with 
        all applicable laws, rules and regulations.
        </p>
        <p>
        You agree that you will not post, upload, share, store, or otherwise provide through the Services 
        any User Submissions that: (i) infringe any third party’s copyrights or other rights (e.g., trademark, privacy rights, etc.);
         (ii) contain sexually explicit content or pornography; 
         (iii) contain hateful, defamatory, or discriminatory content or incite hatred against any individual 
         or group; (iv) exploit minors; (v) depict unlawful acts or extreme violence; (vi) depict animal cruelty 
         or extreme violence towards animals; (vii) promote fraudulent schemes, multi-level marketing (MLM) schemes,
          get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other
           dubious money-making ventures; or (viii) violate any law.
        </p>

        <h5 style={{color: "#002d65", fontStyle: "italic"}}>Licenses</h5>
        <p>
        In order to display your User Submissions on the Services, and to allow other users to enjoy them (where applicable),
         you grant us certain rights in those User Submissions (see below for more information). 
         Please note that all of the following licenses are subject to our Privacy Policy to the extent they
          relate to User Submissions that are also your personally-identifiable information.
        </p>

        <p>
        By submitting User Submissions through the Services, you hereby do and shall grant Zagmax a worldwide,
        non-exclusive, perpetual, royalty-free, fully paid,sublicensable and transferable license to use, 
        edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display,
        perform, and otherwise fully exploit the User Submissions in connection with this site, the Services
        and our (and our successors’ and assigns’) businesses, including without limitation for promoting 
        and redistributing part or all of this site or the Services (and derivative works thereof) in any 
        media formats and through any media channels (including, without limitation, third party websites 
        and feeds), and including after your termination of your account or the Services. You also hereby 
        do and shall grant each user of this site and/or the Services a non-exclusive, perpetual license to 
        access your User Submissions through this site and/or the Services, and to use, edit, modify,
        reproduce, distribute, prepare derivative works of, display and perform such User Submissions, 
        including after your termination of your account or the Services. For clarity, the foregoing 
        license grants to us and our users do not affect your other ownership or license rights in your 
        User Submissions, including the right to grant additional licenses to your User Submissions,
        unless otherwise agreed in writing. You represent and warrant that you have all rights to grant 
        such licenses to us without infringement or violation of any third party rights, including without
        limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or 
        any other intellectual property or proprietary rights.
        </p>
        <p>
        Finally, you understand and agree that Cars and Bids, in performing the required technical steps to
        provide the Services to our users (including you), may need to make changes to your User Submissions 
        to conform and adapt those User Submissions to the technical requirements of connection networks,
        devices, services, or media, and the foregoing licenses include the rights to do so.
        </p>

        <h5 style={{color: "#ff5e00"}}>What if I see something on the Services that infringes my copyright?</h5>
        
        <p>In accordance with the Copyright law of South Africa, Which state, the right to control the use and distribution of artistic and creative works, in the Republic of South Africa. It is embodied in the Copyright Act, 1978 and its various amendment acts, 
            and administered by the Companies and Intellectual Property Commission in the Department of Trade and 
            Industry. As of March 2019 a major amendment to the law in the Copyright Amendment
            Bill has been approved by the South African Parliament and is awaiting signature by the President
            e reserve the right to (1) block access to or remove material that we believe in good faith to be 
            copyrighted material that has been illegally copied and distributed by any of our advertisers, 
            affiliates, content providers, members or users and (2) remove and discontinue service to repeat offenders.
        </p>

        <h5 style={{color: "#002d65", fontStyle: "italic"}}>Procedure for Reporting Copyright Infringements.</h5>
        <p>
        If you believe that material or content residing on or accessible 
        through the Services infringes your copyright (or the copyright of someone whom you are authorized to act on behalf of), 
        please send a notice of copyright infringement containing the following information to Zagmax:'
        </p>
        <ul>
            <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;</li>
            <br></br>
            <li>Identification of works or materials being infringed;</li>
            <br></br>
            <li> Identification of the material that is claimed to be infringing including information 
                regarding the location of the infringing materials that the copyright owner seeks to have removed,
                 with sufficient detail so that Company is capable of finding and verifying its existence;
            </li>
            <br></br>
            <li>Contact information about the notifier including address, telephone number and, if available, email address;</li>
            <br></br>
            <li>A statement that the notifier has a good faith belief that the material identified in (1)(c) is not authorized by the copyright owner, its agent, or the law; and</li>
            <br></br>
            <li>A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.</li>
        </ul>
        <h5 style={{color: "#002d65", fontStyle: "italic"}}>Once Proper Infringement Notification is Received by Zagmax</h5>
        <p>
        Upon receipt of a proper notice of copyright infringement, we reserve the right to:
        </p>
        <ul>
            <li>remove or disable access to the infringing material;</li>
            <br></br>
            <li>notify the content provider who is accused of infringement that we have removed or disabled access to the applicable material; and</li>
            <br></br>
            <li>terminate such content provider's access to the Services if he or she is a repeat offender.</li>
        </ul>
        <h5 style={{color: "#ff5e00"}}>Who is responsible for what I see and do on the Services?</h5>
        <p>
        Any information or Content publicly posted or privately transmitted through the Services is the sole 
        responsibility of the person from whom such Content originated, and you access all such information and 
        Content at your own risk, and we aren’t liable for any errors or omissions in that information or Content 
        or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to
         take any action regarding how you may interpret and use the Content or what actions you may take as a 
         result of having been exposed to the Content, and you hereby release us from all liability for you having 
         acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you 
        interact in using the Services and are not responsible for which users gain access to the Services.
        You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant
         you have all rights necessary to do so, in the manner in which you contribute it.
        </p>

        <p>
        The Services may contain links or connections to third-party websites or services that are not owned or controlled by Zagmax.
         When you access third-party websites or use third-party services, you accept that there are risks in doing
          so, and that Zagmax is not responsible for such risks.
        </p>

        <p>
        Zagmax has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or
         practices of or opinions expressed in any third-party websites or by any third party that you interact 
         with through the Services. In addition, Cars and Bids will not and cannot monitor, verify, censor or edit 
         the content of any third-party site or service. We encourage you to be aware when you leave the Services 
         and to read the terms and conditions and privacy policy of each third-party website or service that you
          visit or utilize. By using the Services, you release and hold us harmless from any and all liability 
          arising from your use of any third-party website or service.
        </p>

        <p>
        If there is a dispute between participants on this site or Services, or between users and any third
         party, you agree that Zagmax is under no obligation to become involved. In the event that you 
         have a dispute with one or more other users, you release Zagmax, its directors, officers, 
         employees, agents, and successors from claims, demands, and damages of every kind or nature, known 
         or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related 
         to such disputes and/or our Services. 
        </p>

        <h5 style={{color: "#ff5e00"}}>Will Zagmax ever change the Services?</h5>
        <p>
        We’re always trying to improve our Services, so they may change over time. We may suspend or 
        discontinue any part of the Services, or we may introduce new features or impose limits on certain 
        features or restrict access to parts or all of the Services. We reserve the right to remove any
         Content from the Services at any time, for any reason (including, but not limited to, if someone 
         alleges you contributed that Content in violation of these Terms), in our sole discretion, and
          without notice.
        </p>

        <h5 style={{color: "#ff5e00"}}>Do the Services cost anything?</h5>
        <p>
        It is free to register for the Services.
        </p>
        <p>
        We reserve the right to charge for certain or all Services in the future. 
        We will notify you before any Services you are then using begin carrying a fee, 
        and if you wish to continue using such Services, you must pay all applicable fees for such Services.
        </p>
        <h5 style={{color: "#ff5e00"}}>What if I want to stop using the Services?</h5>
        <p>
        You’re free to do that at any time by going to your profile and scrolling to the bottom of the page and selecting delete account; 
        please refer to our Privacy Policy, as well as the licenses above, 
        to understand how we treat information you provide to us after you have stopped using our Services.
        </p>

        <p>
        Zagmax is also free to terminate (or suspend access to) your use of the Services or your account
        for any reason in our discretion, including your breach of these Terms. Zagmax has the sole right
        to decide whether you are in violation of any of the restrictions set forth in these Terms; for example,
        a Bidder or Buyer who believes that a Seller may be in breach of these Terms is not able to enforce these Terms 
        against that Seller.
        </p>

        <p>
        Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.
        <br></br>
     
        </p>
 


        </div>
    </div>
  )
}

export default TermsOfUse