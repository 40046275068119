import * as React from 'react';
import { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { useParams, Link } from 'react-router-dom'
import { projectFirestore, auth } from './firebase/config';
import './styles/profileTabs.css'
import Countdown from 'react-countdown';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [userId, setUserId] = useState()  

  auth.onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.
      setUserId(user.uid);
    } else {
      // User not logged in or has just logged out. ??New Commit
    }
  });

  const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Over</span>;
    } else {
      // Render a countdown
      return <span>{ days ? <span>{days}</span> :<span> {hours}:{minutes}:{seconds} Left</span> } {days ? <span>Days Left</span>: null}</span>;
    }

  }; 


  let { uid } = useParams();

  const [reviews, setReviews] = useState([]);
        useEffect(() => {
            projectFirestore.collection("users").doc(uid).collection("Reviews").onSnapshot(snapshot =>(
                setReviews(snapshot.docs.map(doc => (
                    {
                        id: doc.id,
                        data: doc.data(),
                        
                    }
                )))
            ))
    }, [])

    const [post, setPosts] = useState([])
    const [lastDoc, setLastDoc] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false)


      useEffect(() => {
        projectFirestore.collection("Listings").where("OwnerId", "==", uid).onSnapshot(snapshot =>(
          setPosts(snapshot.docs.map(doc => (
              {
                  id: doc.id,
                  data: doc.data(),
                  
              }
          )))
      ))
    }, [])

    console.log(post)

    const viewMore = () => {

      setLoading(true)
      projectFirestore.collection("users").doc(userId).collection("Posts") 
      .startAfter(lastDoc)
      .limit(2)
      .get()
      
      .then((collections) =>{

        const isCollectionEmpty = collections.size === 0;
        console.log(isCollectionEmpty)

        if(!isCollectionEmpty){
        const listings = collections.docs.map((lists) => lists.data())
        const lastList = collections.docs[collections.docs.length - 1]
        setPosts(listOf => [...listOf, ...listings])
        setLastDoc(lastList)
        }else{
          setIsEmpty(true)
        }
        setLoading(false)
      })
}

console.log(post)

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Listings" {...a11yProps(0)} />
          <Tab label="Reviews" {...a11yProps(1)} />
        </Tabs>
      </Box>
      
      <TabPanel value={value} index={0}>
{post.map(({id, data: {  images, adTitle, startingPrice, bids, itemDescription, location, duration, buyItNowPrice, ownerID, timestamp }}) => (
<Link to={`/list/${id}`}>
<div className="user__listings"  >
  <div className="listing__img">        
       <img src={images[0]} />
  </div>

    <div className="list__info">
       <h2 id='profileTitle'>{adTitle}</h2>
      {startingPrice ? <h2 style={{color: "#ff5e00", marginTop: "-10px"}}>R{startingPrice.toLocaleString()}</h2> : <h2 style={{color: "#ff5e00", marginTop: "-10px"}}>R{buyItNowPrice.toLocaleString()}</h2>}
      {startingPrice ?
           <div className="pro__time">
           <h5 className="Auch"> Auction • <Countdown  date={duration} renderer={renderer}/></h5>
           </div> : <h5 className="buyNow">Buy It Now / Make Offer</h5>}
           <h4 id="tab__itemDesc">{itemDescription}</h4>
           <h3 className="pro__location"><i class="fas fa-map-marker-alt" ></i> {location}</h3>
    </div> 
     <div className='remove__btn'>
      
     </div>
 </div>
 </Link>
 ))}

      </TabPanel>




<TabPanel value={value} index={1}>
{reviews.map(({id, data: {Review, Stars, Name, timestamp }}) => (
<div className="user__listings"  >
  
  <div className="listing__img">  
  <small style={{ marginLeft: "5px"}}>{new Date(timestamp?.toDate()).toLocaleDateString('en-US', {
                  
                  day: 'numeric',  // numeric, 2-digit
                  year: 'numeric', // numeric, 2-digit
                  month: 'long', // numeric, 2-digit, long, short, narrow
                  
              })} </small>      
 <Box sx={{'& > legend': { mt: 2 }, }}> 
   <Rating name="read-only" value={Stars} readOnly />
 </Box>

  <h2 style={{ marginTop: "-5px"}}>{Name}</h2> 
  </div>
    <div className="list__info">
    <p>{Review}</p>
    </div>  
 </div>
 ))}

</TabPanel>
      
    </Box>
  );
}
