import React, {useState, useEffect} from 'react';
import {  projectFirestore } from './firebase/config';
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Rating from '@mui/material/Rating';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  //This is the product Id used as the link


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  console.log(value)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 

  const theme = createTheme({
    palette: {
      primary: {
        main: purple[500],
      },
      secondary: {
        main: '#f44336',
      },
    },
  });

  let { proId } = useParams();


  const [description, setDescription] = useState()
 
  useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setDescription(snapshot.data().itemDescription)
  ))
}, [])

const [ID, setID] = useState()
 
  useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
    setID(snapshot.data().OwnerId)
  ))
}, [])

const [reviews, setReviews] = useState([]);

        useEffect(() => {
          if(ID){
          if(value===1){
            projectFirestore.collection("users").doc(ID).collection("Reviews").onSnapshot(snapshot =>(
                setReviews(snapshot.docs.map(doc => (
                    {
                        id: doc.id,
                        data: doc.data(),
                        
                    }
                )))  
            ))
          }
        }
    }, [description, ID, value])
    console.log(ID)
    console.log(reviews)




  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', color: "#FF5E00"}}>
        <Tabs style={{ marginTop: '30px', fontWeight: 'bold'}} indicatorColor="primary" value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab style={{fontWeight: 'bold', color: "#272726"}} label="Description" {...a11yProps(0)} />
          <Tab style={{fontWeight: 'bold', color: "#272726"}}  label="Seller Reviews" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel id="descTab"  value={value} index={0}>
       <p>{description}</p>
      </TabPanel>




      <TabPanel id="descTab" value={value} index={1}>
      {reviews.map(({id, data: {Review, Stars, Name, timestamp }}) => (
<div className="user__listings"  >
  
  <div className="listing__img">  
  <small style={{ marginLeft: "5px"}}>{new Date(timestamp?.toDate()).toLocaleDateString('en-US', {
                  
                  day: 'numeric',  // numeric, 2-digit
                  year: 'numeric', // numeric, 2-digit
                  month: 'long', // numeric, 2-digit, long, short, narrow
                  
              })} </small>      
 <Box sx={{'& > legend': { mt: 2 }, }}> 
   <Rating name="read-only" value={Stars} readOnly />
 </Box>

  <h2 style={{ marginTop: "-5px"}}>{Name}</h2> 
  </div>
    <div className="list__info">
    <p>{Review}</p>
    </div>  
 </div>
 ))}
      </TabPanel>
     
    </Box>
  );
}