import algoliasearch from 'algoliasearch/lite';
import React from 'react';
import {
  InstantSearch,
  Configure,
  Hits,
  SearchBox,
  Panel,
  RefinementList,
  Pagination,
  Highlight,
  NumericMenu,
} from 'react-instantsearch-dom';
import Countdown from 'react-countdown';
import Avatar from '@mui/material/Avatar';
import TuneIcon from '@mui/icons-material/Tune';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import VerifiedIcon from '@mui/icons-material/Verified';
import {Link} from 'react-router-dom';
import './styles/search.css';

const searchClient = algoliasearch('CDRH54OZOX', 'd3558ea5e3c2a3adefc6c5306e043609');


export function Search() {

  
 
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="Listings">

       <header className="header">  
       
        <h1 className="header-title">
          ZAGMAX.COM
        </h1>
        <p className="header-subtitle">
         Search Products 
        </p>
        <SearchBox
               
               translations={{
                 placeholder: 'Search Products',
               }}
             />

      </header>

      <div className="container">
        
      
          <Configure hitsPerPage={4} page={2} />
          <div className="search-panel">
            <div className="search-panel__filters">
             
               
              
            </div>

            <div className="search-panel__results">
              <div className="search-box-container">
              
              </div>
              <Hits hitComponent={Hit} />

              <div className="pagination">
                <Pagination />
              </div>
            </div>
          </div>
       
      </div>
      </InstantSearch>
    </div>
  );
}

function Hit(props) {

  const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Auction Over</span>;
    } else {
      // Render a countdown
      return <span>{ days ? <span>{days}</span> :<span> {hours}:{minutes}:{seconds} Left</span> } {days ? <span>Days Left</span>: null}</span>;
    }
  }; 
  return (
    <Link to={`/list/${props.hit.id}`} >
    <div className='products__uiCard'>

      <div className='searchImage_div'>
      <img src={props.hit.images[0]} className="search__image"/>
     
        <div className='SearchSeller'>
          <div className='SearchSellerAvatar'>
          <Avatar   src={props.hit.OwnerPicture}/>
         
          </div>

          <div className='SearchSellerDetails'>
          <h5 id='SearchSellerName'>
          {props.hit.OwnerName}
          </h5>
          </div>
        </div>
      
      </div>

      <div className='search__cardDetails'>


      <h2 className='ad_Title'>
        <Highlight attribute="adTitle" hit={props.hit} />
      </h2>
      
      <h2 
        className="card__price2">R {props.hit.startingPrice ? props.hit.startingPrice.toLocaleString() : props.hit.buyItNowPrice.toLocaleString()}
      </h2>
      <p className='searchDescription'>
        <Highlight attribute="itemDescription" hit={props.hit} />
      </p>
      {props.hit.startingPrice ?
           <div className="bids__time2">
           <h5 className="card__bids2"><span id="separator2">{props.hit.bids} {props.hit.bids < 2 ? <span>Bid</span> : <span>Bids</span>} • </span></h5>
           <h5 className="card__time2"> Auction • <Countdown  date={props.hit.duration} renderer={renderer}/></h5>
      </div> : <h5 className="negotiable2">Buy It Now / Make Offer</h5>}

      <h3 className="card__location2"><i class="fas fa-map-marker-alt" ></i> {props.hit.location}</h3>
      </div>

    </div>
    </Link>
  );
}

