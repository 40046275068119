import React, {useEffect, useState} from 'react'
import Countdown from 'react-countdown';
import {projectFirestore, increment, auth, timestamp} from'./firebase/config'
import { useParams, Link } from 'react-router-dom'
import { selectUser, login, logout } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import MessageModal from './Modals/MessageModal';
import MessageWinner from './Modals/MessageWinner';
import LockedBid from './Tools/LockedBid'
import HowToBid from './Tools/HowToBid'
import NotAWinner from './Tools/NotAWinner'
import { useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LockIcon from '@mui/icons-material/Lock';
import ChatIcon from '@mui/icons-material/Chat';
import ConfirmDelete from './Modals/ConfirmDelete';
import CancelAuction from './Modals/CancelAuction';



function ContactUs() {

   //This is the product Id used as the link
   let { proId } = useParams();
   const user = useSelector(selectUser);
   const [bidInput, setBidInput] = useState();
   const { enqueueSnackbar } = useSnackbar();

   const [open, setOpen] = React.useState(false);

 

  const handleClose = () => {
    setOpen(false);
  };

   //below I fetch the product data
   const [productData, setProductData] = useState([])
   useEffect(() => {
       projectFirestore.collection("Listings").where("id", "==", proId).limit(1).onSnapshot(snapshot =>(
         setProductData(snapshot.docs.map(doc => (
             {
                 id: doc.id,
                 data: doc.data(),
                 
             }
         )))
     ))
     }, [])

     const [userId, setUserId] = useState([]) 
   
   auth.onAuthStateChanged((user) => {
     if (user) {
       // User logged in already or has just logged in.
       setUserId(user.uid);
     } else {
       // User not logged in or has just logged out.
     }
   });

   const [currPrice, setCurrPrice] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setCurrPrice(snapshot.data().startingPrice)
  ))
}, [])
    
    const [newCurrPrice, setnewCurrPrice] = useState()
    useEffect(() => {
      let newBid = currPrice
      setnewCurrPrice(newBid += 10)
    }, [currPrice])

    console.log(newCurrPrice)

    const [placeholder, setPlaceHolder] = useState()
    useEffect(() => {
      setPlaceHolder(parseInt(newCurrPrice))
    },[newCurrPrice])

   const message = "Please Sign In To Bid";
   const message2 = "You Have To Bid From The Current Price +R10 or More";
   const message3 = "You're the high bidder on this item, but you have to keep watch in case someone outbids you.";
   const message4 = "Please enter amount";
   const handleClickOpen = (e) => {
   if(user){ 
    if(bidInput){setOpen(true);
    e.preventDefault();
    }else{
      e.preventDefault()
      enqueueSnackbar(message4, { 
        variant: 'warning',

        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
      },
      TransitionComponent: Fade,

      autoHideDuration: 90000,
    });
    }
  }else{
    e.preventDefault()
    enqueueSnackbar(message, { 
        variant: 'error',

        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
      },
      TransitionComponent: Fade,

      autoHideDuration: 90000,
    });
  }
  };
     console.log(productData)

  const sendUpdate = (e, variant) => {
      e.preventDefault()
   
  if(user){

    if(bidInput < newCurrPrice){  
      enqueueSnackbar(message2, { 
        variant: 'error',

        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
      },
      TransitionComponent: Fade,

      autoHideDuration: 90000,
    });
    setBidInput('')
    }else{
      if(bidInput > 1000000000){
     alert("The Amount Is To High ")
  }else{
    projectFirestore.collection("Listings").doc(proId).update({
      bidder: user?.displayName,
      Winner: user?.email,
      winnerName: user?.displayName,
      startingPrice: parseInt(bidInput),
      WinnerId: userId,
      WinnerPicture: user?.photoUrl,
      bids: increment,
    })
    setBidInput('')
    projectFirestore.collection("Listings")
    .doc(proId)
    .collection("Bidders").add({
       bidderName: user?.displayName,
       bidTime: timestamp,
       bidAmount: parseInt(bidInput)
    })

    enqueueSnackbar(message3, { 
      variant: 'success',

      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    TransitionComponent: Fade,

    autoHideDuration: 90000,
  });
  }
    }
    } else{
      enqueueSnackbar(message, { 
        variant: 'error',

        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
      },
      TransitionComponent: Fade,

      autoHideDuration: 90000,
    });
    }
    setOpen(false);
    }; 
  
     
    const deleteList = () => {
      projectFirestore.collection("Listings").doc(proId).delete().then(() => {
        console.log("Document successfully deleted!");
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
    }
    

     const [listedProduct, setListedProduct]=useState([])
    

     useEffect(() => {
       projectFirestore.collection("Listings").onSnapshot(snapshot => (
         setListedProduct(snapshot.docs.map(doc => doc.id))
       ))
     }, [])
     const result = listedProduct.find(findUser)
            

     function findUser(item){
         return item === proId
     }
 


     const [con, setCon] = useState()
     useEffect(() =>{
      if(bidInput){
       setCon(parseInt(bidInput))
      }
     },[bidInput])
     console.log(con)

const findProduct = productData.find(function(data, index) {
 if(data.id === proId)
   return true;
});
  // Random component


  const Completionist = () => <div>
  {findProduct.data.Owner === user?.email ? (
  <div>
   { findProduct.data.Winner ?(<div> <CancelAuction />
   <MessageWinner /></div>):( <div>
    <button id="chat__btn" onClick={deleteList} style={{backgroundColor: "#FF5E00", marginBottom: "-8px"}}>Delete Auction</button>
     <button id="chat__btn" style={{backgroundColor: "grey", marginTop: "20px"}}>
       Nobody won <LockIcon style={{marginBottom: "-5px", marginLeft: "5px"}}/>
      </button>
      </div>)}
  </div>
  
  ):(<div>{findProduct.data.Winner === user?.email ? ( <MessageModal />) 
  : (<div style={{display: 'flex'}}><button id="chat__btn" style={{backgroundColor: "grey"}}>Chat With Seller <ChatIcon style={{marginBottom: "-5px",marginLeft: "5px"}} />
  <LockIcon style={{marginBottom: "-5px", marginLeft: "5px"}}/></button> <NotAWinner /></div>)}</div>)}  
  </div>

// Renderer callback with condition
const renderer = ({days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <div>
       <div className="biddingProcess">
              { findProduct.data.Owner === user?.email ? (
                          null
                      )  : <form className="bid__form">
                      <div className="input__bid">
                        <input type="number" id="inputThebid" placeholder={`Bid From R${placeholder.toLocaleString()} or More`} value={bidInput} onChange={e => setBidInput(e.target.value)}/>
                      </div>
                      <div style={{display: 'flex'}}>
                      <button className="submit__bid2" id="placeTheBid" onClick={handleClickOpen}>Place Bid</button>
                      <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            <p style={{fontWeight: "bolder"}}>Are you sure you want to Bid R{con ? con.toLocaleString(): null}</p>
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            When you confirm your bid, it means you're committing to buy this item if you're the winning bidder.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button color="error" onClick={handleClose}>Cancel</Button>
                            <Button variant="contained" onClick={sendUpdate} color="success" >
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
                       <HowToBid />
                      </div>
                      
                    </form>
                     } 
                     </div>
                     <div>
                     { findProduct.data.Owner === user?.email ? (<div>
                         <ConfirmDelete />
                         <button id="chat__btn" style={{backgroundColor: "grey"}} >Chat With The Winner <LockIcon style={{marginBottom: "-5px", marginLeft: "5px"}}/></button></div>
                        )  :  <div style={{display: "flex"}}>
                              <button id="chat__btn2"  style={{backgroundColor: "grey"}}>Chat With The Seller <ChatIcon style={{marginBottom: "-5px",marginLeft: "5px"}} />
                              <LockIcon style={{marginBottom: "-5px", marginLeft: "5px"}}/></button>
                              <LockedBid />
                              </div>
                     }
                      
                     </div>
      
      </div>;
  }
};


if (proId === result ) {
  return (

    
    <div>

    
    { findProduct.data.duration ? (
    <Countdown
    date={findProduct.data.duration}
    renderer={renderer}
    />
      )  : null
    }

    </div>
  )
  }else {
    return <h1>This Product does not exists Does Not Exist</h1>
  }



}

export default ContactUs
