import React from 'react'
import './styles/allCat.css'
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import CottageIcon from '@mui/icons-material/Cottage';
import { Link } from 'react-router-dom';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import HandymanIcon from '@mui/icons-material/Handyman';
import DeckIcon from '@mui/icons-material/Deck';
import ShieldIcon from '@mui/icons-material/Shield';

function AllCategoriesMenu() {
  return (
    <div className="catHeader">
     
        <h2>Vehicles <DirectionsCarFilledIcon style={{marginBottom: "-5px",marginLeft: "1px"}}/></h2>
    
      
        <div className="first4">
        <Link to='/cars-and-bakkies'><button className="firstBtns">Cars And Bakkies</button></Link>
          <Link to='/parts-and-accessories'><button className="firstBtns">Car Parts And Accessories</button></Link>
          <Link to='/motorcycles'><button className="firstBtns">Motorcycles And Scooters</button></Link>
          <Link to='/trucks-and-commercial-vehicles'> <button className="firstBtns">Trucks And Commercial Vehicles</button></Link>
  
          <Link to='/boats'><button className="firstBtns">Boats</button></Link> 
        </div>
        <h2>Electronics <ElectricalServicesIcon style={{marginBottom: "-6px",marginLeft: "1px"}}/></h2>
        <div className="first4">
         <Link to='/accessories'><button className="firstBtns">Accessories</button></Link> 
         <Link to='/cellphones'><button className="firstBtns">Cellphones</button></Link>
         <Link to='/tvs'><button className="firstBtns">TV, Audio & Visual</button></Link>
         <Link to='/gaming'><button className="firstBtns">Gaming & Consoles</button></Link>
         <Link to='/computers'><button className="firstBtns">Computers & Laptops</button></Link>
         <Link to='/cameras'><button className="firstBtns">Cameras</button></Link>
         <Link to='/music'><button className="firstBtns">Musical & Music</button></Link> 
        </div>
        <h2>Fashion <CheckroomIcon style={{marginBottom: "-4px",marginLeft: "1px"}}/></h2>
        <div className="first4">
         <Link to="/fashion-for-men"><button className="firstBtns">Fashion for Men</button></Link>
         <Link to="/fashion-for-women"><button className="firstBtns">Fashion for Women</button></Link>
         <Link to="/jewellery-for-men"> <button className="firstBtns">Jewellery for Men</button></Link>
         <Link to="/jewellery-for-women"> <button className="firstBtns">Jewellery for Women</button></Link>
         <Link to="/cosmetics-for-men"> <button className="firstBtns">Cosmetics for Men</button></Link>
         <Link to="/cosmetics-for-women"> <button className="firstBtns">Cosmetics for Women</button></Link>
        </div>
        <h2>Property <CottageIcon style={{marginBottom: "-4px",marginLeft: "1px"}}/></h2>
        <div className="first4">
        <Link to='/houses'><button className="firstBtns">Houses</button></Link>
        <Link to='/apartments'><button className="firstBtns">Flats</button></Link>
        <Link to='/townhouses'> <button className="firstBtns">Townhouses</button></Link>
        <Link to='/vacant-land'> <button className="firstBtns">Vacant Land</button></Link>
        <Link to='/commercial-property'> <button className="firstBtns">Commercial Property</button></Link>
        </div>
        <h2>Children <ChildCareIcon style={{marginBottom: "-4px",marginLeft: "1px"}}/></h2>
        <div className="first4">
        <Link to='/boys-clothing'> <button className="firstBtns">boys-clothing</button></Link>
        <Link to='/girls-clothing'><button className="firstBtns">girls-clothing</button></Link>
        <Link to='/children-accessories'><button className="firstBtns">Prams, Cots & Accessories</button></Link>
        <Link to='/toys-for-boys'><button className="firstBtns">Toys for Boys</button></Link>
        <Link to='/toys-for-girls'> <button className="firstBtns">Toys for Girls</button></Link>
        </div>
        <h2>Tools & Workshop Equipment<HandymanIcon style={{marginBottom: "-4px",marginLeft: "1px"}}/></h2>
        <div className="first4">
          <Link to="/generators"><button className="firstBtns">Generators & Solar Powers</button></Link>
          <Link to="/tools"> <button className="firstBtns">Hand Tools & Powered Tools</button></Link>
        </div>
        <h2>Home & Garden<DeckIcon style={{marginBottom: "-4px",marginLeft: "1px"}}/></h2>
        <div className="first4">
        <Link to="/furniture"><button className="firstBtns">Furniture</button></Link>
        <Link to="/home-appliances"><button className="firstBtns">Home Appliances</button></Link>
        <Link to="/braai-and-garden"><button className="firstBtns">Braai & Garden</button></Link>
        <Link to="/home-decor"><button className="firstBtns">Home Decor</button></Link>
        <Link to="/lighting"><button className="firstBtns">Lighting</button></Link>
        <Link to="/office"><button className="firstBtns">Office</button></Link>
        </div>
        <h2>Security<ShieldIcon style={{marginBottom: "-4px",marginLeft: "1px"}}/></h2>
        <div className="first4">
        <Link to="/alarms"><button className="firstBtns">Alarms</button></Link>
        <Link to="/cctv"><button className="firstBtns">CCTV Cameras</button></Link>
        <Link to="/locks"> <button className="firstBtns">Locks</button></Link>
        </div>
    </div>
  )
}

export default AllCategoriesMenu