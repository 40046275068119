import React from 'react';
import './styles/button.css';
import { Link } from 'react-router-dom';

export function Button2() {
  return (
    <Link to='/listing'>
      <button className='btn'>Sell <i class="fas fa-plus"></i></button>
    </Link>
  );
}
