import React, {useState, useEffect} from 'react'
import './styles/profile.css'
import { selectUser, login, logout } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom'
import { auth, provider, projectFirestore, projectStorage } from './firebase/config';
import BasicTabs from './ProfileTabs';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import EditName from './Modals/EditName'
import EditBio from './Modals/EditBio'
import blank_profile from './images/blank_profile.png'
import RateModal from './Modals/RateModal'
import { SnackbarProvider, useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Report2 from "./Modals/Report2";
import firebase from 'firebase';
import LinearProgress from '@mui/material/LinearProgress';
import FlagIcon from '@mui/icons-material/Flag';
import Danger from './Modals/Danger';






function Profile() {    

  let { uid } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const userSSS = auth.currentUser;
  const user = useSelector(selectUser);

  const [userProfile, setUserProfile]=useState([])
 
  const [userData, setUserData]=useState([])

  const [userId, setUserId] = useState([]) 
  const [value2, setValue2] = React.useState(4);

auth.onAuthStateChanged((user) => {
  if (user) {
    // User logged in already or has just logged in.
    setUserId(user.uid);
  } else {
    // User not logged in or has just logged out.
  }
});

const userPic = firebase.auth().currentUser;


const types = ['image/png', 'image/jpeg'];
const [progress, setProgress] = useState(0);

const handleChange = (e) => {
for (var i = 0; i < e.target.files.length; i++) {
  var imageFile = e.target.files[i];


  if(imageFile && types.includes(imageFile.type)){
  uploadImageAsPromise(imageFile);
  }else{
    alert("Select A Image")
  }
}
};

//Handle waiting to upload each file using promise
function uploadImageAsPromise (imageFile) {
return new Promise(function (resolve, reject) {
let xll = Math.floor((Math.random() * 1000000000000000) + 1);
  var storageRef = projectStorage.ref(`${userId}/${imageFile.name}` + xll);

  //Upload file
  var task = storageRef.put(imageFile);

  //Update progress bar
  task.on("state_changed",
  (snapshot) => {
    const progress = Math.round(
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    );
    setProgress(progress);
  },
  (error) => {
    console.log(error);
  },
     
      
      
      async () => {
        await projectStorage
          .ref(`${userId}/${imageFile.name}` + xll)
          .getDownloadURL()
          .then((urls) => {
            userPic.updateProfile({
              photoURL: urls,
             }) 
            
             projectFirestore.collection('users').doc(userId).update({
              photoUrl: urls
             })

          });
        }
        
  );
});
}

const reload = () => {

      window.location.reload()

    }

useEffect(() => {
  if(progress === 100){
  setTimeout(reload, 5000)
  }
}, [progress === 100])

  /* ------------------------------------------------user profile---------------------------------- */
 
  useEffect(() => {
    projectFirestore.collection("users").where("id", "==", uid).onSnapshot(snapshot => (
      setUserProfile(snapshot.docs.map(doc => doc.id))
    ))
  }, [])


  
  const result = userProfile.find(findUser)
 

  function findUser(item){
    return item === uid
  }
 
 
  /* ------------------------------------------------user data---------------------------------- */
 
const [Five, setFive] = useState()
useEffect(() => {
  projectFirestore.collection("users").doc(uid).onSnapshot(snapshot =>(
      setFive(snapshot.data().five)
  ))
}, [])

const [One, setOne] = useState()
useEffect(() => {
  projectFirestore.collection("users").doc(uid).onSnapshot(snapshot =>(
      setOne(snapshot.data().one)
  ))
}, [])

const [Four, setFour] = useState()
useEffect(() => {
  projectFirestore.collection("users").doc(uid).onSnapshot(snapshot =>(
      setFour(snapshot.data().four)
  ))
}, [])

const [Three, setThree] = useState()
useEffect(() => {
  projectFirestore.collection("users").doc(uid).onSnapshot(snapshot =>(
      setThree(snapshot.data().three)
  ))
}, [])

const [Two, setTwo] = useState()
useEffect(() => {
  projectFirestore.collection("users").doc(uid).onSnapshot(snapshot =>(
      setTwo(snapshot.data().two)
  ))
}, [])

const [revNos, setRevNos] = useState()
useEffect(() => {
  projectFirestore.collection("users").doc(uid).onSnapshot(snapshot =>(
      setRevNos(snapshot.data().ReviewsNos)
  ))
}, [])


 const [avarageRate, setAvarageRate] = useState()
  useEffect(() => {

    setAvarageRate((5*Five + 4*Four + 3*Three + 2*Two + 1*One) / (Five+Four+Three+Two+One))

  }, [One, Two, Three, Four, Five])

  console.log(avarageRate)

  const [rounded, setRounded] = useState()

  useEffect(() => {
  setRounded(Math.round(avarageRate * 10) / 10)
}, [avarageRate])

  useEffect(() => {
    projectFirestore.collection("users").where("id", "==", uid).onSnapshot(snapshot =>(
      setUserData(snapshot.docs.map(doc => (
          {
              id: doc.id,
              data: doc.data(),
              
          }
      )))
  ))
  }, [])
  
  console.log(userData)  

  
  const findItems = userData.find(function(data, index) {
    if(data.id === uid)
      return true;
  });
  console.log(findItems)

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

 console.log(findItems)
 const message3 = "Sign In To Rate"

 const signInToRate = (e) => {
  e.preventDefault();
  enqueueSnackbar(message3, { 
    variant: 'error',

    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
  },
  TransitionComponent: Fade,

  autoHideDuration: 90000,
}); 
 }

 const message4 = "Sign In To Report The User"

 const signIn = () => {
  enqueueSnackbar(message4, { 
    variant: 'error',

    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
  },
  TransitionComponent: Fade,

  autoHideDuration: 90000,
}); 
 }
 
  
  if (uid === userId ) {
    return (
      <div>
        <div className="profile__banner">

        <img src={user?.photoUrl} onError={(e) => (e.target.onerror = null, e.target.src = blank_profile)} className="profile__img"/>
        <div>
        <h1 className="profile-name"> 
          {user?.displayName} 
        
        </h1>
        <small>{findItems ? <p className="user__info"> {findItems.data.listings} Listings | Member Since {new Date(findItems.data.timestamp?.toDate()).toLocaleDateString('en-US', {
                    year: 'numeric', // numeric, 2-digit
                    month: 'long',  // numeric, 2-digit, long, short, narrow
                    day: 'numeric', 
                    })} </p> : <p></p>}</small>
        </div>
        
        </div>

        <div className="profile__info">

        <div className="profile__sidebar">

          <div className="bio">
            <div className="bio2">

            <h2 className="profile__about">ABOUT</h2>
            {findItems ? <p id="aboutSection"> {findItems.data.about}</p> : <p></p>}

            </div>
          </div>
          <div className="profile__rating">
           <div>
             <h1 className="profile__ratings">{rounded || 0}</h1>
           </div>
          <div style={{marginTop: '10px'}}>
          <Box sx={{'& > legend': { mt: 2 },}}> 
          <Rating name="read-only" value={avarageRate} readOnly size="large"/>
          </Box>
           <h3 className="profile__reviews">{revNos} Reviews</h3>
          </div>
          </div>
          <LinearProgress id="linear" color="info" variant="determinate" value={progress} /> 
          <div className="profile__btns">
            
         <div className="upload-btn-wrapper2">
         
          <button className="btn2">UPDATE IMAGE</button>
          <input type="file" name="myfile" onChange={handleChange}/>
        </div> 
          <EditBio />
          <EditName />
          </div>
       </div>
       <div className="profile__tab">
           <BasicTabs />
       </div>
        </div>
        <div className="delete__profile">
          <div className="deleteDivider"></div>
          <div id="deleteAccount">
            <Danger />
          </div>
          <div className="deleteDivider"></div>
        </div>
      </div>
    ) 






  }else if (uid === result) {
  return (
  <div>
     
     {findItems ? <div>

      <div>
        <div className="profile__banner">
        
        <img src={findItems.data.photoUrl} onError={(e) => (e.target.onerror = null, e.target.src = blank_profile)} className="profile__img"/>
      
        <div>
        <h1 className="profile-name">{findItems.data.name}</h1>
        <small>{findItems ? <p className="user__info"> {findItems.data.listings} Listings | Member Since {new Date(findItems.data.timestamp?.toDate()).toLocaleDateString('en-US', {
                    year: 'numeric', // numeric, 2-digit
                    month: 'long',  // numeric, 2-digit, long, short, narrow
                    day: 'numeric', // numeric, 2-digit
                    })}</p> : <p></p>}</small>
        </div>
        <div className='reportUser'>
          {user ? <Report2 />: <button id="report__btn2" onClick={signIn}><FlagIcon style={{marginBottom: "-8px", marginRight: "5px"}}/>Report</button>}
        </div>

        </div>

        <div className="profile__info">

        <div className="profile__sidebar">

          <div className="bio">
          <div className="bio2">
            <h2 className="profile__about">ABOUT</h2>
           {findItems ? <p> {findItems.data.about}</p> : <p></p>}
            
            </div>
          </div>

          <div className="profile__rating">

           <div>
             <h1 className="profile__ratings">{rounded || 0}</h1>
           </div>

          <div style={{marginTop: '10px'}}>
          <Box sx={{'& > legend': { mt: 2 },}}> 
          <Rating name="read-only" value={avarageRate} readOnly size="large"/>
          </Box>
           <h3 className="profile__reviews">{revNos} Reviews</h3>
          </div>

          </div>

          <div className="profile__btns">
          <Link to="/listing"><button className="sell__btn">SELL +</button> </Link>

          {user ?  <RateModal /> : <button className="sell__btn2" onClick={signInToRate}>Rate</button>} 
          </div>
          </div>

          <div className="profile__tab">
          <BasicTabs />
          </div>
        </div>

      </div>
     
     </div> : <button>Login</button>}
  </div>
  )

  } else {
   return <div>
   <div className="containerTTT">
     <h2 id='oopsTTT'>Oops! User Not Found.</h2>
     <h3>The user page You looking for is not found, it may be because: </h3>
     <ul>
       <li>It does Not Exist</li>
       <li>The Owner has deleted it</li>
       <li>It was taken down due violation of Terms and conditions</li>
     </ul>
     <a href="/">Go back home</a>
</div>
 </div>
  }
}
  

export default Profile;
