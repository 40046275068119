import React, {useState, useEffect} from 'react'
import { Avatar, Badge } from '@material-ui/core';
import { useParams } from 'react-router-dom'
import {projectFirestore, auth} from './firebase/config'
import {Link} from 'react-router-dom'


function SidebarChat({id,name, image, lastMsg, alert}) {

    const [userId, setUserId] = useState('') 
    auth.onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          setUserId(user.uid);
        } else {
          // User not logged in or has just logged out.
        }
      });
    const {chatId} = useParams()

    const [messages, setMessagess] = useState("")

    useEffect(() => {
        if(id, userId){
            projectFirestore.collection("users")
                .doc(userId)
                .collection("Chats")
                .doc(id)
                .collection("Messages")
                .orderBy("timestamp", "desc")
                .onSnapshot((snapshot) => 
                    setMessagess(snapshot.docs.map((doc) =>
                    doc.data()))
                )
        }
    }, [id, userId])   
    console.log(id)
   

    return (
        <Link to={`/cht/${id}`}>
        <div className="sidebarChat">
        <Badge color="secondary" badgeContent={alert} id="AlertBadge"> <Avatar id="sideBar_Avatar" src={image} /></Badge>
            <div className="sidebarChat__info">
                <h3>{name}</h3>
                <p>{messages[0]?.message}</p>
            </div>
        </div>
        </Link>
    )
}

export default SidebarChat
