import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     <List>
        {['Close'].map((text, index) => (
          
          <ListItem button key={text}>

            <ListItemIcon>
            {index % 2 === 0 ? <CloseIcon />:<CloseIcon />}
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    
      <List>
        {['Home'].map((text, index) => (
          <Link to='/'>
          <ListItem button key={text}>
            <ListItemIcon>
            {index % 2 === 0 ? <HomeIcon />:<HomeIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
          </Link>
        ))}
      </List>
      <List>
        {['Help'].map((text, index) => (
          <Link to='/help'>
          <ListItem button key={text}>
            <ListItemIcon>
            {index % 2 === 0 ? <HelpIcon />:<HelpIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
          </Link>
        ))}
      </List>
      
      <div className="login__signUp">
      <Link to="/login">
      <button id="navLogin">Login</button>
      </Link>
      <Link to="/signup">
      <button id="navSignUp">Sign Up</button>
      </Link>
      </div>

      <Link to="/listing">
     <button id="navBtn">Sell +</button>
     </Link>
      
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuIcon onClick={toggleDrawer(anchor, true)} id="Menu__Icon">{anchor}</MenuIcon>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
