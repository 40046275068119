import * as React from 'react';
import { useState , useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {projectFirestore, increment, auth} from '../firebase/config'
import { useParams } from 'react-router-dom'
import { selectUser, login, logout } from '../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import firebase from 'firebase'




export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = React.useState(0);
  const [reviewInput, setReviewInput] = React.useState('');
  let { uid } = useParams();
  const user = useSelector(selectUser);

  const [userId, setUserId] = useState("") 
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          setUserId(user.uid);
        } else {
          // User not logged in or has just logged out.
        }
   });

  const [rater, setRater] = useState()
  useEffect(() => {
  projectFirestore.collection("users").doc(uid).
  onSnapshot(snapshot =>(
      setRater(snapshot.data().raters)
  ))
}, [])

const message3 = "Rating With A Star Is Required"





  const handleClickOpen = () => {
    setOpen(true);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const message = 'You Already Submitted A Review';

  const submitReview = (e, variant) => {

   if(value){ 
    e.preventDefault();
    if (rater.includes(userId)) {
      enqueueSnackbar(message, { 
        variant: 'warning',

        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
      },
      TransitionComponent: Fade,

      autoHideDuration: 90000,
    });
    }else{
  projectFirestore.collection('users').doc(uid).collection('Reviews').add({
    Name: user?.displayName,
    Stars: value,
    Review: reviewInput,
    timestamp: firebase.firestore.FieldValue.serverTimestamp()
  })

  if(value === 5){
    projectFirestore.collection('users').doc(uid).update({
      five: increment
    })
  }else if(value === 4){
    projectFirestore.collection('users').doc(uid).update({
      four: increment
    })
  }else if(value === 3){
    projectFirestore.collection('users').doc(uid).update({
      three: increment
    })
  }else if(value === 2){
    projectFirestore.collection('users').doc(uid).update({
      two: increment
    })
  }else if(value === 1){
    projectFirestore.collection('users').doc(uid).update({
      one: increment
    })
  }else{
    console.log("Should have rated with stars")
  }

  projectFirestore.collection('users').doc(uid).update({
    ReviewsNos: increment
  })
  enqueueSnackbar('Review Was Submitted', { variant });

  projectFirestore.collection('users').doc(uid).update( {
    raters: firebase.firestore.FieldValue.arrayUnion( userId )
  });
}
setOpen(false)
setValue(0)
setReviewInput("")
}else{
  e.preventDefault();
      enqueueSnackbar(message3, { 
        variant: 'error',
  
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
      },
      TransitionComponent: Fade,
  
      autoHideDuration: 90000,
    }); 
}
}
  return (
    <div>
      <button className="sell__btn2" onClick={handleClickOpen}>
        Rate
      </button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Rate The Experience After Working With User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can rate using the rating stars and by going in to details 
          </DialogContentText>
          <Box
      sx={{
        '& > legend': { mt: 2 },
      }}
    >
      <Typography component="legend">Rate</Typography>
      <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
    </Box>
    <TextField
          autoFocus
          margin="dense"
          label="Tells us more about working with the user."
          id="name"
          multiline
          fullWidth
          variant="outlined"
          value={reviewInput} onChange={e => setReviewInput(e.target.value)}
          style={{marginTop: "20px"}}
        />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={(e) => {submitReview(e, 'success')}} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
