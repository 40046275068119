import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom'
import {projectFirestore, auth} from '../firebase/config'
import { useHistory, useLocation } from 'react-router-dom';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [userId, setUserId] = useState('') 

  auth.onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.
      setUserId(user.uid);
    } else {
      // User not logged in or has just logged out.
    }
  });
  
  const {chatId} = useParams()
  const history = useHistory();

  const deleteChat = (e) => {
    e.preventDefault();

    projectFirestore.collection('users').doc(userId).collection('Chats').doc(chatId).delete()
     
    setOpen(false)

    history.push('/chts')
  }

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}><DeleteIcon /></IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure you want to delete The Chat?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Deleting the chat means you won't be able to message the person unless they message you. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} id="modal__btn">Cancel</Button>
          <Button onClick={deleteChat}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
