import * as React from 'react';
import { useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { auth, projectFirestore, } from '../firebase/config';
import firebase from 'firebase'

export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(false);
  const [nameInput, setNameInput] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [userId, setUserId] = useState("") 
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          setUserId(user.uid);
        } else {
          // User not logged in or has just logged out.
        }
      });
      console.log(userId)

const userr = firebase.auth().currentUser;


const updateName = (e) => {
  e.preventDefault();

 userr.updateProfile({
  displayName: nameInput,
 }) 

 projectFirestore.collection('users').doc(userId).update({
  name: nameInput
 })

 setOpen(false)
}

 

  return (
    <div>
     <button className='sell__btn3' variant='contained'color='success' onClick={handleClickOpen}>
        EDIT NAME
      </button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Editing The Name..."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have to Refresh the page to see your newly updated Name
          </DialogContentText>
          <TextField
          autoFocus
          margin="dense"
          label="Type New Name..."
          id="name"
          multiline
          fullWidth
          variant="outlined"
          value={nameInput} onChange={e => setNameInput(e.target.value)}
          
        />
        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          {nameInput === "" ? <Button disabled>Update</Button>:<Button onClick={updateName}>Update</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
