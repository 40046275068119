import React, {useEffect, useState} from 'react'
import '../styles/table.css'
import {useParams} from 'react-router-dom'
import {projectFirestore} from '../firebase/config'

function AccsTable() {
 
  let { proId } = useParams();

  const [bidders, setBidders] = useState([])

  useEffect(() => {
    projectFirestore.collection("Listings")
    .doc(proId).collection("Bidders")
    .orderBy("bidTime", "desc")
    .onSnapshot(snapshot =>(
      setBidders(snapshot.docs.map(doc => (
          {
              id: doc.id,
              data: doc.data(),
          }
      )))
  ))
  }, [])

  console.log(bidders)


  return (
    <div>

<table id="customers">

<tr>
    
    <th>Bidder Name</th>
    <th>Bid Amount</th>
    <th>Bid Time</th>
  </tr>

  {bidders.map(({id, data: {bidderName, bidAmount, bidTime }}) => (
      <tr>
        
         
      <td>{bidderName}</td>
      <td>R{bidAmount.toLocaleString()}</td>
      <td>{new Date(bidTime?.toDate()).toLocaleDateString('en-US', {
                        weekday: 'short', // long, short, narrow
                        day: 'numeric',  // numeric, 2-digit
                        month: 'long', // numeric, 2-digit, long, short, narrow
                        hour: 'numeric', // numeric, 2-digit
                        minute: 'numeric', // numeric, 2-digit
                        
                    })} </td>
      </tr>
  ))}
  
  

   

</table>
    </div>
  )
}

export default AccsTable