import * as React from 'react';
import { useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { auth, projectFirestore, } from '../firebase/config';

export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(false);
  const [bioInput, setBioInput] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [userId, setUserId] = useState("") 
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          setUserId(user.uid);
        } else {
          // User not logged in or has just logged out.
        }
      });
      console.log(userId)

  const sendBioUpdate = (e, variant) => {
    e.preventDefault()
 
    projectFirestore.collection("users").doc(userId).update({
      about: bioInput
    })
    setOpen(false);

  }; 

  return (
    <div>
      <button  className='sell__btn2' variant='contained' color='success' onClick={handleClickOpen}>
        EDIT ABOUT
      </button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Editing The About..."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          A new About may take up to 48 hours to be visible to others
          </DialogContentText>
          <TextField
          autoFocus
          margin="dense"
          label="Type New About..."
          id="name"
          multiline
          fullWidth
          variant="outlined"
          value={bioInput} onChange={e => setBioInput(e.target.value)}
          
        />
        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          {bioInput === ""? <Button disabled>Update</Button>: <Button onClick={sendBioUpdate}>Update</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
