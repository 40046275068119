import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, login, logout } from './redux/userSlice';
import { BrowserRouter as Router, Switch, Route, useParams, Link, Redirect } from 'react-router-dom';
import { auth } from './components/firebase/config';
import Login from './components/Login';
import Signup from './components/Signup';
import ContactUs from './components/ContactUs';
import Listing from './components/Listing';  
import Home from './components/Home';
import Watchlist from './components/Watchlist';  
import Profile from './components/Profile';
import Payment from './components/Payment';
import PrivateListing from './components/PrivateListing';
import Privacy from './Privacy'
import PrivateSignup from './components/PrivateSignup'
import PrivateLogin from './components/PrivateLogin'
import ListingExpand from './components/ListingExpand';
import ChatWrapper from './components/ChatWrapper';
import Help from './components/Help'
import Page404 from './components/Page404';
import TermsOfUse from './TermsOfUse'
import Footer from './components/Footer' 
import Electronics from './components/Categories/Electronics'
import HomeGarden from './components/Categories/HomeGarden'
import Property from './components/Categories/Property'
import Vehicles from './components/Categories/Vehicles';
import CarsAndBakkiesCategory from './components/Categories/CarsAndBakkiesCategory';
import PartsAndAccessories from './components/Categories/PartsAndAccessories';
import TrucksCategory from './components/Categories/TrucksCategory';
import MotorcyclesCategory from './components/Categories/MotorcyclesCategory';
import BoatsCategory from './components/Categories/BoatsCategory';
import AccessoriesCategory from './components/Categories/AccessoriesCategory';
import CellphonesCategory from './components/Categories/CellphonesCategory';
import AudioCategory from './components/Categories/AudioCategory';
import PcCategory from './components/Categories/PcCategory';
import GamingCategory from './components/Categories/GamingCategory';
import MusicCategory from './components/Categories/MusicCategory';
import CamerasCategory from './components/Categories/CamerasCategory';
import TvCategory from './components/Categories/TvCategory';
import FashionForMen from './components/Categories/FashionForMen';
import FashionForWomen from './components/Categories/FashionForWomen';
import JewelleryForMen from './components/Categories/JewelleryForMen';
import JewelleryForWomen from './components/Categories/JewelleryForWomen';
import CosmeticsForMen from './components/Categories/CosmeticsForMen';
import CosmeticsForWomen from './components/Categories/CosmeticsForWomen';
import HouseCategory from './components/Categories/HouseCategory';
import ApartamentsCategory from './components/Categories/ApartamentsCategory';
import TownhouseCategory from './components/Categories/TownhouseCategory';
import PlotCategory from './components/Categories/PlotCategory';
import CommercialCategory from './components/Categories/CommercialCategory';   
import FurnitureCategory from './components/Categories/FurnitureCategory';
import HomeAppliancesCategory from './components/Categories/HomeAppliencesCategory';
import HomeDecorCategory from './components/Categories/HomeDecorCategory';
import BraaiGardenCategory from './components/Categories/BraaiGardenCategory';
import LightingCategory from './components/Categories/LightingCategory';
import OfficeCategory from './components/Categories/OfficeCategory';
import PowerCategory from './components/Categories/PowerCategory';
import HandToolsCategory from './components/Categories/HandToolsCategory';
import BoysClothingCategory from './components/Categories/BoysClothingCategory';
import PramsCategory from './components/Categories/PramsCategory';
import GirlsClothingCategory from './components/Categories/GirlsClothingCategory';
import ToysCategory from './components/Categories/ToysCategory';
import ToysForGirls from './components/Categories/ToysForGirls';
import AlarmsCategory from './components/Categories/AlarmsCategory';
import NewAdded from './components/Categories/NewAdded';
import CctvCategory from './components/Categories/CctvCategory';
import LocksCategory from './components/Categories/LocksCategory';
import {Search } from './components/Search'
import AllCategoriesMenu from './components/AllCategoriesMenu';




 
function App() {

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) =>{
      if (userAuth) {
        //user logged in
        dispatch(
          login({
            displayName: userAuth.displayName,
            email: userAuth.email,
            photoUrl: userAuth.photoURL,
          })
        )
      }else{
        dispatch(logout());
      }
    })
  }, [])


  return (
    <div className="App">
      
      
     
     <div className="App__body">
       <Router>
      
      <Header />
      <Switch>
{/**/}
        <Route path='/' exact>
          <Home />
          <Footer />
        </Route>
        <PrivateListing path='/listing' component={Listing} />
        <Route path='/help' component={Help} /> 
        <PrivateSignup path='/signup' component={Signup} />
        <PrivateLogin path='/login' component={Login} />
        <Route path='/terms-of-use' component={TermsOfUse} />
        <Route path='/privacy' component={Privacy} />
        <Route path='/srch' component={Search} />
        <Route path='/categories' component={AllCategoriesMenu} />
        <Route path='/electronics' >
         <Electronics /> 
        <Footer />
        </Route>
        <Route path='/home-and-garden' >
         <HomeGarden /> 
        <Footer />
        </Route>
        <Route path='/property' >
         <Property /> 
        <Footer />
        </Route>
        <Route path='/vehicles'>
          <Vehicles />
          <Footer />
        </Route>
        <Route path='/cars-and-bakkies'>
          <CarsAndBakkiesCategory />
          <Footer />
        </Route>
        <Route path='/parts-and-accessories'>
          <PartsAndAccessories />
          <Footer />
        </Route>
        <Route path='/trucks-and-commercial-vehicles'>
          <TrucksCategory />
          <Footer />
        </Route>
        <Route path='/motorcycles'>
          <MotorcyclesCategory />
          <Footer />
        </Route>
        <Route path='/boats'>
          <BoatsCategory />
          <Footer />
        </Route>
        <Route path='/accessories'>
          <AccessoriesCategory />
          <Footer />
        </Route>
        <Route path='/cellphones'>
          <CellphonesCategory />
          <Footer />
        </Route>
        <Route path='/tvs'>
          <TvCategory />
          <Footer />
        </Route>
        <Route path='/audio'>
          <AudioCategory />
          <Footer />
        </Route>
        <Route path='/computers'>
          <PcCategory />
          <Footer />
        </Route>
        <Route path='/gaming'>
          <GamingCategory />
          <Footer />
        </Route>
        <Route path='/music'>
          <MusicCategory />
          <Footer />
        </Route>
        <Route path='/cameras'>
          <CamerasCategory />
          <Footer />
        </Route>
        <Route path='/fashion-for-men'>
          <FashionForMen />
          <Footer />
        </Route>
        <Route path='/fashion-for-women'>
          <FashionForWomen />
          <Footer />
        </Route>
        <Route path='/jewellery-for-men'>
          <JewelleryForMen />
          <Footer />
        </Route>
        <Route path='/jewellery-for-women'>
          <JewelleryForWomen />
          <Footer />
        </Route>
        <Route path='/cosmetics-for-men'>
          <CosmeticsForMen />
          <Footer />
        </Route>
        <Route path='/cosmetics-for-women'>
          <CosmeticsForWomen />
          <Footer />
        </Route>
        <Route path='/houses'>
          <HouseCategory />
          <Footer />
        </Route>
        <Route path='/apartments'>
          <ApartamentsCategory />
          <Footer />
        </Route>
        <Route path='/townhouses'>
          <TownhouseCategory />
          <Footer />
        </Route>
        <Route path='/vacant-land'>
          <PlotCategory />
          <Footer />
        </Route>
        <Route path='/commercial-property'>
          <CommercialCategory />
          <Footer />
        </Route>
        <Route path='/furniture'>
          <FurnitureCategory />
          <Footer />
        </Route>
        <Route path='/home-appliances'>
          <HomeAppliancesCategory />
          <Footer />
        </Route>
        <Route path='/home-decor'>
          <HomeDecorCategory />
          <Footer />
        </Route>
        <Route path='/braai-and-garden'>
          <BraaiGardenCategory />
          <Footer />
        </Route>
        <Route path='/lighting'>
          <LightingCategory />
          <Footer />
        </Route>
        <Route path='/office'>
          <OfficeCategory />
          <Footer />
        </Route>
        <Route path='/generators'>
          <PowerCategory />
          <Footer />
        </Route>
        <Route path='/newly-added'>
          <NewAdded />
          <Footer />
        </Route>
        <Route path='/tools'>
          <HandToolsCategory />
          <Footer />
        </Route>
        <Route path='/boys-clothing'>
          <BoysClothingCategory />
          <Footer />
        </Route>
        <Route path='/girls-clothing'>
          <GirlsClothingCategory />
          <Footer />
        </Route>
        <Route path='/children-accessories'>
          <PramsCategory />
          <Footer />
        </Route>
        <Route path='/toys-for-boys'>
          <ToysCategory />
          <Footer />
        </Route>
        <Route path='/toys-for-girls'>
          <ToysForGirls/>
          <Footer />
        </Route>
        <Route path='/alarms'>
          <AlarmsCategory />
          <Footer />
        </Route>
        <Route path='/cctv'>
          <CctvCategory />
          <Footer />
        </Route>
        <Route path='/locks'>
          <LocksCategory />
          <Footer />
        </Route>
        <Route path='/payment'>
          <Payment />
          <Footer />
        </Route>
        
        
        
        <Route exact path='/user/:uid' >
            <Profile />
        </Route>
       
        
        <Route path='/list/:proId'>
            <ListingExpand />
            <Footer />
        </Route>
        
        <ChatWrapper />
        <Route component={Page404} />
  
      </Switch>
      
    </Router>
    </div>
   

</div>
     
  );
}

export default App;
