import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles/home.css"
import welcome from './images/welcome.png'
import welcome2 from './images/welcome2.jpg'
import welcome3 from './images/welcome3.png'
function Banner() {
    return (
        <div className="relative" id="Carousel">
            <Carousel 
                autoPlay
                infiniteLoop
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                interval={15000}
                
            >
                <div>
                <img loading="lazy" className="banner__image" src={welcome} alt=""/>

                </div>
                <div>
                <img loading="lazy" className="banner__image"src={welcome2}  alt=""/>
                </div>
                <div>
                <img loading="lazy" className="banner__image"src={welcome3}  alt=""/>
                </div>
              

            </Carousel>
        </div>
    )
}

export default Banner
