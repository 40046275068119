import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Fade from '@material-ui/core/Fade';
import IconButton from '@mui/material/IconButton';
import {notistackRef, onClickDismiss} from './components/firebase/notistack'
import CloseIcon from '@mui/icons-material/Close';


ReactDOM.render(

  <React.StrictMode>
    <Provider store={store}>
    <SnackbarProvider maxSnack={3}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
   TransitionComponent={Fade}
   ref={notistackRef}
    action={(key) => (
        <IconButton style={{color: 'white'}} onClick={onClickDismiss(key)}>
            <CloseIcon />
        </IconButton>
    )}
    >

    <App />
    </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
  
);


