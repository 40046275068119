import React, { useState } from 'react'
import './styles/signup.css'
import googleIcon from './images/googleIcon.png'
import facebookIcon from './images/facebookIcon.png'
import logo from './images/logo.svg' 
import { auth, provider, projectFirestore } from './firebase/config';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, login, logout } from '../redux/userSlice';
import firebase from 'firebase';
import twitterIcon from './images/twitterIcon.png'
import appleIcon from './images/appleIcon.png'
import { useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Welcome from './alerts/Welcome'



function Signup() {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")

  

  const [welcome, setWelcome] = useState()

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const handleClick = () => history.push('/login');
  const userSSSS = useSelector(selectUser);
  const user = firebase.auth().currentUser;

  const { enqueueSnackbar } = useSnackbar();

  console.log(user)

  const signIn = () => {

      auth.signInWithPopup(provider)
      .then(({ user }) => {
        dispatch(
            login({
                displayName: user.displayName,
                email: user.email,
                photoUrl: user.photoURL,
            })
        )
      })
      .then(() => {

        const userUid = auth.currentUser.uid;
        const docRef =  projectFirestore.collection('globalUids').doc(userUid)
        docRef.get().then((doc) => {
          if(doc.exists){
            console.log("welcome back")
          }else{
            projectFirestore.collection("globalUids").doc(userUid).set({
              uniqueId: userUid,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(), 
            }) 

            projectFirestore.collection("users").doc(userUid).set({
              name: auth.currentUser.displayName,
              photoUrl: auth.currentUser.photoURL,
              email: auth.currentUser.email,
              newMessages: 0, 
              listings: 0,
              five: 0,
              four: 0,
              three: 0,
              two: 0,
              one: 0,
              ReviewsNos: 0,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              about: '***No About***',
              raters: [],
              id: userUid
          });
          }
        })
        
      })
      .catch((error) => alert(error.message));
  }






















  const message3 = "Please Enter your Email."
  const message0 = "Please Enter your Name."
  const message = "The Account Already Exists, Please Login."
  const message2 = "Welcome To Zagmax, You Can Go To Your Profile To View & Update Your Profile Image and About"
  const SignupEmail = (e) => {
     
    if(name){
      if(email){
    e.preventDefault();
      const docRef2 =  projectFirestore.collection('globalEmails').doc(email)
      docRef2.get().then((doc) => {
        if(doc.exists){
          enqueueSnackbar(message, { 
            variant: 'error',
      
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
          },
          TransitionComponent: Fade,
      
          autoHideDuration: 90000,
        });

        }else{
     
       auth.createUserWithEmailAndPassword(email, password)
      .then((userAuth) => {
         userAuth.user.updateProfile({
         displayName: name,
         photoURL: "---"
        })
        .then(() => {
          dispatch(login({
            email: userAuth.user.email,
            displayName: name,
            photoUrl: ""
          }))
        }).then(() => {
          const userUid = auth.currentUser.uid;
          projectFirestore.collection("globalEmails").doc(email).set({
            uniqueId: email,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(), 
          }) 

          projectFirestore.collection("users").doc(userUid).set({
            name: auth.currentUser.displayName,
            photoUrl: auth.currentUser.photoURL,
            email: auth.currentUser.email,
            newMessages: 0, 
            listings: 0,
            five: 0,
            four: 0,
            three: 0,
            two: 0,
            one: 0,
            ReviewsNos: 0,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            about: '***No About***',
            raters: [],
            id: userUid
          });
          enqueueSnackbar(message2, { 
            variant: 'success',
      
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
          },
          TransitionComponent: Fade,
      
          autoHideDuration: 90000,
        });
        })
       }).catch((error) => {
        enqueueSnackbar(error.message, { 
          variant: 'error',
    
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        TransitionComponent: Fade,
    
        autoHideDuration: 90000,
      });
      })
    
      }
      })
    }else{
      e.preventDefault();
      enqueueSnackbar(message3, { 
        variant: 'error',
  
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
      },
      TransitionComponent: Fade,
  
      autoHideDuration: 90000,
    }); 
    }
      
    }else{
      e.preventDefault();
      enqueueSnackbar(message0, { 
        variant: 'error',
  
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
      },
      TransitionComponent: Fade,
  
      autoHideDuration: 90000,
    });
    }

   }

   

    return (
        <div className="signup__form__wrapper">
        <img className='logo' src={logo} alt='logo'></img>
            <h1 >Create an Account</h1>
            
          <div className="social__signUp">
          <div>
            <img src={googleIcon} alt="google" className="google__icon"/>
          </div>
          <div> 
            <button className="google__btn" onClick={signIn}>Continue With Google</button>
          </div>
          </div>

          <div style={{marginTop: '-40px'}}>
          <h3 id="OrSignUp">Or Email</h3>
          </div>
          <div className="form__wrapper">
          <form>
       
             <div className="signup__inputs">
             <label className="signup__labels">
                 Name<span id="required">*</span>
             </label>
             <input 
             name="Name"
             type="text"
             placeholder="Enter Name"
             className="signUp__inputs"
             value={name} 
             onChange={e => setName(e.target.value)}
             />
             
             </div>
             <div className="signup__inputs">
             <label className="signup__labels">
                 Email<span id="required">*</span>
             </label>
             <input 
             name="Email"
             type="email"
             placeholder="Enter Email"
             className="signUp__inputs"
             value={email} 
             onChange={e => setEmail(e.target.value)}
             />
             </div>
             <div className="signup__inputs">
             <label className="signup__labels">
                 Password<span id="required">*</span>
             </label>
             <input 
             type="password"
             name="Password"
             placeholder="Enter Password"
             className="signUp__inputs"
             value={password} 
             onChange={e => setPassword(e.target.value)}
             />
             </div>
             <div style={{marginTop: '10px'}}>
               <small>By continuing I understand and agree with Zagmax’s 
                  <span><Link  style={{color: '#ff5e00'}} target="_blank"  to="/terms-of-use">Terms & Conditions</Link></span> and
                   <span ><Link style={{color: '#ff5e00'}} target="_blank"  to="/privacy">Privacy Policy</Link></span>.</small>
             </div>
             <button type="submit" className="signup__submit" onClick={SignupEmail}>Sign Up</button>

          </form>
          </div>
        <p>Already member? <span id="reg__link" onClick={handleClick}>Login</span></p>

        </div>
    )
}
export{Welcome}
export default Signup
