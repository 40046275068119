import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import '../styles/messageModal.css';
import { selectUser, login, logout } from '../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase'
import { useHistory, useLocation } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom'
import { auth, provider, projectFirestore, increment } from '../firebase/config';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fade from '@material-ui/core/Fade';

function MessageWinner() {

    const user = useSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();

  const [messageInput, setMessageInput] = useState('');
  const [userId, setUserId] = useState("") 
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          setUserId(user.uid);
        } else {
          // User not logged in or has just logged out.
        }
      });
      console.log(userId)



  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


      const [userArray, setUserArray] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setUserArray(snapshot.data().usersArray)
  ))
}, [])
const [ownerId, setOwnerId] = useState('')
const [ownerName, setOwnerName] = useState('')
const [ownerEmail, setOwnerEmail] = useState('')
const [photo, setPhoto] = useState('')
const [productName, setProductName] = useState('')
const [winner, setWinner] = useState('')
const [winnerId, setWinnerId] = useState('')
const [WinnerName, setWinnerName] = useState('')
const [winnerPic, setWinnerPic] = useState('')


useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setWinnerPic(snapshot.data().WinnerPicture)
  ))
}, [])

useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setProductName(snapshot.data().adTitle)
  ))
}, [])


useEffect(() => {
    projectFirestore.collection("Listings").doc(proId).
    onSnapshot(snapshot =>(
        setWinner(snapshot.data().Winner)
    ))
  }, [])

  useEffect(() => {
    projectFirestore.collection("Listings").doc(proId).
    onSnapshot(snapshot =>(
        setWinnerId(snapshot.data().WinnerId)
    ))
  }, [])

  useEffect(() => {
    projectFirestore.collection("Listings").doc(proId).
    onSnapshot(snapshot =>(
        setWinnerName(snapshot.data().winnerName)
    ))
  }, [])

useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setOwnerId(snapshot.data().OwnerId)
  ))
}, [])

useEffect(() => {
projectFirestore.collection("Listings").doc(proId).
onSnapshot(snapshot =>(
  setOwnerName(snapshot.data().OwnerName)
))
}, [])
useEffect(() => {
projectFirestore.collection("Listings").doc(proId).
onSnapshot(snapshot =>(
setOwnerEmail(snapshot.data().Owner)
))
}, [])
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setPhoto(snapshot.data().images[0])
  ))
}, [])
  
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  let subtitle;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  
   //This is the product Id used as the link
   let { proId } = useParams();
   
    //below I fetch the product data
    const [productData, setProductData] = useState([])
    useEffect(() => {
        projectFirestore.collection("Listings").onSnapshot(snapshot =>(
          setProductData(snapshot.docs.map(doc => (
              {
                  id: doc.id,
                  data: doc.data(),
                  
              }
          )))
      ))
      }, [])


      const [listedProduct, setListedProduct]=useState([])
    

     useEffect(() => {
       projectFirestore.collection("Listings").onSnapshot(snapshot => (
         setListedProduct(snapshot.docs.map(doc => doc.id))
       ))
     }, [])
     const result = listedProduct.find(findUser)
            

     function findUser(item){
         return item === proId
     }



const findProduct = productData.find(function(data, index) {
 if(data.id === proId)
   return true;
});

console.log(findProduct)

const message = `Go to Chats to Continue The Conversation with ${ownerName}`;

const sendPosts = (e, variant) => {
  e.preventDefault()
 
  if (userArray.includes(winnerId)) {

    enqueueSnackbar(message, { 
      variant: 'warning',

      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    TransitionComponent: Fade,

    autoHideDuration: 90000,
  });

  } else {
    const userDocRef = projectFirestore.collection('users').doc(winnerId).collection("Chats").doc();
    userDocRef
  .set({
      productTitle: productName,
      ProductPic: photo,
      profilePicture: user?.photoUrl,
      name: user?.displayName,
      userEmail:  user?.email,
      userIdentity: userId,
      TheList: proId,
      ToDeduct: 1,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      alerts: 1,
  })
  .then(() => {
      return userDocRef.collection('Messages').add({
        productTitle: productName,
        ProductPic: photo,
        profilePicture: user?.photoUrl,
        message: messageInput,
        name:  user?.displayName,
        userEmail: user?.email,
        photoUrl: user?.photoUrl,
        userIdentity: userId,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        TheList: proId,
        ToDeduct: 1,
      alerts: 1,
      });
  })
  .then(() => {
      return projectFirestore.collection('users').doc(userId).collection("Chats").doc(userDocRef.id).set({
        productTitle: productName,
        ProductPic: photo,
      profilePicture: winnerPic,
      name: WinnerName,
      userEmail: winner,
      userIdentity: winnerId,
      TheList: proId,
      ToDeduct: 0,
      alerts: 0,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
  })
  .then(() => {
      projectFirestore.collection('users')
      .doc(userId)
      .collection("Chats")
      .doc(userDocRef.id)
      .collection('Messages')
      .add({
        productTitle: productName,
        ProductPic: photo,
        profilePicture: winnerPic,
        message: messageInput,
        name: user?.displayName,
        userEmail: winner,
        userIdentity: winnerId,
        userIdentifier: userId,
        picture: photo,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        TheList: proId,
        ToDeduct: 0,
       alerts: 0,
      });

      projectFirestore.collection('users').doc(winnerId).update({
        newMessages: increment
      })
  })
  .catch((error) => {
      console.log(error);
  });
  enqueueSnackbar('Message Sent!', { variant });
  }

  projectFirestore.collection('Listings').doc(proId).update( {
    usersArray: firebase.firestore.FieldValue.arrayUnion( winnerId )
 });
 
 setOpen(false)
 setMessageInput('')
 
}
  

    return (

      <div>
      
      <button onClick={handleClickOpen} id="chat__btn" style={{backgroundColor: "green", marginTop: "20px"}}>Message The Winner <i class="fas fa-comments" id="chat__icon"></i><i class="fas fa-lock-open" id="chat__lock"></i></button>
      <Dialog open={open} onClose={handleClose}
       aria-labelledby="responsive-dialog-title"
       fullScreen={fullScreen}>
        <DialogTitle>Send Message</DialogTitle>
        <DialogContent>
          <DialogContentText>
            After sending the message you can view the message by going to chats.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Type Message..."
            id="name"
            multiline
            fullWidth
            variant="standard"
            value={messageInput} onChange={e => setMessageInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} id="modal__btn">Cancel</Button>
          {messageInput === "" ? <Button style={{color: "grey"}}>Send</Button> : <Button onClick={(e) => {sendPosts(e, 'success')}}>Send</Button>}
        </DialogActions>
      </Dialog>
    </div>
    )
}

export default MessageWinner
