import React, { useState, useEffect } from 'react'
import './form.css'
import { projectFirestore, projectStorage, auth, increment, timestamp } from '../firebase/config'
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, login, logout } from '../../redux/userSlice';
import { useHistory } from 'react-router-dom'; 
import { SnackbarProvider, useSnackbar } from 'notistack';
import {useForms } from 'react-hook-form'
import LinearProgress from '@mui/material/LinearProgress';
import add__image from '../images/add__image.png'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useForm } from "react-hook-form";
import Fade from '@material-ui/core/Fade';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Autocomplete from "react-google-autocomplete";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';





 
   
function Accessories() {

  const [userId, setUserId] = useState([]) 

  auth.onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.
      setUserId(user.uid);
    } else {
      // User not logged in or has just logged out.
    }
  });

  
  const [place, setPlace] = useState()

  



  const [alignment, setAlignment] = React.useState();

  const handleChange2 = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  console.log(alignment)

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [ url, setUrl ] = useState(null );
  const { enqueueSnackbar } = useSnackbar();


    const user = useSelector(selectUser);



    const [adTitleInput, setAdTitleInput] = useState('');
    const [conditionInput, setConditionInput] = useState('');
    const [conditionDescriptionInput, setConditionDescriptionInput] = useState('');
    const [itemDescriptionInput, setItemDescriptionInput] = useState('');
    const [sellingStyleInput, setSellingStyleInput] = useState('');
    const [quantityInput, setQuantityInput] = useState('');
    const [startingPriceInput, setStartingPriceInput] = useState();
    const [buyItNowPriceInput, setBuyItNowPriceInput] = useState('');
    const [returnsInput, setReturnsInput] = useState('');
    const [shippingInput, setShippingInput] = useState('');
    const [locationInput, setLocationInput] = useState('');
    const [durationInput, setDurationInput] = useState();

/*------------------------------------ validation play ground ----------------------------------------- */

const initialValues = {  
    title: "", 
    sellingStyle: "", 
    startingPrice: "", 
    duration: "", 
    buyItNowPrice: "",
    pictures: "",
    condition: "",
    itemDescription: "",
    location: "",
    Comment: "",
    quantity: "",
    returns: "",
    negotiable: "",
    
}

const message = 'Your Ad Was Successfully Posted.';

const [formValues, setFormValues] = useState(initialValues)
const [formErrors, setFormErrors] = useState({})
const [isSubmit, setIsSubmit] = useState(false)

console.log(formValues.sellingStyle)

const formChange = (e) => {
    const {name, value} = e.target;
    setFormValues({...formValues, [name]: value});
    console.log(formValues)
}
 
const handleSubmit = (e) => {
  e.preventDefault()
  setFormErrors(validate(formValues))

  setIsSubmit(true)
 

}

useEffect(() => {
  console.log(formErrors)
  if(Object.keys(formErrors).length === 0 && isSubmit){

    const userDocRef = projectFirestore.collection('Listings').doc();

      userDocRef.set({
        id: userDocRef.id,
        adTitle: formValues.title,
        condition: formValues.condition,
        conditionDescription: formValues.Comment,
        itemDescription: formValues.itemDescription,
        sellingStyle: formValues.sellingStyle,
        duration: durationDays,
        quantity: formValues.quantity,
        startingPrice: parseInt(formValues.startingPrice),
        buyItNowPrice: parseInt(formValues.buyItNowPrice),
        returns: formValues.returns,
        location: place.formatted_address,
        province: formValues.location,
        OwnerName: user.displayName, 
        Owner: user?.email,
        images: urls, 
        catagory: "Alarms",
        mainCatagory: "Security", 
        bids: 0, 
        OwnerId: userId,
        profilePic: user?.photoUrl,
        viewersArray: [],
        views: 0,
        Winner: "",
        winnerName: "",
        WinnerId: "",
        WinnerPicture: "",
        OwnerPicture: user?.photoUrl,
        usersArray: [],
        timestamp: timestamp,
        negotiable: formValues.negotiable,
        newAd: "yes"
      
    })

    projectFirestore.collection("users").doc(userId).update({
      listings: increment,
    })
   
    projectFirestore.collection("users").doc(userId).collection("Posts").doc(userDocRef.id).set({
        id: userDocRef.id,
        adTitle: formValues.title,
        images: urls, 
        startingPrice: parseInt(formValues.startingPrice),
        buyItNowPrice: parseInt(formValues.buyItNowPrice),
        location: formValues.location,
        timestamp: timestamp,
        duration: durationDays,
        ownerID: userId
    })
    enqueueSnackbar(message, { 
      variant: 'success',

      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    TransitionComponent: Fade,

    autoHideDuration: 90000,
  }); 
  history.push(`/user/${userId}`);

  }
}, [formErrors])

const validate = (values) => {
  const errors = {}
  if(!values.title){
    errors.title = "Title Is Required*"
  }
  if(!values.condition){
    errors.condition = "Condition Is Required*"
  }
  if(values.condition === "--"){
    errors.condition = "Condition Is Required*"
  }


 
  
  
  

  if(!values.itemDescription){
    errors.itemDescription = "Item Description Is Required*"
  }

  if(!values.sellingStyle){
    errors.sellingStyle = "Selling Style Is Required*"
  }

  if(!values.location){
    errors.location = "Location Is Required*"
  }

  if(values.sellingStyle == "--"){
    errors.sellingStyle = "Selling Style Is Required*"
  }

  if(values.sellingStyle === "Auction Style"){
    if(!values.startingPrice){
      errors.startingPrice = "Starting Price Is Required*"
    }
    if(values.startingPrice > 10000000000){
      errors.startingPrice = "Starting Price Is To High*"
    }
  }

  if(values.sellingStyle === "Auction Style"){
    if(!values.duration){
      errors.duration = "Duration Is Required*"
    }

    if(values.duration === "--"){
      errors.duration = "Duration Is Required*"
    }
  }

  if(values.sellingStyle === "Fixed Price"){
    if(!values.buyItNowPrice){
      errors.buyItNowPrice = "Fixed Price Is Required*"
    }

    if(values.buyItNowPrice > 10000000000){
      errors.buyItNowPrice = "Fixed Price= Is To High*"
    }
    if(!values.negotiable){
      errors.negotiable = "Negotiable Is Required*"
    }
    if(values.negotiable === "--"){
      errors.negotiable = "Negotiable Is Required*"
    }
  }

  if(urls.length <= 0){
    errors.pictures = "Upload at least 1 image*"
  } 

  if(!place){
    errors.ExactLocation = " Location is Required"
  }

  return errors;
}





/*------------------------------------ validation play ground ----------------------------------------- */

    const history = useHistory();
   

    useEffect((values) => {

        if(formValues.sellingStyle === "Auction Style"){
          formValues.buyItNowPrice = ''
        }else if(formValues.sellingStyle === "Fixed Price"){
          formValues.startingPrice = ''
          formValues.duration = ''
        }else{
          console.log("Select Style")
        }

    }, [formValues.sellingStyle])


    const [images, setImages] = useState([]);
    const [urls, setUrls] = useState([]);
    const [progress, setProgress] = useState(0);

    const types = ['image/png', 'image/jpeg'];
    const message28 = `One of the file is not a Image`;
    const message27 = `One or more of the Selected images is too large, Maximum size is "10MB"`;

    const handleChange = (e) => {
    for (var i = 0; i < e.target.files.length; i++) {
      var imageFile = e.target.files[i];


      if(imageFile.size > 10485760){
        enqueueSnackbar(message27, { 
          variant: 'error',
      
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          TransitionComponent: Fade,
      
          autoHideDuration: 90000,
        });
        
      }else{
        if(imageFile && types.includes(imageFile.type)){
            uploadImageAsPromise(imageFile);
            
            
            }else{
              enqueueSnackbar(message28, { 
                variant: 'warning',
      
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
              },
              TransitionComponent: Fade,
      
              autoHideDuration: 90000,
            });
            }
      }
  }
    };

    //Handle waiting to upload each file using promise
function uploadImageAsPromise (imageFile) {
  return new Promise(function (resolve, reject) {
    let xll = Math.floor((Math.random() * 1000000000000000) + 1);
      var storageRef = projectStorage.ref(`${userId}/${imageFile.name}` + xll);

      //Upload file
      var task = storageRef.put(imageFile);

      //Update progress bar
      task.on("state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
         
          
          
          async () => {
            await projectStorage
              .ref(`${userId}/${imageFile.name}` + xll)
              .getDownloadURL()
              .then((urls) => {
                setUrls((prevState) => [...prevState ,urls]);

              });
            }
            
      );
  });
}

        useEffect(() => {  
          
          images.map((image) => {
          const promises = []
          let xll = Math.floor((Math.random() * 1000000000000000) + 1);
          const uploadTask = projectStorage.ref(`${userId}/${image.name}` + xll).put(image); 
          promises.push(uploadTask);
          
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(progress);
            },
            (error) => {
              console.log(error);
            },
            
           
          );
        });
         
      
    
    }, [images])

      console.log(durationInput)

    

      

      

      const [durationDays, setDurationDays] = useState()

      useEffect(() => {
  if(formValues.duration){
        if(formValues.duration === "1"){
          setDurationDays(Date.now() + 86400000)
        }else if(formValues.duration === "2"){
          setDurationDays(Date.now() + 172800000)
        }else if(formValues.duration === "3"){
          setDurationDays(Date.now() + 259200000)
        }else if(formValues.duration === "6"){
          setDurationDays(Date.now() + 518400000)
        }else{setDurationDays(0)}
      }else{setDurationDays(0)}
      }, [formValues.duration])

    console.log(durationDays)
    

const deleteOne = (e) =>{
  e.preventDefault()  
  setUrls([])
}

const [Len, setLen] = useState()
useEffect(() => {
  setLen(urls.length)
}, [urls])

console.log(urls)

useEffect(() => {
  console.log(urls)
}, [urls, deleteOne])


     
    return (
        <div>
            <form className="listing__form" onSubmit={handleSubmit}>
    {/*-------------------------- Form Title------------------------------------ */}
                <h1 className="form__title">Alarms</h1>
                <h2 className="form__subTitle"> Item Details</h2>



    {/*------------------------------ Ad Title Input ------------------------------*/}   
                <div className="form__inputs">
                <label className="input__label">
                   Ad Title(Include what you are selling)<span id="required">*</span>
                </label>

                 <input
                 name="title"
                 type="text"
                 className="input__title"
                 maxlength="100"
                 value={formValues.title}  onChange={formChange}
                 />
               <p id="validation__Errors">{formErrors.title}</p> 
                </div>

            
               
               
        
    {/*-------------------------------- Condition Input --------------------------------*/}
              <div className="form__inputs">
                <label className="input__label">
                    Condition<span id="required">*</span>
                </label>

                <select 
                 className="condition__select"
                 name="condition"
                 value={formValues.condition}  onChange={formChange}
                 >
                    <option>--</option>
                    <option>New</option>
                    <option>Used</option>
                    <option>Refurbished</option>
                    <option>For Parts Or Not Working</option>
                 </select>
                 <p id="validation__Errors">{formErrors.condition}</p>
                 </div>
    {/*---------------------------- Condition Description Input --------------------------------*/}

                 <div className="form__inputs">
                <label className="input__label">
                    Comment On Condition(Optional)
                </label>

                <textarea
                 type="text"
                 className="condition__description"
                 name="Comment"
                 value={formValues.Comment}  onChange={formChange} maxlength="800">  
                    
                 </textarea>
                </div>
 
    {/*--------------------------------Upload Images--- --------------------------------*/}

                <div className="form__inputs">
                <label className="input__label">
                    Upload Images (Upload Up To 30)<span id="required">*</span>
                </label>

               
                <div className="upload-btn-wrapper">
                  <button class="upload__btn">Upload Images +</button>
                  <input multiple  type="file" name="myfile" onChange={handleChange}/>
                </div>
                <div>
                <button className='startOver' onClick={deleteOne}>Start Over</button>
                  <small style={{marginLeft: "10px", color: "#002d65", fontWeight: "bolder"}}>{Len}</small>
                </div>
              
                
                
                <LinearProgress style={{ marginTop: '10px', }} color="info" variant="determinate" value={progress} />
                   </div>

                <div style={{marginTop: "20px"}}>
             

            
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          {urls.map((value) => (
            <Grid key={value} item>
              <Paper sx={{ height: 60, width: 60, border:"1px solid #002d65" }} >
                <img style={{ height: "60px", width: "60px" }} src={value} />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <p id="validation__Errors">{formErrors.pictures}</p>

              
                </div>
                {/* */}

     {/*Electronics ** ------------------------------------------------------------------** Catagory */}  





    {/*-------------------------------- Item Description Input --------------------------------*/}

                <div className="form__inputs">
                <label className="input__label">
                    Item Description<span id="required">*</span>
                </label>

                <textarea
                 type="text"
                 className="item__description"
                 name='itemDescription'
                 value={formValues.itemDescription}  onChange={formChange} maxlength="2400" />
                 <p id="validation__Errors">{formErrors.itemDescription}</p>
                </div>


                <h2 className="form__subTitle">Selling Details</h2>

    {/*-------------------------------- Selling Style Input -----------------------------------*/}

                <div className="form__inputs">
                <label className="input__label">
                    Selling Style<span id="required">*</span>
                </label>

                <select
                 className="sellingStyle__select"
                 name='sellingStyle' 
                 value={formValues.sellingStyle}  onChange={formChange}
                  >
                        <option>--</option>
                        <option>Auction Style</option>
                        <option>Fixed Price</option>
                 </select>
                 <p id="validation__Errors">{formErrors.sellingStyle}</p>
                 </div>
     {/*-------------------------------- Starting Price Input --------------------------------*/}

    {formValues.sellingStyle === "Auction Style" ? <div> <div className="form__inputs">
                <label className="input__label">
                    Starting Price ZAR<span id="required">*</span>
                </label>

                <input type="number"
                 className="input__startingPrice"
                 name='startingPrice' 
                 value={formValues.startingPrice}  onChange={formChange}
                 maxlength="11"
                 />
                 <p id="validation__Errors">{formErrors.startingPrice}</p>
      
                </div>{/*------------------------------- Duration Input --------------------------------*/}
                 <div className="form__inputs">
                <label className="input__label">
                Duration In Days<span id="required">*</span>
                </label>

                <select
                 className="duration__select" 
                 name='duration' 
                 value={formValues.duration}  onChange={formChange}
                 >
                        <option>--</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>6</option>
                    </select>
                </div>
                <p id="validation__Errors">{formErrors.duration}</p>
                </div> :<div>
               {formValues.sellingStyle === "Fixed Price" ? <div className="form__inputs">
                <label className="input__label">
                    Fixed Price ZAR<span id="required">*</span>
                </label>
                <input type="number"
                 className="input__buyItNowPrice" value={formValues.buyItNowPrice}  onChange={formChange}
                 name='buyItNowPrice'
                 min="5"
                 max="7"
                 
                 />
                 <p id="validation__Errors">{formErrors.buyItNowPrice}</p>
                 {/*---------------------------- Negotiation Input --------------------------------*/}
                <div className="form__inputs">
                <label className="input__label">
                Negotiable?<span id="required">*</span>
                </label>

                <select 
                 className="condition__select"
                 name="negotiable"
                 value={formValues.negotiable}  onChange={formChange}
                 >
                    <option>--</option>
                    <option>Yes</option>
                    <option>No</option>
                 </select>
                 <p id="validation__Errors">{formErrors.negotiable}</p>
                 </div>
                 
                </div> : null} </div>}



               
    {/*-------------------------------- Quantity Input --------------------------------*/}

                <div className="form__inputs">
                <label className="input__label">
                    Quantity
                </label>

                <input type="number"
                 className="input__quantity" 
                 name='quantity' 
                 value={formValues.quantity}  onChange={formChange}
                 />
                </div>
               
  
   {/*-------------------------------- Returns Input --------------------------------*/}

                <div className="form__inputs">
                <label className="input__label">
                    Do You Accept Returns?
                </label>

                <select
                 className="returns__select" 
                 name='returns' 
                 value={formValues.returns}  onChange={formChange}
                 >
                        <option>--</option>
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                </div>

                <div className="form__inputs">
                <label className="input__label">
                    Province<span id="required">*</span>
                </label>

                <select
                 className="returns__select" 
                 name='location' 
                 value={formValues.location}  onChange={formChange}
                 >
                        <option>--</option>
                        <option>KwaZulu-Natal</option>
                        <option>Gauteng</option>
                        <option>Free State</option>
                        <option>Western Cape</option>
                        <option>Eastern Cape</option>
                        <option>Northern Cape</option>
                        <option>Limpopo</option>
                        <option>Mpumalanga</option>
                        <option>North West</option>
                    </select>
                </div>
            <p id="validation__Errors">{formErrors.location}</p>

   {/*-------------------------------- Location Input --------------------------------*/}
   <div className="form__inputs">
                <label className="input__label">
                    Search Location<span id="required">*</span>
                </label>

                
                <Autocomplete
                      className="location__inputs"
                      apiKey="AIzaSyDo0zYdlBGyMOb6Bt_gEOtfMLuEJtxKy4g"
                      
                      onPlaceSelected={(place) => {
                        setPlace(place);
                      }}
                      options={{
                        types: ["(regions)"],
                        componentRestrictions: { country: "za" },
                      }}
                 
                  />
                
                </div>
           <div>
                  {place ? <div className='location__places'>
                    
                    <List
                      sx={{  bgcolor: 'background.paper' }}
                      aria-label="contacts"
                    >
                      
                      <ListItem disablePadding>
                          <ListItemIcon>
                            <LocationOnIcon />
                          </ListItemIcon>
                          <p>{place.formatted_address} <CheckCircleOutlineIcon style={{color: "green",marginBottom: "-5px",marginLeft: "5px"}}/></p>
                      </ListItem>
                    
                     
                    </List>
                  </div> : null}
                 <p id="validation__Errors">{formErrors.ExactLocation}</p>
                </div>

  {/*-------------------------------- Submit Button-------- --------------------------------*/}


                <button type="submit" className="submit__btn" onClick={handleSubmit}>Submit</button>
                 
            </form>
        </div>
    )
}

export default Accessories
