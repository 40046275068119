import React from 'react'

function HomeSecurity() {
    return (
        <div>
            <h1>Home Security</h1>
        </div>
    )
}

export default HomeSecurity
