import * as React from 'react';
import { useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useParams} from 'react-router-dom'
import { projectFirestore } from '../firebase/config';
import firebase from 'firebase';
import { useHistory, useLocation } from 'react-router-dom';

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const history = useHistory();
     

  const handleClose = () => {
    setOpen(false);
  };
  let { uid } = useParams();

  const [Name, setName] = useState()
useEffect(() => {
  projectFirestore.collection("users").doc(uid).onSnapshot(snapshot =>(
      setName(snapshot.data().name)
  ))
}, [])

const user = firebase.auth().currentUser;

const permanently = () => {
  

user.delete().then(() => {
  // User deleted.
}).catch((error) => {
  // An error ocurred
  // ...
});

history.push('/');
setOpen(false)
}



  const [deleteAcc, setDeleteAcc] = useState("")

  return (
    <div>
      <Button variant="outlined" color='error' onClick={handleClickOpen}>
        DELETE ACCOUNT
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure you want to delete this account</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <p>Doing so will permanently delete the data linked to this account and its can't be undone.</p>
          <b></b>
         Confirm that you want to delete this account by typing your Name: {Name} 
          </DialogContentText>
          <TextField
          autoFocus
          margin="dense"
          label="Confirm that you want to delete this account"
          placeholder={`${Name}`}
          id="name"
          multiline
          fullWidth
          variant="outlined"
          value={deleteAcc} onChange={e => setDeleteAcc(e.target.value)}
          style={{marginTop: "20px"}}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {Name === deleteAcc ? <Button variant='contained' color="error" onClick={permanently}>delete</Button>
          : <Button variant='contained' color="error" disabled>delete</Button>
        }
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
