import React from 'react'

function Privacy() {
  return (
    <div>
        <div className="Terms__header">
            <h2>Privacy Policy</h2>
        </div>

        <div className="Terms__body">
        <p>Effective date: April 1st, 2022</p>

        <p>At Zagmax, we take your privacy seriously. Please read this Privacy Policy to learn how we treat 
        your personal information. <span style={{fontWeight: 'bolder'}}>By using or accessing our Services in any manner, you acknowledge that you 
        accept the practices and policies outlined below, and you hereby consent that we will collect, use and
         share your information as described in this Privacy Policy.</span>
         </p>

         <p>Remember that your use of the Services is at all times subject to our Terms of Use,
            which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have
            the definitions given to them in the Terms of Use.
         </p>
         <h5 style={{color: "#ff5e00"}}>What this Privacy Policy Covers</h5>
         <p>This Privacy Policy covers how we treat Personal Data that we gather when you access or use our
              Services. “Personal Data” means any information that identifies or relates to a particular 
              individual and also includes information referred to as “personally identifiable information” or 
              “personal information” under applicable data privacy laws, rules or regulations. This Privacy 
              Policy does not cover the practices of companies we don’t own or control or people we don’t 
              manage.
        </p>

        <h5 style={{color: "#ff5e00"}}>Categories of Personal Data We Collect</h5>
        <div style={{overflowX:"auto"}}>
        <table id="customers2">
  <tr>
    <th>Category of Personal Data</th>
    <th>Personal Data We Collect</th>
    <th> Source(s) of Personal Data</th>
    <th>Categories of Third Parties With Whom We Share this Personal Data for a Business Purpose:</th>
  </tr>
  <tr>
    <td>Profile Information</td>
    <td>
        <ul>
            <li>First and last name</li>
            <li> Email</li>
            <li>Photo</li>
            <li>Vehicle metadata</li>
        </ul>

    </td>
    <td>You</td>
    <td>
        <ul>
        <li>Service Providers</li>
        <li>Affiliates</li>
        <li>Acquirers</li>
        <li>Selected Recipients Authorized By You</li>
        </ul>
   </td>
  </tr>
 
</table>
</div>

<h5 style={{color: "#ff5e00"}}>How We Use Your Personal Data</h5>

<p>
We process Personal Data to operate, improve, understand and personalize our Services. We use Personal Data for the following purposes:
</p>

<ul>
    <li>To meet or fulfill the reason you provided the information to us.</li>
    <br></br>
    <li>To communicate with you about the Services, including Service announcements, updates or offers.</li>
    <br></br>
    <li>To provide support and assistance for the Services</li>
    <br></br>
    <li>To create and manage your Account or other user profiles.</li>
    <br></br>
    <li>To personalize website content and communications based on your preferences.</li>
    <br></br>
    <li>To respond to user inquiries and fulfill user requests.</li>
    <br></br>
    <li>To improve and develop the Services, including testing, research, analysis and product development.</li>
    <br></br>
    <li>To protect against or deter fraudulent, illegal or harmful actions and maintain the safety, security and integrity of our Services.</li>
    <br></br>
    <li>To comply with our legal or contractual obligations, resolve disputes, and enforce our Terms of Use.</li>
    <br></br>
    <li>  To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
</ul>
<p>
We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different,
unrelated, or incompatible purposes without providing you notice
</p>
<p>
As noted in the list above, we may communicate with you if you’ve provided us the means to do so. 
For example, if you’ve given us your email address, we may send you promotional email offers or email you
about your use of the Services. Also, we may receive a confirmation when you open an email from us, which helps us improve our Services.
</p>

<h5 style={{color: "#ff5e00"}}>Data Security and Retention</h5>

<p>
We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical,
technical, organizational and administrative security measures based on the type of Personal Data and how we 
are processing that data. For example, the Services use industry standard Secure Sockets Layer (SSL) 
technology to allow for the encryption of Personal Data you provide to us. You should also help protect your
data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access
to your computer or device and browser; and signing off after you have finished accessing your account. 
Although we work to protect the security of your account and other data that we hold in our records, please
be aware that no method of transmitting data over the Internet or storing data is completely secure. We
cannot guarantee the complete security of any data you share with us, and except as expressly required by 
law, we are not responsible for the theft, destruction, loss or inadvertent disclosure of your information 
or content.
</p>


<h5 style={{color: "#ff5e00"}}>Personal Data of Children</h5>

     <p>
     As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children 
     under 13 years of age; if you are a child under the age of 13, please do not attempt to register 
     for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal 
     Data from a child under 13 years of age, we will delete that information as quickly as possible.
      If you believe that a child under 13 years of age may have provided Personal Data to us,
     </p>
     
        </div>
    </div>
  )
}

export default Privacy