import React, { useEffect, useRef, useState } from 'react'
import { Avatar } from '@material-ui/core';
import './styles/chats.css'
import {Send} from '@material-ui/icons';
import { useParams } from 'react-router-dom'
import {projectFirestore, auth, increment} from './firebase/config'
import firebase from 'firebase';
import { selectUser, login, logout } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteChatConfirm from './Modals/DeleteChatConfirm'
import ChatDeleted from './alerts/ChatDeleted'
import Alert from '@mui/material/Alert';



function Chats() {

    
  const user = useSelector(selectUser);

  const dummy = useRef()

    const [userId, setUserId] = useState('') 
    const [title, setTitle] = useState('')
    const [productPic, setProductPic] = useState('')
    const [messages, setMessages] = useState([])
    const [messengerId, setMessengerId] = useState('')
    const [Input, setInput] = useState('')
    const [ownerId, setOwnerId] = useState('')
    const [ownerId2, setOwnerId2] = useState('')
    const [deduct, setToDeduct] = useState()
    const [headerName, setHeaderName] = useState('')
    const [toTheList, setToTheList] = useState('')

    auth.onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          setUserId(user.uid);
        } else {
          // User not logged in or has just logged out.
        }
      });
    const {chatId} = useParams()

    


    
    useEffect(() => {

        if(chatId, userId) {
            projectFirestore.collection('users')
            .doc(userId)
            .collection("Chats")
            .doc(chatId)
            .onSnapshot((snapshot) => setTitle
            (snapshot.data().productTitle))

            projectFirestore.collection('users')
            .doc(userId)
            .collection("Chats")
            .doc(chatId)
            .onSnapshot((snapshot) => setProductPic
            (snapshot.data().ProductPic))

            projectFirestore.collection('users')
            .doc(userId)
            .collection("Chats")
            .doc(chatId)
            .onSnapshot((snapshot) => setHeaderName
            (snapshot.data().name))



            projectFirestore.collection('users')
            .doc(userId)
            .collection("Chats")
            .doc(chatId)
            .collection("Messages")
            .orderBy("timestamp", "asc")
            .onSnapshot((snapshot) =>
                setMessages(snapshot.docs.map((doc) =>
                doc.data()))
            );

            projectFirestore.collection('users')
            .doc(userId)
            .collection("Chats")
            .doc(chatId)
            .onSnapshot((snapshot) => setMessengerId
            (snapshot.data().userIdentity))   
           

           projectFirestore.collection('users')
            .doc(userId)
            .collection("Chats")
            .doc(chatId)
            .onSnapshot((snapshot) => setToDeduct
            (snapshot.data().ToDeduct))   

            projectFirestore.collection('users')
            .doc(userId)
            .collection("Chats")
            .doc(chatId)
            .onSnapshot((snapshot) => setToTheList
            (snapshot.data().TheList)) 
        }

        
    }, [chatId, userId])

    

    



    
    
   
    

    const [alrt, setAlert] = useState()
    const NotSend = (e) => {
        e.preventDefault()
    }


    const sendMessage = (e) => {

        setInput('')
        e.preventDefault();
        const docRef =  projectFirestore.collection('users').doc(messengerId).collection('Chats').doc(chatId)

        docRef.get().then((doc) => {
            if (doc.exists) {
                projectFirestore.collection('users').doc(messengerId).collection('Chats').doc(chatId).collection("Messages").add({
                    message: Input,
                    name: user?.displayName,
                    userIdentifier: userId,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    alerts: increment
                })
        
                projectFirestore.collection('users').doc(messengerId).collection('Chats').doc(chatId).update({
                    alerts: increment
                })
                projectFirestore.collection('users').doc(messengerId).collection('Chats').doc(chatId).update({
                    ToDeduct: increment
                })
        
                projectFirestore.collection('users').doc(messengerId).update({
                    newMessages: increment
                })
        
                projectFirestore.collection('users').doc(userId).collection('Chats').doc(chatId).collection("Messages").add({
                    message: Input,
                    name: user?.displayName,
                    userIdentifier: userId,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp()
                })
                
        
                
        
                dummy.current.scrollIntoView({behavior: 'smooth'})
            } else {
            setAlert(<div><ChatDeleted /></div>)
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });

       
    }
    let date = new Date()
   

    useEffect(() => {
        if(chatId, userId, deduct){

       
        projectFirestore.collection('users').doc(userId).collection('Chats').doc(chatId).update({
         alerts: 0
        })
        projectFirestore.collection('users').doc(userId).update({
            newMessages: firebase.firestore.FieldValue.increment(-deduct)
        })
        projectFirestore.collection('users').doc(userId).collection('Chats').doc(chatId).update({
            ToDeduct: 0
           })
    }
    }, [chatId, userId, deduct])

    return (
        <div className="chat">
           {alrt}
{/*-------------------------Chat Header------------------------------------- */}          
            <div className="chat__header">
            
          <Link to='/chts'><ArrowBackIcon className="backToChats"/></Link> 
          <Link to={"/list/" + toTheList}><Avatar src={productPic}/></Link>
           
            <div className="chat__headerInfo">
               <Link to={"/list/" + toTheList}><h3 id="header__Title">{title}</h3></Link> 
                <small id="header__Name">Chatting with <Link to={"/user/"+ messengerId}style={{fontWeight: "bold", fontStyle: "italic", color: "#FF5E00"}}>{headerName}</Link></small>
            </div>
            <div> <DeleteChatConfirm /> </div>
            </div>  
{/*------------------------  Chat Body ------------------------------------- */}
            <div className="chat__body">
                <div className="messages__Wrapper">
                {messages.map(message => (
                    <p className={`chat__message ${message.userIdentifier === userId && 'chat__receiver'}`}>
                   
                    {message.message}
                    <span className="chat__timestamp">
                   
                    {new Date(message.timestamp?.toDate()).toLocaleDateString('en-US', {
                        weekday: 'short', // long, short, narrow
                        day: 'numeric',  // numeric, 2-digit
                        year: 'numeric', // numeric, 2-digit
                        month: 'long', // numeric, 2-digit, long, short, narrow
                        hour: 'numeric', // numeric, 2-digit
                        minute: 'numeric', // numeric, 2-digit
                        
                    })} 
                    </span>
                </p>
                ))}
                </div>
            <div ref={dummy}></div>

            </div>
{/*-------------------------Chat footer------------------------------------- */}
            <div className="chat__footer">
            
            <form>
           <textarea type="text" value={Input} onChange={e => setInput(e.target.value)} placeholder="Type a Message..." className="chat__input"></textarea>
           
           <div>

           {Input ? <button type="submit" className="chatSubmit__btn" onClick={sendMessage}><Send /></button> 
           : 
           <button className="chatSubmit__btn" style={{backgroundColor: "grey"}} onClick={NotSend}><Send /></button>}
           </div>
            </form>

            </div>
              
        </div>
    )
}

export default Chats
