import React, {useEffect, useState} from 'react'
import '../styles/table.css'
import {useParams} from 'react-router-dom'
import {projectFirestore} from '../firebase/config'
import BathtubIcon from '@mui/icons-material/Bathtub'
import BedIcon from '@mui/icons-material/Bed';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import PoolIcon from '@mui/icons-material/Pool';
import PetsIcon from '@mui/icons-material/Pets';
import ChairAltIcon from '@mui/icons-material/ChairAlt';



function AccsTable() {
 
  let { proId } = useParams();

  const [specs, setSpecs] = useState([])

  useEffect(() => {
    projectFirestore.collection("Listings")
    .where("id" , "==", proId).limit(1)
    .onSnapshot(snapshot =>(
      setSpecs(snapshot.docs.map(doc => (
          {
              id: doc.id,
              data: doc.data(),
          }
      )))
  ))
  }, [])

 


  return (
    <div>

<table id="customers2">
    



  {specs.map(({id, data: {typeOfProperty, bedrooms, bathrooms, parkingOrGarage, pet,swimmingPool,furnished }}) => (
      <>
  <tr>
      <td>Type of Property</td>
      <td >{typeOfProperty ===  "forSale" ? <>For Sale</> : <>To Rent</>}</td>
  </tr>
  
  <tr>
      <td><BedIcon style={{marginBottom: "-5px"}}/> Bedrooms</td>
      <td>{bedrooms}</td>
  </tr>
  <tr>
      <td><BathtubIcon style={{marginBottom: "-3px"}}/> Bathrooms</td>
      <td>{bathrooms}</td>
  </tr>
  <tr>
      <td><DirectionsCarFilledIcon  style={{marginBottom: "-5px"}}/>Parking / Garage</td>
      <td>{parkingOrGarage}</td>
  </tr>
  <tr>
      <td><PetsIcon style={{marginBottom: "-5px"}}/> Pet Friendly</td>
      <td>{pet}</td>
  </tr>
  <tr>
      <td><PoolIcon style={{marginBottom: "-5px"}}/> Swimming Pool</td>
      <td>{swimmingPool}</td>
  </tr>
  <tr>
      <td><ChairAltIcon style={{marginBottom: "-5px"}}/>Furnished</td>
      <td>{furnished}</td>
  </tr>
  </>
))}

   

</table>
    </div>
  )
}

export default AccsTable