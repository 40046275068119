import React from 'react'

function ToolsWorkshopEquipment() {
    return (
        <div>
            <h1>ToolsWorkshopEquipment</h1>
        </div>
    )
}

export default ToolsWorkshopEquipment
