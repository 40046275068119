import React, {useState, useEffect} from 'react'
import './styles/listingExpand.css'
import { Avatar } from '@material-ui/core';import ReactStars from "react-rating-stars-component";
import ListingExpSlider from './ListingExpSlider';
import { useParams, Link } from 'react-router-dom'
import { auth, provider, projectFirestore, increment, timestamp } from './firebase/config';
import Countdown from 'react-countdown';
import { selectUser, login, logout } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase'
import { useHistory, useLocation } from 'react-router-dom';
import ContactUs from './ContactUs';
import MessageModal from './Modals/MessageModal';
import Bidders from './Modals/Bidders';
import MessageTheSeller from './Tools/MessageTheSeller'
import DescriptionTab from './DescriptionTab'
import Box from '@mui/material/Box';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import Rating from '@mui/material/Rating';
import moment from 'moment'
import Alert from '@mui/material/Alert';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import AccsTable from './Schemas/AccsTable';
import Chip from '@mui/material/Chip';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import './styles/404.css'
import Report from './Modals/Report'
import FlagIcon from '@mui/icons-material/Flag';
import CarSpecs from './Schemas/CarSpecs'
import PhoneSpecs from './Schemas/PhoneSpecs'
import BikeSpecs from './Schemas/BikeSpecs'
import TruckSpecs from './Schemas/TruckSpecs'
import BoatSpecs from "./Schemas/BoatSpecs"
import HouseSpecs from "./Schemas/HouseSpecs"
import ConfirmDelete from './Modals/ConfirmDelete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

  
function ListingExpand({ item }) {

  TimeAgo.addDefaultLocale(en)
  const timeAgo = new TimeAgo('en-US')

  const user = useSelector(selectUser);
  const history = useHistory();
  

  const [bidInput, setBidInput] = useState('');
  const [messageInput, setMessageInput] = useState('');

  

    const Completionist = () => <span style={{color: 'White'}}>Ended</span>;

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <Completionist />;
        } else {
          // Render a countdown
          return <div > {days === 0 ? <span>{hours}:{minutes}:{seconds}</span> :<span>{days} {days < 2 ? <span>Day</span> : <span>Days</span>}</span> } </div>  ;
        }
      };

    //This is the product Id used as the link
    let { proId } = useParams();

    //below I fetch the product data
    const [productData, setProductData] = useState([])
    useEffect(() => {
        projectFirestore.collection("Listings").where("id", "==", proId).limit(1).onSnapshot(snapshot =>(
          setProductData(snapshot.docs.map(doc => (
              {
                  id: doc.id,
                  data: doc.data(),
                  
              }
          )))
      ))
      }, [])
     
console.log(productData)
      const [listedProduct, setListedProduct]=useState([])
      console.log(listedProduct)
      
      useEffect(() => {
        projectFirestore.collection("Listings").where("id", "==", proId).limit(1).onSnapshot(snapshot => (
          setListedProduct(snapshot.docs.map(doc => doc.id))
        ))
      }, [])
      
      

      const sendUpdate = (e, variant) => {
        e.preventDefault()
     
        projectFirestore.collection("Listings").doc(proId).update({
          bidder: user?.displayName,
          Winner: user?.email,
          winnerName: user?.displayName,
          startingPrice: parseInt(bidInput),
          bids: increment,
          WinnerId: userId
        })


      }; 

   
     
      const findProduct = productData.find(function(data, index) {
        if(data.id === proId)
          return true;
       });

      useEffect(() => {
        projectFirestore.collection("Listings").where("id", "==", proId).limit(1).onSnapshot(snapshot => (
          setListedProduct(snapshot.docs.map(doc => doc.id))
        ))
      }, [])

      useEffect(() => {
        projectFirestore.collection("Listings").where("id", "==", proId).limit(1).onSnapshot(snapshot => (
          setListedProduct(snapshot.docs.map(doc => doc.id))
        ))
      }, [])

      const result = listedProduct.find(findUser)

      function findUser(item){
        return item === proId
    }
      

      const [slideImages, setSlideImages] = useState([])
      const [timeL, setTimeL] = useState()

      useEffect(() => {
       setSlideImages(oldArray => [findProduct] );

      }, [findProduct])


      console.log(slideImages)

  
     const [ownerId, setOwnerId] = useState('')
     

      useEffect(() => {
            projectFirestore.collection("Listings").doc(proId).
            onSnapshot(snapshot =>(
                setOwnerId(snapshot.data().OwnerId)
            ))
      }, [])

  

const [userArray, setUserArray] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setUserArray(snapshot.data().usersArray)
  ))
}, [])

const [sellerCom, setSellerCom] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
    setSellerCom(snapshot.data().conditionDescription)
  ))
}, [])

const [descp, setDescp] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
    setDescp(snapshot.data().itemDescription)
  ))
}, [])

const [cars, setCars] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
    setCars(snapshot.data().catagory)
  ))
}, [])

const [duration, setDuration] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
    setDuration(snapshot.data().duration)
  ))
}, [])

const [opReturns, setOpReturns] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
    setOpReturns(snapshot.data().returns)
  ))
}, [])



const [Neg, setNeg] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
    setNeg(snapshot.data().negotiable)
  ))
}, [])


const [StartingPrice, setStartingPrice] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setStartingPrice(snapshot.data().startingPrice)
  ))
}, [])

const [FixedPrice, setFixedPrice] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setFixedPrice(snapshot.data().buyItNowPrice)
  ))
}, [])

const [viewArray, setViewArray] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setViewArray(snapshot.data().viewersArray)
  ))
}, [])


console.log(viewArray)

const [views, setViews] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
      setViews(snapshot.data().views)
  ))
}, [])
console.log(views)

const [usrListings, setUsrListings] = useState()

useEffect(() => {
 
 if(ownerId){ projectFirestore.collection("users").doc(ownerId).
  onSnapshot(snapshot =>(
      setUsrListings(snapshot.data().listings)
  ))
 }
}, [ownerId])

  console.log(userArray)
      

      const [userId, setUserId] = useState("") 
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          setUserId(user.uid);
        } else {
          // User not logged in or has just logged out.
        }
      });
      console.log(userId)
      


       
   
    const viewArrayyy = ['gvgvg', 'ccgftcf', 'PnicD413jxa4HjoMXnj0a8pGGVG2']

    const handleClick = () => history.push(`/user/${ownerId}`);

    const handleClick2 = () => history.push('/contactus');
 
    const message4 = "Sign In to report the listing"

 const signInFirst = () => {
  enqueueSnackbar(message4, { 
    variant: 'error',

    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
  },
  TransitionComponent: Fade,

  autoHideDuration: 90000,
}); 
 }
    
    useEffect(() => {
      projectFirestore.collection("Listings")
      .doc(proId)
      .update({
        views: increment,
      });
    }, []);

    const [rightPrice, setRightPrice] = useState()

    useEffect(() => {
      
      if(StartingPrice){
      setRightPrice(StartingPrice.toLocaleString())
      }
    }, [StartingPrice]);
console.log(rightPrice)

const [rightPrice2, setRightPrice2] = useState()

    useEffect(() => {
      
      if(FixedPrice){
      setRightPrice2(FixedPrice.toLocaleString())
      }
    }, [FixedPrice]);


    {/*---------------------------------------------------------Reviews------------ -------------*/}

  const [Five, setFive] = useState()
useEffect(() => {

 if(ownerId){ projectFirestore.collection("users").doc(ownerId).onSnapshot(snapshot =>(
      setFive(snapshot.data().five)
  ))
 }
}, [ownerId])

const [One, setOne] = useState()
useEffect(() => {
  if(ownerId){ projectFirestore.collection("users").doc(ownerId).onSnapshot(snapshot =>(
      setOne(snapshot.data().one)
  ))}
}, [ownerId])

const [Four, setFour] = useState()
useEffect(() => {
  if(ownerId){ projectFirestore.collection("users").doc(ownerId).onSnapshot(snapshot =>(
      setFour(snapshot.data().four)
  ))}
}, [ownerId])

const [Three, setThree] = useState()
useEffect(() => {
  if(ownerId){ projectFirestore.collection("users").doc(ownerId).onSnapshot(snapshot =>(
      setThree(snapshot.data().three)
  ))}
}, [ownerId])

const [Two, setTwo] = useState()
useEffect(() => {
  if(ownerId){ projectFirestore.collection("users").doc(ownerId).onSnapshot(snapshot =>(
      setTwo(snapshot.data().two)
  ))}
}, [ownerId])

const [revNos, setRevNos] = useState()
useEffect(() => {
  if(ownerId){ projectFirestore.collection("users").doc(ownerId).onSnapshot(snapshot =>(
      setRevNos(snapshot.data().ReviewsNos)
  ))}
}, [ownerId])

    const [avarageRate, setAvarageRate] = useState()
    useEffect(() => {
  
      setAvarageRate((5*Five + 4*Four + 3*Three + 2*Two + 1*One) / (Five+Four+Three+Two+One))
  
    }, [One, Two, Three, Four, Five])
  
    console.log(avarageRate)
    
    const { enqueueSnackbar } = useSnackbar();

    const message = 'Sign In To Chat';
    const SignToChat = (e) => {
      e.preventDefault();
      enqueueSnackbar(message, { 
        variant: 'error',
  
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
      },
      TransitionComponent: Fade,
  
      autoHideDuration: 90000,
    }); 
    }

const [watchlistTitle, setWatchlistTitle] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
    setWatchlistTitle(snapshot.data().adTitle)
  ))
}, [])

const [watchlistPics, setWatchlistPics] = useState([])
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
    setWatchlistPics(snapshot.data().images)
  ))
}, []) 

const [watchlistLocation, setWatchlistLocation] = useState()
useEffect(() => {
  projectFirestore.collection("Listings").doc(proId).
  onSnapshot(snapshot =>(
    setWatchlistLocation(snapshot.data().location)
  ))
}, [])
    
    const Watch = () => {

if(user){
   const docRef = projectFirestore.collection("users").doc(userId).collection("Watchlist").doc(proId)
     docRef.get().then((doc) => {
      if (doc.exists) {
        const message11 = 'Already Added';
     
        enqueueSnackbar(message11, { 
          variant: 'info',
    
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        TransitionComponent: Fade,
    
        autoHideDuration: 5000,
      })
      }else{
        projectFirestore.collection("users").doc(userId).collection("Watchlist").doc(proId).set({
          id: proId,
          adTitle: watchlistTitle,
          images: watchlistPics[0], 
          location: watchlistLocation,
          timestamp: timestamp,
          ownerID: userId,
          buyItNowPrice: FixedPrice,
          startingPrice: StartingPrice,
          duration: duration,
          itemDescription: descp
       })
  
       const message9 = 'Successfully Added';
     
        enqueueSnackbar(message9, { 
          variant: 'success',
    
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        TransitionComponent: Fade,
    
        autoHideDuration: 5000,
      })
      }
    })
  }else{
    const message8 = 'Sign in to add to Watchlist';
     
        enqueueSnackbar(message8, { 
          variant: 'error',
    
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        TransitionComponent: Fade,
    
        autoHideDuration: 5000,
      })
     
  }











    }
    
    

    if (proId === result ) {
    return (
        <div className="listing__expand">

        { findProduct ? <div>
{/*----------------------------------------Auction Style Listing-------------------------------------------------- */}          
        
        {findProduct.data.startingPrice ? <div>
            <div className="posted__usr">
                <div className="posted__usrAvatar">
                <Avatar id="usr_avatar" src={findProduct.data.profilePic}/>
                </div>
                <div className="posted__usrDetails" onClick={handleClick}>
                <h3 id="usr__name">{findProduct.data.OwnerName}</h3>
                    <small id="usr__listings">{usrListings} listings</small>
                </div>
            </div>

            <div className="usr__Reviews">
            <Box sx={{'& > legend': { mt: 2 },}}> 
            <Rating name="read-only" value={avarageRate} readOnly size="large"/>
           </Box>

            <small id="numberOfReviews">{revNos} Reviews</small>
            </div>

            <div className="aboutTheList">

            <div className="listingImages"> 
            <ListingExpSlider />
            </div>  

            <div className="listing__details">
                <div className="dashed__line">
                <h2 id="product__title">{findProduct.data.adTitle}</h2>
                </div>

                <div className="bidding__area">
                  
                    <h4 id="price">Current Bid •
                     <span id="current__price">R {rightPrice}</span>
                     <div id="bids">
                       <div id="bidsNo"> {findProduct.data.bids}</div>
                       <div id="bidders"><Bidders /></div>
                     </div>
                     </h4>
                     
                    <h4 id="winning__time"><AccessTimeIcon style={{marginRight: "3px", marginTop: "-3px"}}/> Time Left • <span style={{marginLeft: "6px"}}> <Countdown   date={findProduct.data.duration} renderer={renderer}/></span> </h4>
                      
                      { findProduct.data.Winner === user?.email ? (
                        <Alert style={{marginBottom: "10px", fontWeight: 'bold'}} severity="success">You Are Winning!</Alert>
                        )  : null
                      }

                    { findProduct.data.Owner === user?.email ? ( 
                      <div>
                        { findProduct.data.Winner ? ( <Alert style={{marginBottom: "10px", fontWeight: 'bold'}} severity="success" >{findProduct.data.winnerName} Is Winning!</Alert>) : <h3 id="winning__bid" style={{color: "grey"}}>No Bids Yet</h3> }
                      </div>
                        )  : null
                      }
                      
                      { findProduct.data.Owner === user?.email ? ( 
                    null
                        )  : <div>
                       <a href='https://www.youtube.com/watch?v=G-amKIlvjbU' target="__blank"><Alert style={{marginBottom: "10px", fontWeight: 'bold', textDecoration:"underline"}} severity="info" > How Buying Works</Alert></a> 
                        </div>
                      }
                    <div>
                    
      
                    
                    </div>
                     
                     
                     
                     <div>
                      <ContactUs />
                    
                      
                     </div>

                     <div>
                     { findProduct.data.Owner === user?.email ? (
                       <h4 className="addToWatchList"> 
                       Viewed • {views} {views <= 1 ? <span>time</span> : <span>times</span>} </h4>
                    )  :  <h4 className="addToWatchList" onClick={Watch}> <i class="fas fa-eye"></i> Add to Watchlist</h4>
                      }
                     </div>
            
                     <h3 id="winning__bid">Location • {findProduct.data.location}</h3>

                </div>

                <div className="shipping__returns">
                {findProduct.data.condition === "" ? null: <div><h4>Condition • {findProduct.data.condition}</h4></div> }
                </div>
               {opReturns === "" ? null : <div><h4>Returns • {opReturns}</h4></div>}
          
            
            
            
                
         
                
                
            </div>
            </div>
            <div>
              {user ? <Report />: <button id="report__btn"onClick={signInFirst}><FlagIcon style={{marginBottom: "-8px", marginRight: "5px"}}/>Report</button>}
            </div>

           {cars ===  "Cars and Bakkies" ? <div>
              <CarSpecs />
            </div> : null}

            {cars ===  "Cellphones" ? <div>
              <PhoneSpecs />
            </div> : null}

            {cars ===  "Motorcycles And Scooters" ? <div>
              <BikeSpecs />
            </div> : null}

            {cars ===  "Trucks And Commercial Vehicles" ? <div>
              <TruckSpecs />
            </div> : null}
            {cars ===  "House" ? <div>
              <HouseSpecs />
            </div> : null}

            {sellerCom === "" ? null : 

            <div className='seller__com__wrapper'>
            <div style={{display: "flex"}}><Avatar id="usr_avatar" src={findProduct.data.profilePic}/><h3 id='com__name'>{findProduct.data.OwnerName} Comment On Condition</h3></div>
            <p className='Seller__Com'>{sellerCom}</p>
            </div>
            
            }
            
           <DescriptionTab /> 
























 {/*---------------------------------Fixed Price Listing  -------------------------------------------------------------*/}
            </div> : <div>
            {findProduct.data.buyItNowPrice ? <div>
            <div className="posted__usr">
                <div className="posted__usrAvatar">
                <Avatar id="usr_avatar" onClick={handleClick} src={findProduct.data.profilePic}/>
                </div>
                <div className="posted__usrDetails" onClick={handleClick}>
                    <h3 id="usr__name">{findProduct.data.OwnerName} </h3>
                    <small id="usr__listings">{usrListings} listings</small>
                </div>

            </div>

            <div className="usr__Reviews">
            <Box sx={{'& > legend': { mt: 2 },}}> 
            <Rating name="read-only" value={avarageRate} readOnly size="large"/>
            </Box>

            <small id="numberOfReviews">{revNos} Reviews</small>
            </div>

            <div className="aboutTheList">

            <div className="listingImages"> 
            <ListingExpSlider />
            </div>  

            <div className="listing__details">
               <div className="dashed__line">
                <h2 id="product__title">{findProduct.data.adTitle}</h2>
                </div>
                

                <div className="bidding__area">
                    <h4 id="winning__bid"><span id="current__price">R {rightPrice2}</span></h4>
                      
                      
                        {Neg === "Yes" ? <div>
                        <Chip id="Chip" label="Negotiable" color="success" variant='outlined'  />
                          </div> : <div>
                        <Chip id="Chip" label="Non-Negotiable" color="error" variant='outlined'  />
                          </div> }
                      
      <small id="winning__bid">{moment(new Date(findProduct.data.timestamp?.toDate())).fromNow()}</small>

                    
                      
                    <div>
                    
      
                    
                    </div>
                     
                     <div className="biddingProcess">
                     { findProduct.data.Owner === user?.email ? (
                          null
                      )  : null
                     }
                     </div>
                     
                     <div>
                     { findProduct.data.Owner === user?.email ? (
                       <div>
                        <ConfirmDelete />
                        <Link to='/chts'><button id="chat__btn" style={{backgroundColor: "#002d65"}}>Open Chats</button></Link>
                         </div>
                        )  : <div> {user ? <MessageModal /> : <button onClick={SignToChat} id="chat__btn" style={{backgroundColor: "green", marginTop: "20px"}}>Message The Seller <LockOpenIcon /></button> }</div>
                      }
                         
                     </div>

                     <div>
                     { findProduct.data.Owner === user?.email ? (
                       <h4 className="addToWatchList"> 
                        Viewed • {views} {views <= 1 ? <span>time</span> : <span>times</span>} </h4>
                    )  :  <h4 className="addToWatchList" onClick={Watch} style={{marginTop: "45px"}}> <i class="fas fa-eye"></i> Add to Watchlist</h4>
                      }

                    <h3 id="winning__bid">Location: {findProduct.data.location}</h3>
                     </div>
            

                </div>

                   
                <div className="shipping__returns">
                {findProduct.data.condition === "" ? null: <div><h4>Condition • {findProduct.data.condition}</h4></div> }
                </div>
               {opReturns === "" ? null : <div><h4>Returns • {opReturns}</h4></div>}
                
         
                
                
            </div>
            </div>

            <div>
            {user ? <Report />: <button id="report__btn"onClick={signInFirst}><FlagIcon style={{marginBottom: "-8px", marginRight: "5px"}}/>Report</button>}
            </div>
            {cars === "Cars and Bakkies" ? <div>
              <CarSpecs />
            </div> : null}

            {cars ===  "Cellphones" ? <div>
              <PhoneSpecs />
            </div> : null}

            {cars ===  "Motorcycles And Scooters" ? <div>
              <BikeSpecs />
            </div> : null}

            {cars ===  "Trucks And Commercial Vehicles" ? <div>
              <TruckSpecs />
            </div> : null}

            {cars ===  "Boats" ? <div>
              <BoatSpecs />
            </div> : null}

            {cars ===  "House" ? <div>
              <HouseSpecs />
            </div> : null}
            {cars ===  "Apartment / Flat" ? <div>
              <HouseSpecs />
            </div> : null}

            {sellerCom === "" ? null : 

            <div className='seller__com__wrapper'>
            <div style={{display: "flex"}}><Avatar id="usr_avatar" src={findProduct.data.profilePic}/><h3 id='com__name'>{findProduct.data.OwnerName} Comment On Condition</h3></div>
            <p className='Seller__Com'>{sellerCom}</p>
            </div>
            
            }
            <DescriptionTab />
            </div> : <div>
              <Link to="/help"><h2 style={{textDecoration: "underline"}}>Error 500 Contact Us For Help </h2></Link>
              </div>}
              </div>}



        </div> : <div>The Listing Does Not Exist</div>}
        
            
        </div>
      
    )

    }else{
        return <div>
          <div className="containerTTT">
            <h2 id='oopsTTT'>Oops! Listing Not Found.</h2>
            <h3>The listing You looking for is not found, it may be because: </h3>
            <ul>
              <li>It does Not Exist</li>
              <li>The Owner has deleted it</li>
              <li>It has expired</li>
              <li>It was taken down due violation of Terms and conditions</li>
            </ul>
            <a href="/">Go back home</a>
       </div>
        </div>
    }
}

export default ListingExpand
