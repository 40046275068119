import React, {useEffect, useState} from 'react'
import '../styles/table.css'
import {useParams} from 'react-router-dom'
import {projectFirestore} from '../firebase/config'

function AccsTable() {
 
  let { proId } = useParams();

  const [specs, setSpecs] = useState([])

  useEffect(() => {
    projectFirestore.collection("Listings")
    .where("id" , "==", proId).limit(1)
    .onSnapshot(snapshot =>(
      setSpecs(snapshot.docs.map(doc => (
          {
              id: doc.id,
              data: doc.data(),
          }
      )))
  ))
  }, [])

 


  return (
    <div>

<table id="customers2">
    



  {specs.map(({id, data: { storage, colour }}) => (
      <>
  <tr>
      <td>Storage</td>
      <td >{storage}</td>
  </tr>
  
  <tr>
      <td>colour</td>
      <td>{colour}</td>
  </tr>
</>
))}

   

</table>
    </div>
  )
}

export default AccsTable