import React, {useState, useRef} from 'react';
import './styles/listing.css';
import { useHistory } from 'react-router-dom'; 
import { SnackbarProvider, useSnackbar } from 'notistack';
import { projectFirestore, projectStorage } from './firebase/config'
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, login, logout } from '../redux/userSlice';
import CarsAndBakkies from './listingFormsProps/CarsAndBakkies';
import Accessories from './listingFormsProps/Accessories';
import ApartmentFlat from './listingFormsProps/ApartmentFlat';
import BoatsAviation from './listingFormsProps/BoatsAviation';
import Cameras from './listingFormsProps/Cameras';

import CarPartsAccessories from './listingFormsProps/CarPartsAccessories';
import CellPhones from './listingFormsProps/CellPhones';
import ClothingAccessories from './listingFormsProps/ClothingAccessories';
import ClothingShoes from './listingFormsProps/ClothingShoes';
import CommercialProperty from './listingFormsProps/CommercialProperty';
import ComputersLaptops from './listingFormsProps/ComputersLaptops';
import GamingConsoles from './listingFormsProps/GamingConsoles';
import HealthBeautyCosmetics from './listingFormsProps/HealthBeautyCosmetics';
import HomeSecurity from './listingFormsProps/HomeSecurity';
import House from './listingFormsProps/House';
import JewelleryAccessories from './listingFormsProps/JewelleryAccessories';
import MotorCyclesScooters from './listingFormsProps/MotorCyclesScooters';
import PramsCotsEquipment from './listingFormsProps/PramsCotsEquipment';
import ToolsWorkshopEquipment from './listingFormsProps/ToolsWorkshopEquipment';
import Townhouse from './listingFormsProps/Townhouse';
import Musical from './listingFormsProps/Musical';
import Tools from './listingFormsProps/Tools';
import Generators from './listingFormsProps/Generators';
import Toys from './listingFormsProps/Toys';
import Alarms from './listingFormsProps/Alarms';
import Locks from './listingFormsProps/Locks';
import CCTV from './listingFormsProps/CCTV';
import Furniture from './listingFormsProps/Furniture';
import HomeDecor from './listingFormsProps/HomeDecor';
import HomeAppliances from './listingFormsProps/HomeAppliances';
import BraaiGarden from './listingFormsProps/BraaiGarden';
import Lighting from './listingFormsProps/Lighting';
import Office from './listingFormsProps/Office';
import TrucksCommercialVehicles from './listingFormsProps/TrucksCommercialVehicles';
import AudioForm from './listingFormsProps/AudioForm';
import TvAudioVisual from './listingFormsProps/TvAudioVisual';
import VacantLandPlot from './listingFormsProps/VacantLandPlot';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Personal from './alerts/Personal'

function Listing() {    

  const [showResults, setShowResults] = useState(false)
  const SelectSubCatagory = () => setShowResults(true)

  {/* ------------------------------fetching the values of the forms-----------------------------------*/}
   const [Categories, setCategories] = useState('')


  {/*----------------------------- The State of the form is false------------------------------------ */}
   const [vehiclesForm, setVehiclesForm] = useState(false);
   const [electronicsForm, setElectronicsForm] = useState(false);
   const [fashionForm, setFashionForm] = useState(false);
   const [propertyForm, setPropertyForm] = useState(false);
   const [childrenForm, setChildrenForm] = useState(false);
   const [homeGardenForm, setHomeGardenForm] = useState(false);

  {/*------------------------------ Opening the select form ------------------------------------------*/}
  const vehiclesClick = () => setVehiclesForm(true)
  const electronicsClick = () => setElectronicsForm(true)
  const fashionClick = () => setFashionForm(true)
  const propertyClick = () => setPropertyForm(true)
  const childrenClick = () => setChildrenForm(true)
  const homeGardenClick = () => setHomeGardenForm(true)
  {/*--------------------------------- closing the select form------------------------------------------- */} 
  const closeVehiclesForm = () => setVehiclesForm(false);
  const closeElectronicsForm = () => setElectronicsForm(false) 
  const closeFashionForm = () => setFashionForm(false)  
  const closePropertyForm = () => setPropertyForm(false)
  const closeChildrenForm = () => setChildrenForm(false)
  const closeHomeGarden = () => setHomeGardenForm(false)


  const [vehicles, setVehicles] = React.useState(false);
  const [electronics, setElectronics] = React.useState(false);
  const [fashion, setFashion] = React.useState(false);
  const [property, setProperty] = React.useState(false);
  const [children , setChildren] = React.useState(false);
  const [tools , setTools] = React.useState(false);
  const [home , setHome] = React.useState(false);
  const [security , setSecurity] = React.useState(false);

  const dummy = useRef()

  const dummyScroll = () => {
  dummy.current.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div>

      <h1 className="listing__title">Create Your Listing</h1>

      <h1 className="select__Category">Select Category</h1>
      <div className="listing">
      <ToggleButton
      value="check"
      selected={vehicles}
      onChange={() => {
        setVehicles(!vehicles);
        setElectronics(false);
        setFashion(false);
        setChildren(false);
        setSecurity(false);
        setHome(false);
        setTools(false);
        setProperty(false);
      }}
      className="form__button"
      style={{color: "#002d65",
      textTransform: "capitalize",
      fontFamily:"Montserrat",
      fontSize:"18px",
      fontWeight:"bolder",
      marginLeft:"10px",
      marginTop:"10px",
    }}
      
    >
      Vehicles
    </ToggleButton>
    <ToggleButton
      value="check"
      selected={electronics}
      onChange={() => {
        setVehicles(false);
        setElectronics(!electronics);
        setFashion(false);
        setChildren(false);
        setSecurity(false);
        setHome(false);
        setTools(false);
        setProperty(false);
      }}
      className="form__button"
      style={{color: "#002d65",
      textTransform: "capitalize",
      fontFamily:"Montserrat",
      fontSize:"18px",
      fontWeight:"bolder",
      marginLeft:"10px",
      marginTop:"10px",
    }}
      
    >
      electronics
    </ToggleButton>
    <ToggleButton
      value="check"
      selected={fashion}
      onChange={() => {
        setVehicles(false);
        setElectronics(false);
        setFashion(!fashion);
        setChildren(false);
        setSecurity(false);
        setHome(false);
        setTools(false);
        setProperty(false);
      }}
      className="form__button"
      style={{color: "#002d65",
      textTransform: "capitalize",
      fontFamily:"Montserrat",
      fontSize:"18px",
      fontWeight:"bolder",
      marginLeft:"10px",
      marginTop:"10px",
    }}
      
    >
      Fashion
    </ToggleButton>

    <ToggleButton
      value="check"
      selected={property}
      onChange={() => {
        setVehicles(false);
        setElectronics(false);
        setFashion(false);
        setChildren(false);
        setSecurity(false);
        setHome(false);
        setTools(false);
        setProperty(!property);
      }}
      className="form__button"
      style={{color: "#002d65",
      textTransform: "capitalize",
      fontFamily:"Montserrat",
      fontSize:"18px",
      fontWeight:"bolder",
      marginLeft:"10px",
      marginTop:"10px",
    }}
      
    >
      Property
    </ToggleButton>


    <ToggleButton
      value="check"
      selected={home}
      onChange={() => {
        setVehicles(false);
        setElectronics(false);
        setFashion(false);
        setChildren(false);
        setSecurity(false);
        setHome(!home);
        setTools(false);
        setProperty(false);
      }}
      className="form__button"
      style={{color: "#002d65",
      textTransform: "capitalize",
      fontFamily:"Montserrat",
      fontSize:"18px",
      fontWeight:"bolder",
      marginLeft:"10px",
      marginTop:"10px",
    }}
      
    >
      Home & Garden
    </ToggleButton>

     
    
    <ToggleButton
      value="check"
      selected={tools}
      onChange={() => {
        setVehicles(false);
        setElectronics(false);
        setFashion(false);
        setChildren(false);
        setSecurity(false);
        setHome(false);
        setTools(!tools);
        setProperty(false);
      }}
      className="form__button"
      style={{color: "#002d65",
      textTransform: "capitalize",
      fontFamily:"Montserrat",
      fontSize:"18px",
      fontWeight:"bolder",
      marginLeft:"10px",
      marginTop:"10px",
    }}
      
    >
      Tools & Workshop
    </ToggleButton>
    
    <ToggleButton
      value="check"
      selected={children}
      onChange={() => {
        setVehicles(false);
        setElectronics(false);
        setFashion(false);
        setChildren(!children);
        setSecurity(false);
        setHome(false);
        setTools(false);
        setProperty(false);
      }}
      className="form__button"
      style={{color: "#002d65",
      textTransform: "capitalize",
      fontFamily:"Montserrat",
      fontSize:"18px",
      fontWeight:"bolder",
      marginLeft:"10px",
      marginTop:"10px",
    }}
      
    >
      Children
    </ToggleButton>

   




    <ToggleButton
      value="check"
      selected={security}
      onChange={() => {
        setVehicles(false);
        setElectronics(false);
        setFashion(false);
        setChildren(false);
        setSecurity(!security);
        setHome(false);
        setTools(false);
        setProperty(false);
      }}
      className="form__button"
      style={{color: "#002d65",
      textTransform: "capitalize",
      fontFamily:"Montserrat",
      fontSize:"18px",
      fontWeight:"bolder",
      marginLeft:"10px",
      marginTop:"10px",
    }}
      
    >
      Security
    </ToggleButton>


      

      <div className="form__inputs">
      
                

          

                  {vehicles === true && (
                  <select  className="top__select" value={Categories}  onChange={e => setCategories(e.target.value)}>
                      <option>Select The Vehicle Sub-Category </option>
                      <option>Cars And Bakkies</option>
                      <option>Parts And Accessories</option>
                      <option>Motorcycles And Scooters</option>
                      <option>Trucks And Commercial Vehicles</option>
                      <option>Boats</option>
                  </select>
                     )}

                  {electronics === true && ( 
                    <select className="top__select" value={Categories}  onChange={e => setCategories(e.target.value)}>
                        <option>Select The Electronics Sub-Category </option>
                        <option>Accessories</option>
                        <option>Cellphones</option>
                        <option>TV's & Visual</option>
                        <option>Audio & Sound</option>
                        <option>Computers & Laptops</option>
                        <option>Gaming & Consoles</option>
                        <option>Musical & Music</option>
                        <option>Cameras</option>
                    </select>
                  )}
    
                {fashion === true && ( 
                    <select className="top__select" value={Categories}  onChange={e => setCategories(e.target.value)}>
                        <option>Select The Fashion Sub-Category </option>
                        <option>Clothing & Shoes</option>
                        <option>Jewellery & Fashion Accessories</option>
                        <option>Beauty & Cosmetics</option>
                    </select>
                  )}

                {property === true && ( 
                    <select className="top__select" value={Categories}  onChange={e => setCategories(e.target.value)}>
                        <option>Select The Property Sub-Category </option>
                        <option>House</option>
                        <option>Apartment / Flat</option>
                        <option>Townhouse</option>
                        <option>Vacant Land / Plot</option>
                        <option>Commercial Property</option>
                    </select>
                  )}

                  {children === true && ( 
                    <select className="top__select" value={Categories}  onChange={e => setCategories(e.target.value)}>
                        <option>Select The Children Sub-Category </option>
                        <option>Clothing & Accessories</option>
                        <option>Prams, Cots & Equipment</option>
                        <option>Toys</option>
                    </select>
                  )}

                {home && ( 
                    <select className="top__select" value={Categories}  onChange={e => setCategories(e.target.value)}>
                        <option>Select The Home & Garden Sub-Category </option>
                        <option>Furniture</option>
                        <option>Home Appliances</option>
                        <option>Home Decor</option>
                        <option>Braai & Garden</option>
                        <option>Lighting</option>
                        <option>Office</option>
                    </select>
                  )}

                   {tools === true && ( 
                    <select className="top__select" value={Categories}  onChange={e => setCategories(e.target.value)}>
                        <option>Select The Tools & Workshop Sub-Category </option>
                        <option>Generators & Solar Powers</option>
                        <option>Hand Tools & Powered Tools</option>
                    </select>
                  )}

                 {security === true && ( 
                    <select className="top__select" value={Categories}  onChange={e => setCategories(e.target.value)}>
                        <option>Select The Security Sub-Category </option>
                        <option>Alarms</option>
                        <option>CCTV Cameras</option>
                        <option>Locks</option>
                    </select>
                  )}
    
    
    <div ref={dummy}></div>
    
     {/* ---------------------------------------------Listing Forms------------------------------------------------------- */}

           {    
          Categories === "Cars And Bakkies" ? (
           <CarsAndBakkies />
          ) : Categories === "Parts And Accessories" ? (
            <CarPartsAccessories />
          ) : Categories === "Motorcycles And Scooters" ? (
            <MotorCyclesScooters />
          ) : Categories === "Trucks And Commercial Vehicles" ? (
            <TrucksCommercialVehicles />
          ) :Categories === "Boats" ? (
            <BoatsAviation />
          ) :Categories === "Accessories" ? (
            <Accessories />
          ) :Categories === "Cellphones" ? (
            <CellPhones />
          ):Categories === "TV's & Visual" ? (
            <TvAudioVisual />
          ):Categories === "Computers & Laptops" ? (
            <ComputersLaptops />
          ):Categories === "Gaming & Consoles" ? (
            <GamingConsoles />
          ):Categories === "Cameras" ? (
            <Cameras />

          ):Categories === "Clothing & Shoes" ? (
            <ClothingShoes />
          ):Categories === "Jewellery & Fashion Accessories" ? (
            <JewelleryAccessories />
          ):Categories === "Beauty & Cosmetics" ? (
            <HealthBeautyCosmetics />

          ):Categories === "House" ? (
            <House />
          ):Categories === "Apartment / Flat" ? (
            <ApartmentFlat />
          ):Categories === "Townhouse" ? (
            <Townhouse />
          ):Categories === "Vacant Land / Plot" ? (
            <VacantLandPlot />

          ):Categories === "Clothing & Accessories" ? (
            <ClothingAccessories />
          ):Categories === "Prams, Cots & Equipment" ? (
            <PramsCotsEquipment />

          ):Categories === "Home & Security" ? (
            <HomeSecurity />
          ):Categories === "Tools & Workshop Equipment" ? (
            <ToolsWorkshopEquipment />

          ):Categories === "Commercial Property" ? (
            <CommercialProperty />
          ): Categories === "Musical & Music" ? (
            <Musical />
          ): Categories === "Office" ? (
            <Office />
          ) :Categories === "Lighting" ? (
            <Lighting /> 
          ) :Categories === "Home Decor" ? (
            <HomeDecor />
          ) :Categories === "Home Appliances" ? (
            <HomeAppliances />
          ) :Categories === "Furniture" ? (
            <Furniture />
          ) :Categories === "Braai & Garden" ? (
            <BraaiGarden />
          ) : Categories === "Generators & Solar Powers" ? (
            <Generators /> 
          ) : Categories === "Hand Tools & Powered Tools" ? (
            <Tools/>
          ) : Categories === "Toys" ? (
            <Toys /> 
          ): Categories === "CCTV Cameras" ? (
            <CCTV />
          ) :Categories === "Locks" ? (
            <Locks />
           ) :Categories === "Alarms" ? (
             <Alarms />
           ) : Categories === "Audio & Sound" ? (
              <AudioForm />
           ) : null
           }
        </div>
    </div>
  

    
    </div>
  )
}

export default Listing
